export const termsArray: Array<any> = [
	{
		title: `1. Загальні положення`,
		message: `1.1 Замовниками послуг ТОВ «ДЖАСТ ІН» можуть бути фізичні особи, фізичні особи- підприємці і юридичні особи (далі за текстом – Замовник).

	1.2 Права й обов’язки ТОВ «ДЖАСТ ІН» та Замовника (далі за текстом разом – Сторони), передбачені цими Умовами надання послуг (далі за текстом – Умови), виникають з договору, укладеного між Сторонам.
	
	1.3 Умови розроблено ТОВ «ДЖАСТ ІН» відповідно до вимог чинного законодавства з метою регулювання послуг з організації перевезення відправлень та надання комплексу послуг, пов’язаних із перевезенням відправлень територією України.
	
	1.4 Шляхом передачі Замовником відправлення до ТОВ «ДЖАСТ ІН» для транспортування Замовник підтверджує, що він ознайомлений та погоджується з положеннями цих Умов, чинними на момент передачі відправлення для транспортування, та зобов’язується їх виконувати незалежно від наявності або відсутності в експрес-накладній підпису Замовника.
	
	1.5 Ці Умови доступні для ознайомлення на офіційному сайті ТОВ «ДЖАСТ ІН» Justin.ua (далі за текстом – Сайт).`,
	},

	{
		title: `2. Послуга доставки територією України`,
		message: `2.1 Доставка відправлень здійснюється відповідно до строків та за тарифами, які зазначено на Сайті на день оформлення відправлення.

		2.2 «Відділення–Відділення» – приймання відправлення від Відправника у відділенні в місті Відправника та видача цього відправлення Одержувачеві у відділенні або в поштоматі в місті Одержувача.
		
		2.3 Під відправленням у межах цих Умов розуміється вантаж або інше майно (залежно від задекларованої інформації у відповідній експрес-накладній), прийняті ТОВ «ДЖАСТ ІН» для надання основної послуги.
		
		2.4 Відправлення до моменту вручення їх Одержувачу є власністю Відправника.`,
	},

	{
		title: `3. Вимоги до габаритів та ваги посилки`,
		message: `3.1 Мережа відділень — сукупність відділень ТОВ «ДЖАСТ ІН», які здійснюють приймання та видачу відправлень.

		Відділення поділяються на такі типи:
		
		Smart, OSR mini – відділення, у якому здійснюється приймання/видача відправлення вагою не більше ніж 15 кг, довжиною не більше ніж 90 см, а сума всіх сторін не більше 120 см.
		OSR standard – відділення, у якому здійснюється приймання/видача відправлення вагою не більше ніж 30 кг, довжиною не більше ніж 90 см, а сума всіх сторін не більше 150 см.
		OSR cargo – відділення, у якому здійснюється приймання/видача відправлення вагою не більше ніж 1000 кг, довжиною не більше ніж 300 см, шириною не більше ніж 180 см і висотою не більше ніж 180 см.
		3.2 Поштомати — сукупність автоматизованих поштових терміналів самообслуговування, призначених для видачі відправлень без участі представників ТОВ «ДЖАСТ ІН».
		
		Поштомати розраховані для відправлень фактичною вагою не більше ніж 15 кг на одне місце та розмірами, де довжина не більше ніж 58 см, висота не більше ніж 36 см та ширина не більше ніж 40 см. Габарити вказані з урахуванням упаковки.
		
		3.3 Відправлення, які за розмірами чи вагою перевищують допустимі обмеження, приймаються до перевезення лише за попередньою домовленістю Сторін.
		
		3.4 Відправлення в гофрокартонних коробках, поєднані між собою стрейч-плівкою або скотчем, до перевезення не приймаються.
		
		Дозволяється поєднувати до трьох відправлень в одне відправлення, якщо одночасно виконуються такі умови:
		
		вміст відправлення — текстильні вироби (одяг, тканини, м’які речі);
		спільна вага — не більше 10 кг;
		відправлення поєднані між собою як мінімум п’ятьма шарами стрейч-плівки та скотчем, що утворює єдину цілісну упаковку для об’єднаного відправлення.`,
	},

	{
		title: `4. Вимоги до пакування`,
		message: `4.1 Упаковка відправлення повинна бути належною, якісною та відповідати характеру відправлення. Відправлення без належного пакування до пересилання не приймаються.

		4.2 Для забезпечення цілісності відправлення під час перевезення необхідно дотримуватись таких правил:
		
		використовувати коробки лише з цупкого гофрованого картону;
		на коробці не має бути випуклих поверхонь, вм’ятин, розривів, щілин;
		поверхня коробки має бути чистою;
		крихкі відправлення обов’язково з пом’якшувальним матеріалом.
		4.3 Упаковка має забезпечувати захист від пошкоджень не лише власного вмісту, а й вмісту сусідніх відправлень, з якими буде контактувати запаковане відправлення.`,
	},

	{
		title: `5. Заборонені до траспортування посилки`,
		message: `5.1 Грошові кошти (знаки), зокрема іноземна валюта, цінні папери.

		5.2 Вогнепальна зброя та її частини, боєприпаси.
		
		5.3 Азбест; вогненебезпечні, легкозаймисті та вибухові речовини, радіоактивні речовини й інші небезпечні відправлення з відповідним маркуванням («вибухові матеріали та речовини», «гази», «легкозаймисті рідини», «речовини, здатні до самозаймання», «токсичні та інфекційні речовини», «речовини, що окислюють», «радіоактивні матеріали», «корозійні і їдкі речовини», «інші небезпечні речовини і вироби»).
		
		5.4 Наркотичні засоби, психотропні речовини, їх аналоги та прекурсори, включені до переліку наркотичних засобів, психотропних речовин, їх аналогів і прекурсорів, що підлягають спеціальному контролю відповідно до законодавства.
		
		5.5 Токсичні та радіоактивні речовини, ртуть, кислоти, отруйні, їдкі речовини.
		
		5.6 Балони з рідиною та газом.
		
		5.7 Автомобільні акумулятори, акумулятори для мото- та с/г техніки.
		
		5.8 Тварини (рештки тварин чи необроблене хутро) та рослини.
		
		5.9 Відправлення, що вимагають спеціального температурного режиму.
		
		5.10 Відправлення, які видають запах, брудні та можуть завдати шкоди іншим відправленням чи здоров’ю людей або тварин.
		
		5.11 Інші предмети, перевезення яких заборонено чинним законодавством України.
		
		5.12 Предмети, перевезення яких потребує спеціальних умов або для перевезення яких необхідна наявність спеціальних ліцензій, дозволів, погоджень тощо.`,
	},

	{
		title: `6. Оголошена вартість посилки`,
		message: `6.1 Оголошена вартість — вартість відправлення, заявлена Відправником в експрес-накладній.

		6.2 Оголошена вартість повинна відповідати реальній вартості вкладення у відправленні.
		
		6.3 При встановленні оголошеної вартості відправлення в розмірі 5 000 грн і більше представник ТОВ «ДЖАСТ ІН» в праві витребувати у Відправника документ, що підтверджує вартість вкладення (товарний чек, видаткова накладна тощо).`,
	},

	{
		title: `7. Розрахунок ваги і вартості посилки`,
		message: `7.1 Якщо Відправник юридична особа або фізична особа підприємець, то для відправлення визначаються два види ваги: фактична й об’ємна для всіх вантажних місць сумарно.

		7.2 Вартість обчислюється за більшим із цих значень згідно з тарифами на послуги з організації перевезень, розміщеними на Сайті.
		
		Фактична вага визначається шляхом зважування відправлення на вагах.
		
		Об’ємна вага визначається вимірюванням габаритів відправлення за формулою:
		
		Об’ємна вага [кг] = Довжина [см] х Ширина [см] х Висота [см] / 4000 або
		
		Об’ємна вага [кг] = Об’єм [м3 ] х 250
		
		7.3 Якщо Відправник фізична особа, то для відправлення визначається фактична вага для всіх вантажних місць сумарно, та найдовша сторона відправлення.
		
		Фактична вага визначається шляхом зважування відправлення на вагах.
		
		Найдовша сторона визначається вибором найбільшого значення після вимірювання всіх довжин. Формат відправлення визначається за більшим значенням ваги або розміру.
		
		Формат відправлення визначається за більшим значенням ваги або розміру.
		
		7.4 Якщо відправлення має неправильну геометричну форму (є частини, які виступають), то лінійні розміри вимірюються за крайніми точками габариту відправлення.
		
		7.5 Вартість перевезення відправлень визначається за тарифами, що розміщені на Сайті за посиланням: justin.ua/taryfy/`,
	},

	{
		title: `8. Iнформація, необхідна для оформлення посилки`,
		message: `8.1 Інформація про Відправника:

		для юридичних осіб і фізичних осіб-підприємців – назва / П. І. Б, номер відділення і номер мобільного телефону контактної особи;
		для фізичних осіб – П. І. Б., номер відділення і номер мобільного телефону.
		8.2 Місто доставки відправлення.
		
		8.3 Інформація про Одержувача:
		
		для юридичних осіб і фізичних осіб-підприємців — назва / П.І.Б., номер відділення та номер мобільного телефону контактної особи;
		для фізичних осіб — П. І. Б., номер відділення та номер мобільного телефону. Інформація про відправлення:
		кількість місць;
		вага;
		оголошена вартість;
		опис вмісту.
		8.4 Платник послуг.
		
		8.5 Форма розрахунку (готівкова / безготівкова).`,
	},

	{
		title: `9. Умови приймання посилок / грошового переказу у відділенні`,
		message: `9.1. Відправник надає відправлення запаковане відповідно до розділу 4 цих правил. У разі невідповідності пакування відправлення ТОВ «ДЖАСТ ІН» має право відмовити у прийнятті такого відправлення.
 
		9.2. Відправник в особі представника Клієнта юридичної особи та фізичної особи-підприємця під час оформлення відправлення у відділенні надає представнику ТОВ «ДЖАСТ ІН» оригінал довіреності, та один із документів, що підтверджує особу і зазначений в довіреності, а саме:​​​​​​​
		Паспорт громадянина України (у т.ч. у вигляді ID-картки).
		Паспорт громадянина України для виїзду за кордон.
		Посвідчення водія, видане в Україні. Оригінали довіреностей, надані представнику ТОВ «ДЖАСТ ІН», не повертаються представникам Клієнтів.
		9.3. Відправник у разі самостійного створення експрес-накладної в особистому кабінеті або через реєстр відправлень передає зареєстроване відправлення представнику ТОВ «ДЖАСТ ІН» з наклеєним маркуванням або вказує на пакуванні номер замовлення за яким реєструвалося відправлення.
		
		9.4. При замовленні грошового переказу з готівковою формою розрахунку (з сумою менше ніж 5 000 грн і Отримувач переказу – тільки фізична особа, або з сумою менше ніж 30 000 грн і Отримувач переказу – тільки юридична особа) або при розрахунку банківською карткою (з сумою менше ніж 30 000 грн і Отримувач переказу – фізична або юридична особа) – ідентифікація та верифікація Відправника за документами не проводиться.
		
		9.5. При замовленні грошового переказу з готівковою формою розрахунку (з сумою 5 000 грн і більше з Отримувачем переказу – тільки фізична особа, або з сумою 30 000 грн і більше з Отримувачем переказу – тільки юридична особа) або при розрахунку банківською карткою (з сумою 30 000 грн і більше з Отримувачем переказу – фізична або юридична особа) – Відправник надає представнику ТОВ «ДЖАСТ ІН» один із документів наведених у п.п.10.4.1 або п.п.10.4.2 для здійснення ідентифікації, верифікації та зняття копії документів у відповідності до чинного законодавства (ст. 6 та ст.9 Закону України «Про запобігання та протидію легалізації (відмиванню) доходів, одержаних злочинним шляхом, фінансуванню тероризму та фінансуванню розповсюдження зброї масового знищення» від 14.10.2014 №1702-VII та п. 38 і п. 40 Розділу IV «Положення про здійснення небанківськими фінансовими установами фінансового моніторингу в частині надання ними фінансової послуги щодо переказу коштів» затвердженого Постановою Національного банку України від 15.09.2016 №388).
		
		​​​​​​​9.6. Сплатити залежно від форми розрахунку представнику ТОВ «ДЖАСТ ІН» кошти за надані послуги.`,
	},

	{
		title: `10. Умови одержання посилок / грошового переказу у відділенні`,
		message: `10.1 Для отримання відправлення Одержувач надає представнику ТОВ «ДЖАСТ ІН» номер експрес-накладної або телефону, що вказаний в одержувачах.

		10.2 Для отримання грошового переказу Одержувач надає представнику ТОВ «ДЖАСТ ІН» номер переказу або телефону, що вказаний в одержувачах, і документ, що посвідчує його особу.
		
		10.3 Одержувач фізична особа або фізична особа-підприємець пред’являє представнику ТОВ «ДЖАСТ ІН» будь-який із наведених нижче документів, що підтверджує особу Одержувача, зазначеного в експрес-накладній, а саме:
		Паспорт громадянина України (у т.ч. у вигляді ID-картки).
		Тимчасове посвідчення, що посвідчує особу громадянина України.
		Тимчасове посвідчення, що підтверджує особу громадянина України.
		Посвідку на постійне проживання.
		Посвідку на тимчасове проживання.
		Посвідчення біженця, видане в Україні.
		Паспорт громадянина України для виїзду за кордон.
		Посвідчення особи без громадянства для виїзду за кордон.
		Дипломатичний паспорт України.
		Службовий паспорт України.
		Посвідчення особи моряка.
		Посвідчення члена екіпажу.
		Посвідчення особи на повернення в Україну.
		Посвідчення водія.
		Картка мігранта.
		Проїзний документ біженця.
		Посвідчення особи, яка потребує додаткового захисту.
		Проїзний документ особи, якій надано додатковий захист.
		Паспорт іноземного громадянина, у якому відомості про особу вказані кирилицею. У разі використання іншої абетки – надати апостиль (офіційний переклад паспорта українською мовою).
		10.4 При одержанні відправлення з післяплатою / грошового переказу на суму 5 000 грн і більше (незалежно від кількості отриманих переказів), Одержувач (фізична особа) надає представнику ТОВ «ДЖАСТ ІН» один із документів наведених у п.п.10.4.1 або п.п.10.4.2 для здійснення ідентифікації, верифікації та зняття копії документів у відповідності до чинного законодавства (ст. 6 і ст.9 Закону України «Про запобігання та протидію легалізації (відмиванню) доходів, одержаних злочинним шляхом, фінансуванню тероризму та фінансуванню розповсюдження зброї масового знищення» від 14.10.2014 №1702-VII та п. 38 і п. 40 Розділу IV «Положення про здійснення небанківськими фінансовими установами фінансового моніторингу в частині надання ними фінансової послуги щодо переказу коштів» затвердженого Постановою Національного банку України від 15.09.2016 №388):
		
		10.4.1 Громадяни України:
		Паспорт громадянина України (у т.ч. у вигляді ID-картки). 
		Паспорт громадянина України для виїзду за кордон (за умови пред’явлення довідки про ІПН). Дипломатичний паспорт України.
		Паспорт громадянина України для виїзду за кордон (за умови пред’явлення довідки про ІПН).
		Дипломатичний паспорт України.
		10.4.2 Іноземні громадяни та особи без громадянства, які постійно проживають в Україні:
		Посвідка на постійне проживання;
		Посвідка на тимчасове проживання (видана на термін не менше 6 місяців).
		10.5 Фізичній особі, не вказаній в експрес-накладній як Одержувач – надати представнику ТОВ «ДЖАСТ ІН» номер експрес-накладної та один із нижче перелічених пакетів документів:
		
		10.5.1 Відповідно оформлену довіреність і документ, який посвідчує особу згідно з довіреністю (паспорт громадянина України, паспорт громадянина України для виїзду за кордон).
		
		10.5.2 Одночасно надати паспорт громадянина України, або паспорт громадянина України для виїзду за кордон, що підтверджує особу Одержувача, вказану в експрес-накладній, і власний документ, що посвідчує особу відповідно до переліку наведеного в п. 10.3 та номер експрес-накладної.
		
		10.5.3 Надати власний документ, що посвідчує особу відповідно до переліку наведеного в п. 10.3 та документ, що посвідчує родинні зв’язки (свідоцтво про народження, свідоцтво про шлюб, паспорт громадянина України з відміткою про шлюб тощо).
		
		10.6 Без документів, що підтверджують особу Одержувача, дозволяється видавати відправлення тільки фізичній особі за таких умов:
		
		10.6.1 Для відправлень без оплати Одержувач:
		називає номер експрес-накладної або номер телефону, що вказаний в одержувачах; 
		пред’являє документ за яким П.І.Б. має співпадати з П.І.Б. вказаному в отримувачах експрес-накладної або називає SMS-код надісланий на номер телефону, що вказаний Відправником, як контактний номер Одержувача зазначений в експрес-накладній.
		10.6.2 Для відправлень з післяплатою Одержувач:
		називає номер експрес-накладної або номер телефону, що вказаний в одержувачах; 
		при готівковій формі розрахунку (з післяплатою менше 5 000 грн і Отримувач переказу – тільки фізична особа, або з післяплатою менше 30 000 грн і Отримувач переказу – тільки юридична особа) або при розрахунку банківською карткою (з післяплатою менше 30 000 грн і Отримувач переказу – фізична або юридична особа).
		10.7 При одержанні грошового переказу на суму, що менша 5 000 грн, Одержувач (фізична особа) надає представнику ТОВ «ДЖАСТ ІН» один із документів наведених у п.п.10.4.1 або п.п.10.4.2 для здійснення ідентифікації та верифікації у відповідності до чинного законодавства (ст. 6 та ст.9 Закону України «Про запобігання та протидію легалізації (відмиванню) доходів, одержаних злочинним шляхом, фінансуванню тероризму та фінансуванню розповсюдження зброї масового знищення» від 14.10.2014 №1702-VII) без зняття копії.
		
		10.8 Сплатити залежно від форми розрахунку представнику ТОВ «ДЖАСТ ІН» кошти за надані послуги.`,
	},

	{
		title: `11. Додаткові послуги`,
		message: `11.1 Додаткова послуга — послуга, що надається до основної послуги за додаткову плату. До додаткових послуг ТОВ «ДЖАСТ ІН» належать:

		11.1.1 «Післяплата» — послуга, що передбачає контроль переказу Одержувачем коштів за відправлення Відправнику. Послуга надається у відділеннях ТОВ «ДЖАСТ ІН» фінансовою установою-партнером відповідно до договору про спільну діяльність. Послуга здійснюється тільки у національній валюті. Сума післяплати, визначена відправником, не повинна перевищувати суми оголошеної вартості відправлення.
		
		11.1.2 «Грошовий переказ» — послуга, що передбачає виконання доручення Відправника на пересилання та виплату Одержувачу визначеної ним суми коштів лише у національній валюті. Загальна сума, що приймається до пересилання переказами в межах України не повинна перевищувати 100 тис. грн. Послуга надається у відділеннях ТОВ «ДЖАСТ ІН» фінансовою установою-партнером за тарифом 2%+15 грн. відповідно до договору про спільну діяльність.
		
		11.1.3 «Переадресування відправлення» — послуга, що передбачає зміну адреси доставки вже оформленого відправлення. Послуга здійснюється лише після прибуття відправлення в першочергове замовлене відділення та тарифікується відповідно до тарифів на доставку, які розміщені на Сайті justin.ua/taryfy/. Послуга для одного відправлення може бути замовлена лише один раз Відправником або Одержувачем і замовляється у відділенні, через Контакт Центр, а для юридичних осіб, з якими укладено договір про надання послуг звернення відбуваються через менеджера з продажу.
		
		11.1.4 «Повернення відправлення» — послуга, що передбачає повернення відправлення Відправнику. Послуга тарифікується відповідно до тарифів на доставку, які розміщені на Сайті.
		
		11.1.5 «Пакування» — послуга, що призначається для пакування відправлень в упаковку ТОВ «ДЖАСТ ІН». Послуга тарифікується відповідно до тарифів на упаковку, що розміщені на Сайті за посиланням: justin.ua/taryfy/
		
		11.1.6 Карта «Monobank» — послуга, що передбачає видачу банківської платіжної карти ПАТ «УНІВЕРСАЛ БАНК» з продуктом «Monobank» на відділенні.
		
		11.1.7 Кредитування «РВС БАНК» — послуга, що передбачає оформлення заявки на споживчий кредит у відділенні та його видачу на платіжну карту ПАТ «РВС БАНК» відповідно до умов співпраці з банком партнером.
		
		11.1.8 Кредитування «МАНІВЕО» — послуга, що передбачає подачу заяви на кредит у відділенні та його видачу на особисту банківську платіжну карту Клієнта відповідно до умов співпраці з фінансовою компанією партнером ТОВ «МАНІВЕО ШВИДКА ФІНАНСОВА ДОПОМОГА».
		
		11.1.9 «Зручна передача» — послуга, що надає можливість Відправнику залишити відправлення у відділенні з подальшою видачою Одержувачу у цьому ж відділенні за зниженим тарифом justin.ua/taryfy/`,
	},

	{
		title: `12. Сервіси`,
		message: `12.1 «Відстеження відправлення» — сервіс, що передбачає можливість відстежити за номером експрес-накладної місцезнаходження відправлення. Скористатися сервісом можна на Сайті.

		12.2 «Зберігання» — сервіс, що передбачає безкоштовне зберігання відправлення впродовж 5 робочих днів починаючи з наступного після дня надходження у відділення Одержувача.
		
		12.3 «Інформування» — сервіс, що передбачає інформування Одержувача та Відправника про прибуття, час доставки та зберігання відправлення. Сервіс надається у форматі СМС-повідомлення, телефонного дзвінка або електронного листа на підставі договірних відносин із ТОВ «ДЖАСТ ІН». Зміни налаштувань сервісу «Інформування» стосуються всіх відправлень, за якими Клієнт зазначений Відправником або Одержувачем в експрес-накладній.
		
		12.4 «Огляд відправлення» — сервіс, надає можливість Одержувачу у відділенні розкрити упаковку відправлення та перевірити його вміст, наявність та цілісність пломб або наклейок розміщених на упаковці або корпусі виробу; проводити зовнішній огляд відправлення на комплектність та відсутність пошкоджень.
		
		Під час огляду відправлення забороняється:
		Використовувати витратні матеріали, що є у відправленні (наприклад, розпилювати тестові флакони парфумерії).
		Відкривати упаковки з будь-якими продуктами харчування.
		Копіювати інформацію з її носіїв.
		Встановлювати паролі на телефони, планшети, ноутбуки та інші електронні прилади.
		Зривати з упаковки та відправлень заводські, рекламні та інформаційні наклейки.
		Відкривати наступні типи упаковок:
		блістерна;
		ламінована;
		термозбіжна;
		за наявності пломб (металевих, паперових, з прошивкою ниток та перфорацією).
		Приміряти корсетні товари (натільна білизна) та панчішно-шкарпеткові вироби.
		Проводити огляд відправлення за межами відділення.
		12.5 «Примірка» — сервіс, що надає можливість Одержувачу у відділенні розкрити упаковку з дотриманням вимог п.12.4 цих Умов та провести примірювання його вмісту (одяг, взуття). Даний сервіс надається тільки у відділеннях, на яких розташовані примірочні.
		
		Забороняється надавати сервіс «Примірка» за неоплаченими відправленнями для таких корпоративних Клієнтів:
		
		№
		
		Корпоративний Клієнт
		
		1
		
		Lamoda, ТОВ «ПОСТ-ІНТЕРНЕЙШНЛ»
		
		2
		
		MD-fashion ТОВ «Омні Коммерс»
		
		3
		
		Newbalance ТОВ «Омні Коммерс»
		
		4
		
		Puma ТОВ «Омні Коммерс»
		
		5
		
		Garne ТОВ «Трейд Івен»
		
		6
		
		Garne ФОП Половінкіна Ірина Ігорівна
		
		7
		
		Kedoff ФОП Климчук Ігор Дмитрович
		
		8
		
		Gepur  ФОП Жеребчук Дмитро Володимирович
		
		9
		
		LeBoutique, ТОВ «ЛЄ БУТІК»
		
		10
		
		ТОВ «ОМНІ ДЕЛІВЕРІ»
		
		12.6 «Зміна даних в ЕН» — сервіс, що надає можливість Відправнику (або представнику Відправника-юридичної особи) змінити дані (інформацію) в експрес-накладній. Сервіс доступний для замовлення у відділенні, через відділ Контакт-центру, а для юридичних осіб, з якими укладено договір про надання послуг звернення відбуваються через менеджера з продажу.
		
		12.6.1 Відправник може звернутися до ТОВ «ДЖАСТ ІН» з проханням змінити наступну інформацію в замовленні (ЕН):
		
		номер телефону Відправника;
		ПІБ представника юридичних осіб (Контакт відправника);
		номер телефону Одержувача;
		ПІБ Одержувача;
		платника, тільки для юридичних осіб;
		форма виплати грошового переказу (COD), тільки при умові, що відправлення по якому проводяться зміни не було видане;
		відділення виплати грошового переказу тільки для форми виплати «Готівкова» і відправлення по якому проводяться зміни не було видане;
		форма оплати, тільки для юридичних осіб, у разі зміни платника відправлення з Одержувача на Відправника;
		зміна суми післяплати або відміна післяплати:
		− для післяплати, що дорівнює або менше 5000 грн – заява на зміну даних оформлюється у телефонному режимі через Контакт-Центр або в письмовій формі у відділенні. Юридичні особи у яких є персональний менеджер з продажів оформлюють заяву через менеджера;
		− для післяплати більше ніж 5000 грн – заява на зміну даних оформлюється  тільки у відділенні в письмовій формі. Юридичні особи у яких є персональний менеджер з продажів оформлюють заяву через менеджера.
		12.6.2 Обмеження сервісу. Заборонено вносити такі зміни в ЕН:
		
		додавати дані, які були відсутні в ЕН (наприклад, заборонено додавати в ЕН суму післяплати, якщо ця послуга не була замовлена Відправником під час оформлення відправлення);
		змінювати будь-які дані закритої ЕН, вже після вручення відправлення в системі.
		12.7 «Масові відправлення» - сервіс, що надає можливість Відправнику робити відправки за реєстром від 5 і більше одиниць відправлень у відділеннях ТОВ «ДЖАСТ ІН».`,
	},

	{
		title: `13. Масові посилки`,
		message: `У вкладенні ви знайдете файли, що допоможуть комфортно працювати з масовими відправленнями.

		13.1 У разі, якщо відправник — юридична особа без укладеного договору про співпрацю, спершу потрібно оформити договір, потім подавати відправлення (так як там буде визначено форму оплату, форму отримання накладної плати тощо). Так як розрахунки проводяться по безготівці.
		
		13.2 Без укладання договору відправник може бути тільки — фізична особа.`,
	},
];
