import {useTranslation} from "react-i18next";
import React from "react";
import {Accordion, AccordionDetails, AccordionSummary, createStyles, Theme, Typography} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import {DICTIONARY} from "../../../enums/dictionary";
import {makeStyles} from "@material-ui/core/styles";


export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: "flex",
            background: "white",
            borderRadius: "10px",
            padding: "35px 75px",
            "& h3": {
                fontFamily: "Helvetica",
                fontSize: "24px",
                lineHeight: "28px",
                "font-weight": "700",
            },
            fontFamily: "Helvetica",
            fontSize: "20px",
            lineHeight: "23px",
            "font-weight": "400",
        },
        messageStyle: {
            whiteSpace: "pre-line",
            fontFamily: "Helvetica",
            fontSize: "16px",
            lineHeight: "20px",
            "font-weight": "400",
            "& a": {
                "overflow-wrap": "anywhere",
            },
            "& span": {
                "font-weight": "700",
            },
            ["@media (max-width:812px)"]: {
                padding: "0 14px",
            },
        },
        heading: {
            color: "#104EFF",
            fontFamily: "Helvetica",
            fontSize: "20px !important",
            lineHeight: "24px !important",
            "font-weight": "700 !important",
            ["@media (max-width:812px)"]: {
                padding: "0 14px",
                fontSize: "20px !important",
                lineHeight: "23px !important",
            },
        },
        acordeonStyle: {
            paddingBottom: theme.spacing(3),
        },
        wrapperBox: {
            padding: "0 8% 70px",
            ["@media (max-width:812px)"]: {
                padding: "0 0 50px",
            },
            "& .MuiAccordionSummary-root": {
                padding: "0",
                "& p": {
                    padding: "0",
                    margin: "0",
                }
            },
            "& .MuiAccordionDetails-root": {
                padding: "0",
                "& p": {
                    padding: "0",
                }
            }
        },
        blueColor: {
            color: "#104EFF",
        },
        accordionSummary: {
            "& p": {
                fontFamily: "Helvetica",
                fontSize: "20px",
                lineHeight: "23px",
                "font-weight": "400",
            },
            "& label": {fontSize: "16px",
                lineHeight: "20px",
            },
            "& span": {
                color: "#C4C4C4",
                transform: "rotate(270deg)",
                ["@media (max-width:812px)"]: {
                    "& svg": {
                        width: "44px",
                        height: "44px",
                    },
                },
            },
            "& .Mui-expanded": {
                "& span": {
                    color: "black",
                    transform: "rotate(180deg)",
                },
                "& p": {
                    color: "black",
                    textDecoration: "underline",
                },
            },
        },
        separator: {
            width: "100%",
            height: "2px",
            background: "grey",
            opacity: '0.3',
        },
        wrapperSeparatorManage: {
            padding: "20px 0 50px",
            background: "white",
        }
    })
);


export const getFAQ = () => {
    /* eslint-disable */
    const classname = useStyles();
    const {t} = useTranslation();
    const [expanded, setExpanded] = React.useState<string | false>('panel1');
    const handleChange = (panel: string) => (event: React.ChangeEvent<{}>, newExpanded: boolean) => {
        setExpanded(newExpanded ? panel : false);
    };
    return (<>
            <Accordion
                square expanded={expanded === 'panel1'}
                onChange={handleChange("panel1")}
            >
                <AccordionSummary
                    className={classname.accordionSummary}
                    expandIcon={<ExpandMoreIcon/>}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <Typography className={classname.heading}>
                        {t(DICTIONARY.GET_API_KEY_HEADER)}
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography className={classname.messageStyle}>
                        <label>{t(DICTIONARY.GET_API_KEY_SUB)} </label><br/><br/>
                        <label>{t(DICTIONARY.KEY_ACCOUNT)} Just</label><br/><br/>
                        <label>{t(DICTIONARY.PASSWORD_ACCOUNT)} Just</label><br/><br/>
                        <label>{t(DICTIONARY.API_KEY)} f2290c07-c028-11e9-80d2-525400fb7782</label><br/><br/>
                        <label>{t(DICTIONARY.TEST_PATH)}
                            <a href="https://api.sandbox.justin.ua/client_api/hs/"> https://api.sandbox.justin.ua/client_api/hs/ </a>
                            {t(DICTIONARY.VERSION_OF_THE_METHOD)}
                        </label><br/><br/>
                        <label>{t(DICTIONARY.GET_PERSONAL_KEY)}</label><br/><br/>
                        <label>{t(DICTIONARY.RECEIVE_PERSONAL_KEY_SUB_1)}
                            <span>{t(DICTIONARY.RECEIVE_PERSONAL_KEY_SUB_2)}</span>
                            {t(DICTIONARY.RECEIVE_PERSONAL_KEY_SUB_3)}
                            <span>{t(DICTIONARY.RECEIVE_PERSONAL_KEY_SUB_4)}</span>
                        </label><br/><br/>
                        <label>{t(DICTIONARY.PERSONAL_KEY)}</label><br/><br/>
                    </Typography>
                </AccordionDetails>
            </Accordion>
            <div className={classname.wrapperSeparatorManage}>
                <div className={classname.separator}/>
            </div>
            <Accordion>
                <AccordionSummary
                    className={classname.accordionSummary}
                    expandIcon={<ExpandMoreIcon/>}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <Typography className={classname.heading}>
                        {t(DICTIONARY.CORRECTLY_KEY_HEADER)} <br/>
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography className={classname.messageStyle}>
                        <br/>
                        {t(DICTIONARY.ORDER_ASK)}<br/><br/>
                        • {t(DICTIONARY.CORRECTLY_KEY_STEP_1)}<br/>
                        • {t(DICTIONARY.CORRECTLY_KEY_STEP_2)}<br/>
                        • {t(DICTIONARY.CORRECTLY_KEY_STEP_3)}<br/>
                        • {t(DICTIONARY.CORRECTLY_KEY_STEP_4)}<br/>
                    </Typography>
                </AccordionDetails>
            </Accordion>
            <div className={classname.wrapperSeparatorManage}>
                <div className={classname.separator}/>
            </div>
            <Accordion>
                <AccordionSummary
                    className={classname.accordionSummary}
                    expandIcon={<ExpandMoreIcon/>}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <Typography className={classname.heading}>
                        {t(DICTIONARY.GENERATE_A_SIGN_HEADER)} <br/>
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography className={classname.messageStyle}>
                        <br/>
                        {t(DICTIONARY.GENERATE_A_SIGN_SUB)}
                    </Typography>
                </AccordionDetails>
            </Accordion>
            <div className={classname.wrapperSeparatorManage}>
                <div className={classname.separator}/>
            </div>
            <Accordion>
                <AccordionSummary
                    className={classname.accordionSummary}
                    expandIcon={<ExpandMoreIcon/>}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <Typography className={classname.heading}>
                        {t(DICTIONARY.GET_INFORMATION_HEADER)} <br/>
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography className={classname.messageStyle}>
                        <br/>
                        {t(DICTIONARY.GET_INFORMATION_SUB)}
                    </Typography>
                </AccordionDetails>
            </Accordion>
            <div className={classname.wrapperSeparatorManage}>
                <div className={classname.separator}/>
            </div>
            <Accordion>
                <AccordionSummary
                    className={classname.accordionSummary}
                    expandIcon={<ExpandMoreIcon/>}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <Typography className={classname.heading}>
                        {t(DICTIONARY.JUSTIN_BILLING_HEADER)} <br/>
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography className={classname.messageStyle}>
                        <br/>
                        {t(DICTIONARY.JUSTIN_BILLING_SUB)}
                    </Typography>
                </AccordionDetails>
            </Accordion>
            <div className={classname.wrapperSeparatorManage}>
                <div className={classname.separator}/>
            </div>
            <Accordion>
                <AccordionSummary
                    className={classname.accordionSummary}
                    expandIcon={<ExpandMoreIcon/>}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <Typography className={classname.heading}>
                        {t(DICTIONARY.GET_INFORMATION_ON_THE_CURRENT_HEADER)} <br/>
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography className={classname.messageStyle}>
                        <br/>
                        {t(DICTIONARY.GET_INFORMATION_ON_THE_CURRENT_SUB)}
                    </Typography>
                </AccordionDetails>
            </Accordion>
            <div className={classname.wrapperSeparatorManage}>
                <div className={classname.separator}/>
            </div>
            <Accordion>
                <AccordionSummary
                    className={classname.accordionSummary}
                    expandIcon={<ExpandMoreIcon/>}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <Typography className={classname.heading}>
                        {t(DICTIONARY.REGISTER_OF_SHIPMENTS_HEADER)} <br/>
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography className={classname.messageStyle}>
                        <br/>
                        {t(DICTIONARY.REGISTER_OF_SHIPMENTS_SUB)}
                    </Typography>
                </AccordionDetails>
            </Accordion>
            <div className={classname.wrapperSeparatorManage}>
                <div className={classname.separator}/>
            </div>
            <Accordion>
                <AccordionSummary
                    className={classname.accordionSummary}
                    expandIcon={<ExpandMoreIcon/>}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <Typography className={classname.heading}>
                        {t(DICTIONARY.COD_AMOUNT_HEADER)} <br/>
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography className={classname.messageStyle}>
                        <br/>
                        {t(DICTIONARY.COD_AMOUNT_SUB)}
                    </Typography>
                </AccordionDetails>
            </Accordion>
        </>
    );
};