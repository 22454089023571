import { contextState, IContextState } from "./store";
import { ActionType } from "./types";
import { ISnackBarData } from "./interfaces/interfaces";

export enum ACTION_TYPES {
	"RESET",
	"SET_CAPTCHA",
	"SHOW_REGISTRATION_FORM",
	"SET_SNACK_BAR_DATA",
	"SHOW_LOGIN_FORM",
	"NEWS_ID",
	"MODULE_ID",
	"SET_EMAIL",
	"SET_LANGUAGE",
}

export const reducer = (
	state: IContextState,
	action: ActionType
): IContextState => {
	switch (action.type) {
		/***
		 * Reset app context to initial state;
		 */
		case ACTION_TYPES.RESET: {
			return { ...contextState };
		}

		case ACTION_TYPES.SET_CAPTCHA: {
			const captcha: string = action.payload;
			return { ...state, captcha: captcha };
		}

		case ACTION_TYPES.SHOW_REGISTRATION_FORM: {
			const showRegistrationForm: boolean = action.payload;
			return { ...state, showRegistrationForm: showRegistrationForm };
		}

		case ACTION_TYPES.SET_SNACK_BAR_DATA: {
			const snackBarDataRender: ISnackBarData = action.payload;
			return { ...state, snackBarDataRender: snackBarDataRender };
		}

		case ACTION_TYPES.SHOW_LOGIN_FORM: {
			const showLoginForm: boolean = action.payload;
			return { ...state, showLoginForm: showLoginForm };
		}
		case ACTION_TYPES.NEWS_ID: {
			const newsId: number = action.payload;
			return { ...state, newsId: newsId };
		}
		case ACTION_TYPES.MODULE_ID: {
			const moduleId: number = action.payload;
			return { ...state, moduleId: moduleId };
		}
		case ACTION_TYPES.SET_EMAIL: {
			const email: string = action.payload;
			return { ...state, email: email };
		}
		case ACTION_TYPES.SET_LANGUAGE: {
			const language: string = action.payload;
			return { ...state, language: language };
		}

		default: {
			return {
				...state,
			};
		}
	}
};
