import React, { FC, Fragment, useContext } from "react";
import { DICTIONARY, TYPE_MESSAGE } from "../../../enums/dictionary";
import {
	createStyles,
	makeStyles,
	Theme,
	Grid,
	TextField,
	Button,
	MenuItem,
	Typography,
	createMuiTheme,
	ThemeProvider,
	Paper,
} from "@material-ui/core";
import { useFormik } from "formik";
import DropzoneAreaExample from "./dropzone";
import { GoogleCapcha } from "../../recapcha/recapcha";
import { ContextStore } from "../../../store";
import { feedbackValidationSchema as validationSchema } from "./feedback.validation";
import { ISnackBarData } from "../../../interfaces/interfaces";
import { ACTION_TYPES } from "../../../reduser";
import { useTranslation } from "react-i18next";

export const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		container: {
			width: "100%",
			height: "auto",
			color: "white",
			display: "flex",
			justifyContent: "center",
		},
		btnStyle: {
			textTransform: "none",
			margin: theme.spacing(2),
			background: "#104eff",
			color: "white",
			"&:hover": {
				backgroundColor: "rgb(11, 54, 178)",
			},
		},
		formStyle: {
			width: "100%",
		},
		btnPosition: {
			display: "flex",
			justifyContent: "center",
			[theme.breakpoints.up("md")]: {
				justifyContent: "flex-end",
			},
		},
		inputStyle: {
			width: "45%",
			margin: theme.spacing(2),
		},
		title: {
			padding: theme.spacing(2),
		},
		formcontrol: {
			width: "100%",
			display: "flex",
			alignItems: "center",
		},

		selectStyle: {
			display: "flex",
			width: "100%",
			alignItems: "center",
		},
		dropzone: {
			width: "100%",
			display: "flex",
			justifyContent: "center",
		},
		textStyle: {
			padding: theme.spacing(1),
		},
		selectWrapper: {
			width: "100%",
		},
		capchaWrapper: {
			display: "flex",
			justifyContent: "space-around",
			alignItems: " center",
			padding: theme.spacing(2),
			width: "100%",
		},
		[theme.breakpoints.down("md")]: {
			selectStyle: {
				flexDirection: "column-reverse",
				alignItems: "center",
				width: "100%",
			},
		},
		[theme.breakpoints.down("sm")]: {
			capchaWrapper: {
				flexDirection: "column",
			},
			wrapper: {
				display: "flex",
				flexDirection: "column",
				alignItems: "center",
				width: "100%",
			},
			inputStyle: {
				width: "55%",
				minWidth: "224px",
			},
			title: {
				fontSize: "21px",
			},
		},
		notchedOutline: {
			border: "1px solid white",
		},

		icon: {
			fill: "white",
		},
		whiteColor: {
			color: "white",
		},

		captchaPosition: {
			display: "flex",
			justifyContent: "center",
		},
		paperStyle: {
			paddingLeft: theme.spacing(2),
			borderRadius: "15px",
		},
	})
);

const theme = createMuiTheme({
	palette: {
		primary: {
			main: "#ffffff",
		},
		secondary: {
			main: "#ffffff",
		},
		background: {
			paper: "#0ed1be",
		},
		text: {
			primary: "#ffffff",
			secondary: "#ffffff",
		},
	},
	typography: {
		fontFamily: [`"Gilroy-Medium"`, `"Gilroy-Regular"`].join(","),
		h1: {
			fontSize: "3rem",
		},
		h2: {
			fontSize: "2.5rem",
		},
		h3: {
			fontSize: "2rem",
		},
		h4: {
			fontSize: "1.5rem",
		},
		h5: {
			fontSize: "1.25rem",
		},
		h6: {
			fontSize: "1rem",
		},
	},

	overrides: {
		MuiSelect: {
			icon: {
				color: () => theme.palette.primary.main,
			},
		},
		MuiOutlinedInput: {
			notchedOutline: {
				borderColor: () => theme.palette.primary.main,
			},
		},
		MuiInputBase: {
			input: {
				borderColor: () => theme.palette.primary.main,
			},
		},

		MuiButton: {
			containedPrimary: {
				background: "red",
			},
		},
	},
});

export const Feedback: FC = () => {
	const classes = useStyles();
	const { state, dispatch } = useContext(ContextStore);
	const { t } = useTranslation();

	const {
		handleSubmit,
		handleChange,
		resetForm,
		values,
		errors,
		touched,
		handleBlur,
	} = useFormik({
		initialValues: {
			userName: "",
			email: "",
			phone: "",
			variantCall: "",
			titleMessage: "",
			message: "",
		},
		onSubmit: ({
			userName,
			email,
			phone,
			variantCall,
			titleMessage,
			message,
		}) => {
			if (state.captcha && state.captcha.length > 0) {
				console.log("values", values);
				resetForm();
				const snackData: ISnackBarData = {
					code: "200",
					message: t(DICTIONARY.MESSAGE_SUCCESS),
					typeMessage: TYPE_MESSAGE.SUCCESS,
				};
				dispatch({
					type: ACTION_TYPES.SET_SNACK_BAR_DATA,
					payload: snackData,
				});
			}
		},
		validationSchema,
	});

	return (
		<Fragment>
			<ThemeProvider theme={theme}>
				<Paper className={classes.paperStyle}>
					<Typography variant="h1" className={classes.title}>
						{t(DICTIONARY.FEEDBACK)}
					</Typography>
					<form onSubmit={handleSubmit} className={classes.formStyle}>
						<Grid
							container
							className={classes.container}
							spacing={2}
						>
							<Grid item xs={12} md={6}>
								<TextField
									size="small"
									value={values.userName}
									onChange={handleChange}
									id="userName"
									name="userName"
									error={
										!!(errors.userName && touched.userName)
									}
									helperText={errors.userName}
									onBlur={handleBlur}
									color="primary"
									fullWidth
									label={t(DICTIONARY.NAME)}
									variant="outlined"
								/>
							</Grid>
							<Grid item xs={12} md={6}>
								<TextField
									size="small"
									value={values.email}
									onChange={handleChange}
									id="email"
									name="email"
									error={!!(errors.email && touched.email)}
									helperText={errors.email}
									onBlur={handleBlur}
									color="primary"
									fullWidth
									label={t(DICTIONARY.E_MAIL)}
									variant="outlined"
								/>
							</Grid>
							<Grid item xs={12} md={6}>
								<TextField
									size="small"
									value={values.phone}
									onChange={handleChange}
									id="phone"
									name="phone"
									error={!!(errors.phone && touched.phone)}
									helperText={errors.phone}
									onBlur={handleBlur}
									color="primary"
									fullWidth
									label={t(DICTIONARY.PHONE)}
									variant="outlined"
								/>
							</Grid>
							<Grid item xs={12} md={10}>
								<TextField
									size="small"
									value={values.variantCall}
									onChange={handleChange}
									name="variantCall"
									id="variantCall"
									error={
										!!(
											errors.variantCall &&
											touched.variantCall
										)
									}
									helperText={errors.variantCall}
									onBlur={handleBlur}
									color="primary"
									select
									fullWidth
									label={t(DICTIONARY.VARIANT_CALL)}
									variant="outlined"
								>
									<MenuItem value={"Email"}>Email</MenuItem>
									<MenuItem value={"Телефон"}>
										Телефон
									</MenuItem>
									<MenuItem value={"	Не зв'язуватись зі мною"}>
										Не зв'язуватись зі мною
									</MenuItem>
								</TextField>
							</Grid>
							<Grid item xs={12} md={10}>
								<TextField
									size="small"
									value={values.titleMessage}
									onChange={handleChange}
									name="titleMessage"
									id="titleMessage"
									error={
										!!(
											errors.titleMessage &&
											touched.titleMessage
										)
									}
									helperText={errors.titleMessage}
									onBlur={handleBlur}
									color="primary"
									select
									fullWidth
									label={t(DICTIONARY.TITLE_MESSAGE)}
									variant="outlined"
								>
									<MenuItem value={"Ten"}>Ten</MenuItem>
									<MenuItem value={"Twenty"}>Twenty</MenuItem>
									<MenuItem value={"Thirty"}>Thirty</MenuItem>
								</TextField>
							</Grid>

							<Grid item xs={12} md={10}>
								<TextField
									value={values.message}
									onChange={handleChange}
									id="message"
									name="message"
									error={
										!!(errors.message && touched.message)
									}
									helperText={errors.message}
									onBlur={handleBlur}
									color="primary"
									multiline
									fullWidth
									label={t(DICTIONARY.MESSAGE)}
									variant="outlined"
								/>
							</Grid>
							<Grid item xs={12} md={10}>
								<Typography className={classes.textStyle}>
									{t(DICTIONARY.DROPZONETEXT)}
								</Typography>
							</Grid>
							<Grid item xs={12} md={10}>
								<DropzoneAreaExample />
							</Grid>

							<Grid
								item
								xs={12}
								md={5}
								className={classes.captchaPosition}
							>
								<GoogleCapcha />
							</Grid>
							<Grid
								item
								xs={12}
								md={5}
								className={classes.btnPosition}
							>
								<Button
									size="small"
									type="submit"
									variant="contained"
									className={classes.btnStyle}
								>
									{t(DICTIONARY.SEND)}
								</Button>
							</Grid>
						</Grid>
					</form>
				</Paper>
			</ThemeProvider>
		</Fragment>
	);
};
