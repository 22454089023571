import React, {Suspense} from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import './i18n/i18n';
import {CookiesProvider} from 'react-cookie';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {createStore, applyMiddleware, Store} from "redux";
import {Provider} from "react-redux";
import thunk from "redux-thunk";
import reducer from "./redux/reducers/justinReducer";
import {IInitialStateStore, StoreAction, DispatchType} from "./redux/types/type";
import {composeWithDevTools} from 'redux-devtools-extension';
import axios from "axios";

const composeEnhancers = composeWithDevTools({});
const store: Store<IInitialStateStore, StoreAction> & {
    dispatch: DispatchType,
} = createStore(reducer, composeEnhancers(applyMiddleware(thunk)));
axios.defaults.baseURL = process.env.REACT_APP_API_END_POINT;

ReactDOM.render(
    <Provider store={store}>
        <React.Fragment>
            <Suspense fallback="loading">
                <CookiesProvider>
                    <App/>
                </CookiesProvider>
            </Suspense>
        </React.Fragment>
    </Provider>,
    document.getElementById('root')
);



