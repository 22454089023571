import React, {FC, Fragment} from "react";
import {
    createStyles,
    makeStyles,
    Theme,
    Button
} from "@material-ui/core";
import {Grid} from "@material-ui/core";
import {DICTIONARY} from "../../../enums/dictionary";
import {useTranslation} from "react-i18next";
import axios from "axios";
import {async} from "q";

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: "100%",
            display: "flex",
        },
        wrapperInfo: {
            width: "100%",
            marginTop: "50px",
            background: "white",
            fontSize: "24px",
            lineHeight: "41px",
            "font-weight": "400",
            fontFamily: "Helvetica",
            borderRadius: "10px",
            padding: "65px 75px 100px 30px",
            ["@media (max-width:812px)"]: {
                padding: "12px 40px",
                marginTop: "0px",
            },
            "& h3": {
                fontSize: "36px",
                lineHeight: "41px",
                fontWeight: "700",
                fontFamily: "Helvetica",
            },
            "& p": {
                marginTop: "24px",
                lineHeight: "28px",
                "font-weight": "400",
                fontFamily: "Helvetica",
                ["@media (max-width:812px)"]: {
                    margin: "24px 0",
                },
            }
        },
        bt: {
            fontSize: "24px",
            lineHeight: "30px",
            "font-weight": "400",
            fontFamily: "Helvetica",
            padding: "15px 135px",
            textTransform: "capitalize"
        },
        wrapper_bt: {
            display: "flex",
            "justify-content": "center",
        }

    })
);


export const InfoPlugins: FC = () => {
    const {t} = useTranslation();
    const classname = useStyles();
    const download = async () => {
        return await axios({
            url: `download`,
            method: 'GET',
            responseType: 'blob',
        }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'test.pdf'); //or any other extension
            document.body.appendChild(link);
            return link.click();
        });
    };
    return (
        <Fragment>
            <Grid className={classname.root} container spacing={1}>
                <Grid className={classname.wrapperInfo}>
                    <h3>Заголовок</h3>
                    <p> Текст </p>
                    <div className={classname.wrapper_bt}>
                        <Button onClick={download} className={classname.bt} variant="contained"
                                color="primary">
                            Завантажити
                        </Button>
                    </div>
                </Grid>
            </Grid>
        </Fragment>
    );
};
