import React, {Fragment} from "react";
import {
    createStyles,
    makeStyles,
    Button,
} from "@material-ui/core";
import {Grid} from "@material-ui/core";
import {DICTIONARY} from "../../../enums/dictionary";
import IntegrationImgUA from "../../../images/integrationStepUA.svg";
import IntegrationImgRU from "../../../images/integrationStepRU.svg";
import IntegrationImgEN from "../../../images/integrationStepEN.svg";
import SupportImg from "../../../images/support_FAQ.svg";
import {useTranslation} from "react-i18next";
import {IInitialStateStore} from "../../../redux/types/type";
import {Dispatch} from "redux";
import {HANDLE_MODAL_SUPPORT} from "../../../redux/actions/actionTypes";
import {connect, ConnectedProps} from "react-redux";
import {getFAQ} from "./ListQuestion";
import {Link} from "react-router-dom";
import {MobileNavigation} from "../../navigation/MobileNavigation";

export const useStyles = makeStyles(() =>
        createStyles({
            root: {
                width: "100%",
                display: "flex",
            },
            wrapperInfo: {
                width: "100%",
                display: "flex",
                marginTop: "50px",
                background: "white",
                fontSize: "24px",
                lineHeight: "28px",
                alignItems: "center",
                "font-weight": "400",
                fontFamily: "Helvetica",
                borderRadius: "10px",
                padding: "65px 15% 50px 15%",
                ["@media (max-width:812px)"]: {
                    flexDirection: "column",
                    marginTop: "25px",
                    padding: "50px 30px",

                },
                "& h3": {
                    // width: "50%",
                    fontSize: "20px",
                    lineHeight: "24px",
                    fontWeight: "700",
                    fontFamily: "Helvetica",
                    marginBottom: "50px",
                    ["@media (max-width:812px)"]: {
                        width: "100%",
                        marginBottom: "30px",
                    },
                },
                "& p": {
                    marginTop: "20px",
                    fontSize: "18px",
                    lineHeight: "23px",
                    "font-weight": "400",
                    fontFamily: "Helvetica",
                },
                "& a": {
                    textDecoration: "none",
                },
                "& .MuiPaper-elevation1": {
                    boxShadow: "none",
                    paddingBottom: "70px",
                    ["@media (max-width:812px)"]: {
                        paddingBottom: "30px",
                    }
                },
                "& .MuiAccordion-root:before": {
                    display: "none",
                },
                "& .Mui-expanded ": {
                    margin: "0",
                },
            },

            news: {
                display: "flex",
                width: "100%"
            },
            wrapperIntegration: {
                display: "flex",
                width: "100%",
                "justify-content": "space-between",
                background: "white",
                lineHeight: "28px",
                borderRadius: "10px",
                padding: "65px 15% 50px 15%",
                ["@media (max-width:812px)"]: {
                    flexDirection: "column",
                    padding: "30px 12px 50px 12px",
                    "& img": {
                        display: "none",
                    },
                    "& h3": {
                        marginBottom: "30px",
                    },
                },

            },
            integrationWrapper: {
                "& h3": {
                    fontSize: "24px",
                    fontWeight: "700",
                    fontFamily: "Helvetica",
                },
                "& p": {
                    fontSize: "16px",
                    marginTop: "20px",
                    lineHeight: "23px",
                    "font-weight": "400",
                    fontFamily: "Helvetica",
                    ["@media (max-width:812px)"]: {
                        marginTop: "0",
                    },
                },
                "& .MuiPaper-elevation1": {
                    boxShadow: "none",
                    padding: "0 !important",
                    ["@media (max-width:812px)"]: {
                        paddingBottom: "0 !important",
                    },
                }, "& .MuiAccordion-root:before": {
                    display: "none",
                },
                "& .MuiAccordionDetails-root": {
                    padding: "8px 0",
                },
                "& .Mui-expanded ": {
                    padding: "0",
                },
            },
            bt: {
                marginTop: "60px",
                fontSize: "20px",
                lineHeight: "25px",
                "font-weight": "400",
                fontFamily: "Helvetica",
                padding: "15px 50px",
                textTransform: "initial",
                ["@media (max-width:812px)"]:
                    {
                        display: "none",
                    }
            },
            boxInfo: {
                width: "100%",
                ["@media (max-width:812px)"]: {
                    "padding": "0 0 50px",
                    "& p": {
                        fontSize: "20px",
                        lineHeight: "23px",
                    }
                }
            },
            steps: {
                fontSize: "24px",
                lineHeight: "28px !important",
                "font-weight": "400",
                marginTop: "0px !important",
            },
            box: {
                width: "100%",
                display: "flex",
                flexDirection: "column",
                "& .MuiPaper-elevation1": {
                    boxShadow: "none",
                    "padding": "0 8% 70px",
                    ["@media (max-width:812px)"]: {
                        padding: "0 0 30px 0",
                    },
                },
                "& .MuiAccordion-root:before": {
                    display: "none",
                },
                "& .Mui-expanded ": {
                    margin: "0",
                    padding: "0",
                },
            },
            btMobile: {
                display: "none",
                fontSize: "18px",
                lineHeight: "25px",
                "font-weight": "400",
                fontFamily: "Helvetica",
                padding: "15px 50px",
                textTransform: "initial",
                ["@media (max-width:812px)"]:
                    {
                        display: "block",
                        marginTop: "0px",
                    },
            },
            mr_0: {
                margin: "0 !important",
            },
            mobileImg: {
                display: "none",
                ["@media (max-width:812px)"]: {
                    display: "block",
                    alignSelf: "flex-start",
                    width: "70%",
                    marginBottom: "25px",
                },
            },
            desktopImg: {
                ["@media (max-width:812px)"]: {
                    display: "none",
                },
            },
            textDecorationNone: {
                textDecoration: "none",
            }

        })
    )
;
const mapStateToProps = (state: IInitialStateStore) => ({isModalSupport: state.isModalSupport});

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        handleModal: () => dispatch({type: HANDLE_MODAL_SUPPORT})
    }
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>

interface IProps extends PropsFromRedux {
}

function getImg() {
    switch (localStorage.getItem("i18nextLng")) {
        case "ru" :
            return <img src={IntegrationImgRU} alt={IntegrationImgRU.toString()}/>
        case "ua" :
            return <img src={IntegrationImgUA} alt={IntegrationImgUA.toString()}/>
        case "en" :
            return <img src={IntegrationImgEN} alt={IntegrationImgEN.toString()}/>
    }
}


const Support = (props: IProps) => {
    const {t} = useTranslation();
    const classname = useStyles();
    const {handleModal} = props;
    localStorage.getItem("i18nextLng");

    return (
        <Fragment>
            <Grid className={classname.root} container spacing={1}>
                <Grid className={classname.box}>
                    <div className={classname.wrapperIntegration}>
                        <div className={classname.integrationWrapper}>
                            <h3>{t(DICTIONARY.FAQ)}</h3>
                            <p>{t(DICTIONARY.HAPPY_TO_ANSWER)}</p>
                            <p className={classname.mr_0}>{t(DICTIONARY.SAVE_YOUR_TIME)}</p>
                            {getFAQ()}
                        </div>
                    </div>
                </Grid>
                <Grid className={classname.wrapperInfo}>
                    <img className={classname.mobileImg} src={SupportImg} alt={SupportImg.toString()}/>
                    <div>
                        <h3>{t(DICTIONARY.NEED_HELP_HEADER)}</h3>
                        <div className={classname.boxInfo}>
                            <p className={classname.steps}>{t(DICTIONARY.NEED_HELP_SUB)}</p>
                        </div>
                    </div>
                    <div>
                        <img className={classname.desktopImg} src={SupportImg} alt={SupportImg.toString()}/>
                        <Link to="apply">
                            <Button
                                className={classname.bt}
                                variant="contained"
                                color="primary">
                                {t(DICTIONARY.ASK_A_QUESTION)}
                            </Button>
                        </Link>

                        {/*<a className={classname.textDecorationNone} href="send-message">*/}
                        {/*    <Button*/}
                        {/*        className={classname.btMobile}*/}
                        {/*        variant="contained"*/}
                        {/*        color="primary">*/}
                        {/*        {t(DICTIONARY.ASK_A_QUESTION)}*/}
                        {/*    </Button>*/}
                        {/*</a>*/}
                    </div>
                </Grid>
            </Grid>
        </Fragment>
    );
};

export default connector(Support);