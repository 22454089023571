import * as yup from "yup";
import {FORM_NAMES} from "../../../enums/dictionary";

export const duplicateEmail = "alredy taken";
export const required = "field is required";
export const emailNotLongEnough = "email must be at least 3 characters";
export const passwordlNotLongEnough = "password must be at least 8 characters";
export const invalidEmail = "email must be a valid email";

export const registerNameValidation = yup
    .string()
    .required(required);

export const registerEmailValidation = yup
    .string()
    .required(required)
    .email();

export const registerPasswordValidation = yup
    .string()
    .required(required)
    .min(8, passwordlNotLongEnough)
    .max(20);

export const registerPasswordConfirmValidation = yup
    .string()
    .required(required)
    .oneOf([yup.ref(FORM_NAMES.PASSWORD)], "Passwords must match");

// const phoneRegExp = /^(?:(?:\(?(?:00|\+)([1-4]\d\d|[1-9]\d?)\)?)?[\-\.\ \\\/]?)?((?:\(?\d{1,}\)?[\-\.\ \\\/]?){0,})(?:[\-\.\ \\\/]?(?:#|ext\.?|extension|x)[\-\.\ \\\/]?(\d+))?$/;

export const registerPhoneValidation = yup
    .string()
    // .matches(phoneRegExp, "Phone number is not valid");


export const registerCompanyNameValidation = yup
    .string();


export const validationSchemaAuthorization = yup.object({
    [FORM_NAMES.E_MAIL]: registerEmailValidation,
    [FORM_NAMES.PASSWORD]: registerPasswordValidation,
});

export const validationSchemaRegistration = yup.object({
    [FORM_NAMES.NAME]: registerNameValidation,
    [FORM_NAMES.E_MAIL]: registerEmailValidation,
    [FORM_NAMES.PASSWORD]: registerPasswordValidation,
    [FORM_NAMES.CONFIRM_PASSWORD]: registerPasswordConfirmValidation,
    [FORM_NAMES.PHONE]: registerPhoneValidation,
    [FORM_NAMES.COMPANY_NAME]: registerCompanyNameValidation,
});
