import React, { FC, Fragment, useContext } from "react";
import {
	createStyles,
	Theme,
	makeStyles,
	Grid,
	TextField,
	Button,
	Typography,
} from "@material-ui/core";
import { areaValidationSchema as validationSchema } from "./area.form.validation";
import { ISnackBarData } from "../../../interfaces/interfaces";
import { ACTION_TYPES } from "../../../reduser";
import { TYPE_MESSAGE, DICTIONARY } from "../../../enums/dictionary";
import { useFormik } from "formik";
import { ContextStore } from "../../../store";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		rootContainer: {
			display: "flex",
			justifyContent: "center",
		},
		formStyle: {
			width: "100%",
		},
		container: {
			width: "100%",
			height: "auto",
			color: "white",
			display: "flex",
			justifyContent: "center",
			flexDirection: "column",
			alignItems: "center",
		},
		inputStyle: {
			paddingBottom: theme.spacing(2),
			width: "100%",
		},
		btnStyle: {
			margin: theme.spacing(2),
		},
		btnPosition: {
			display: "flex",
			justifyContent: "center",
		},
		inputWidth: {
			width: "100%",
		},
		profileStyle: {
			fontSize: "25px",
			paddingBottom: theme.spacing(2),
		},
	})
);

export const AreaForm: FC = () => {
	const classes = useStyles();
	const { t } = useTranslation();

	const { dispatch } = useContext(ContextStore);

	const {
		handleSubmit,
		handleChange,
		resetForm,
		values,
		errors,
		touched,
		handleBlur,
	} = useFormik({
		initialValues: {
			userName: "",
			email: "",
			phone: "",
			companyName: "",
		},
		onSubmit: ({ userName, email, phone, companyName }) => {
			console.log("values", values);
			resetForm();
			const snackData: ISnackBarData = {
				code: "200",
				message: "Дані успішно змінено",
				typeMessage: TYPE_MESSAGE.SUCCESS,
			};
			dispatch({
				type: ACTION_TYPES.SET_SNACK_BAR_DATA,
				payload: snackData,
			});
		},
		validationSchema,
	});

	const handleExitArea = () => {
		dispatch({
			type: ACTION_TYPES.SHOW_LOGIN_FORM,
			payload: true,
		});
		document.cookie = "token= ; expires = Thu, 01 Jan 1970 00:00:00 GMT";

		localStorage.removeItem("email");
	};

	return (
		<Fragment>
			<Grid container spacing={0} className={classes.rootContainer}>
				<Typography variant="h1" className={classes.profileStyle}>
					{t(DICTIONARY.PROFILE)}
				</Typography>
				<form onSubmit={handleSubmit} className={classes.formStyle}>
					<Grid item xs={12} className={classes.container}>
						<Grid
							item
							xs={12}
							sm={6}
							className={classes.inputWidth}
						>
							<TextField
								size="small"
								className={classes.inputStyle}
								value={values.userName}
								onChange={handleChange}
								id="userName"
								name="userName"
								error={!!(errors.userName && touched.userName)}
								helperText={errors.userName}
								onBlur={handleBlur}
								color="primary"
								label={t(DICTIONARY.NAME)}
								variant="outlined"
							/>
						</Grid>
						<Grid
							item
							xs={12}
							sm={6}
							className={classes.inputWidth}
						>
							<TextField
								size="small"
								className={classes.inputStyle}
								value={values.email}
								onChange={handleChange}
								id="email"
								name="email"
								error={!!(errors.email && touched.email)}
								helperText={errors.email}
								onBlur={handleBlur}
								color="primary"
								label={t(DICTIONARY.E_MAIL)}
								variant="outlined"
							/>
						</Grid>
						<Grid
							item
							xs={12}
							sm={6}
							className={classes.inputWidth}
						>
							<TextField
								size="small"
								className={classes.inputStyle}
								value={values.phone}
								onChange={handleChange}
								id="phone"
								name="phone"
								error={!!(errors.phone && touched.phone)}
								helperText={errors.phone}
								onBlur={handleBlur}
								color="primary"
								label={t(DICTIONARY.PHONE)}
								variant="outlined"
							/>
						</Grid>
						<Grid
							item
							xs={12}
							sm={6}
							className={classes.inputWidth}
						>
							<TextField
								size="small"
								className={classes.inputStyle}
								value={values.companyName}
								onChange={handleChange}
								id="companyName"
								name="companyName"
								error={
									!!(
										errors.companyName &&
										touched.companyName
									)
								}
								helperText={errors.companyName}
								onBlur={handleBlur}
								color="primary"
								label={t(DICTIONARY.COMPANY_NAME)}
								variant="outlined"
							/>
						</Grid>
					</Grid>

					<Grid item xs={12} className={classes.btnPosition}>
						<Button
							type="submit"
							variant="contained"
							color="primary"
							size="small"
							className={classes.btnStyle}
						>
							{t(DICTIONARY.EDIT)}
						</Button>
						<Button
							variant="contained"
							color="primary"
							size="small"
							className={classes.btnStyle}
							onClick={handleExitArea}
						>
							{t(DICTIONARY.EXIT)}
						</Button>
					</Grid>
				</form>
			</Grid>
		</Fragment>
	);
};
