import {FieldAttributes, useField} from "formik";
import React from "react";
import {TextField} from "@material-ui/core";
import {useStylesTextFieldPlaceHolder} from "./cast.textfield.password";


type MyTextFieldProps = {label: string, variant: any, size: any} & FieldAttributes<{}>;

export const CastTextField: React.FC<MyTextFieldProps> = ({label, variant, size, placeholder, required,  ...props}) => {
    const [field, meta] = useField<{}>(props);
    let errorText = meta.error && meta.touched ? meta.error : '';
    const classes = useStylesTextFieldPlaceHolder();

    return <TextField className={classes.root}
                      {...field}
                      placeholder={placeholder}
                      helperText={errorText}
                      label={label}
                      variant={variant}
                      size={size}
                      error={!!errorText}
                      required={required}
    />;
};








