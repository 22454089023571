import React, {FC, Fragment, useState, useEffect} from "react";
import {
    createStyles,
    makeStyles,
    Theme,
} from "@material-ui/core";
import {DICTIONARY} from "../../enums/dictionary";
import {Link, useLocation} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {MobileNavigation} from "./MobileNavigation";

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
            height: "125px",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-around",

            ["@media (max-width:812px)"]: {
                display: "none",
            },
            "& a": {
                position: "relative",
                color: "white",
                textDecoration: "none",
                fontSize: "19px",
                lineHeight: "22px",
                fontWeight: "400",
                fontFamily: "Helvetica",
                "&:hover": {
                    border: "1px solid white",
                    borderRadius: "30px",
                    padding: "10px 20px"
                    // fontWeight: "700",
                },
                ["@media (max-width:1100px)"]: {
                    fontSize: "18px",
                },

            },
        },
        selected: {
            color: "#white !important",
            "font-weight": "700 !important",
            "&:before": {
                position: "absolute",
                content: '""',
                bottom: -5,
                right: 0,
                width: "100%",
                height: 2,
                background: "white"
            },
            "&:hover:before": {
                // background: "black",
                display: "none",
            },
        },
    })
);

export const Navigation: FC = () => {
    const classes = useStyles();
    const {t} = useTranslation();
    const location = useLocation();

    const [selectURL, setSelectURL] = useState("/");
    useEffect(() => {
        setSelectURL(`/${location.pathname.toString().split("/")[1]}`)
    });
    const navItems = [
        {
            text: DICTIONARY.API,
            link: "/doc",
        },
        {
            text: DICTIONARY.STARTINTEGRATION,
            link: "/integration",
        },
        {
            text: DICTIONARY.PLUGINSANDRESOURCES,
            link: "/plugins_resources",
        },
        // {
        //     text: DICTIONARY.ALL_NEWS,
        //     link: "/news",
        // },
        {
            text: DICTIONARY.SERVICE_SUPPORT,
            link: "/support",
        },
    ];
    return (
        <Fragment>
            <div className={classes.root}>
                {navItems.map((item) => {
                    const isSelect = selectURL === item.link ? classes.selected : "";
                    return <Link to={item.link} key={item.text}
                                 className={isSelect}>{t(item.text)}</Link>
                })}
            </div>
            {/* <MobileNavigation nowLink={t(DICTIONARY.ASK_A_QUESTION)}/> */}
        </Fragment>
    );
};
