import React, {Fragment} from "react";
import {
    createStyles,
    makeStyles,
} from "@material-ui/core";
import {Grid} from "@material-ui/core";
import {DICTIONARY} from "../../../enums/dictionary";
import {useTranslation} from "react-i18next";
import LOGO from '../../../images/just.png';

export const useStyles = makeStyles(() =>
        createStyles({
                root: {
                    display: "flex",
                    width: "100%",
                    background: "white",
                    fontFamily: "Helvetica",
                    "font-weight": "700",
                    padding: "40px 30px",
                    borderRadius: "10px",
                    "& label": {
                        fontSize: "24px",
                        lineHeight: "28px",
                        "font-weight": "400",
                    }
                },
                boxText: {
                    display: "flex",
                    flexDirection: "column",
                    width: "60%",
                    "& h3": {
                        fontSize: "36px",
                        lineHeight: "41px",
                        marginBottom: "40px",
                    },

                },
                wrapperLinks: {
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    marginTop: "20px",
                    "& label": {
                        "font-weight": "700",
                    },
                    "& img": {
                        width: "max-content",
                        margin: "10px 0 30px 0",
                    }
                },
                blue: {
                    color: "#104EFF"
                },
                wrapperBtn: {
                    fontSize: "24px",
                    lineHeight: "30px",
                    "& a": {
                        textDecoration: "none",
                        background: "#104EFF",
                        color: "white",
                        padding: "15px 40px",
                        borderRadius: "7px",
                    }

                },
                mr_r20: {
                    marginRight: "20px"
                }
            }
        )
    )
;

export const GetKeyPage = () => {
    const {t} = useTranslation();
    const classname = useStyles();

    return (
        <Fragment>
            <Grid className={classname.root} container spacing={1}>
                <div className={classname.boxText}>
                    <h3>{t(DICTIONARY.ACCESS_JUSTIN_API)}</h3>
                    <label>{t(DICTIONARY.RECEIVE_KEY_SUB_1)}<span
                        className={classname.blue}>{t(DICTIONARY.API_JUSTIN)}</span>{t(DICTIONARY.RECEIVE_KEY_SUB_2)}
                    </label><br/>
                    <label>{t(DICTIONARY.REGISTER_FOR_SITE)}</label><br/>
                    <label>{t(DICTIONARY.REGISTER_FOR_SITE_SUB_1)}</label><br/>
                    <label>{t(DICTIONARY.REGISTER_FOR_SITE_SUB_2)}</label><br/>
                    <label>{t(DICTIONARY.REGISTER_FOR_SITE_SUB_3)}</label><br/>
                    <label> <span className={classname.blue}>{t(DICTIONARY.ATTENTION)}</span>
                        {t(DICTIONARY.WHEN_REGISTRATION)}</label><br/>
                    <label>{t(DICTIONARY.REGISTER_FOR_SITE_SUB_4)}</label><br/>
                    <label>{t(DICTIONARY.FOR_EVERYONE_FIRST_RECEIVED)}</label>
                </div>
                <div className={classname.wrapperLinks}>
                    <label>{t(DICTIONARY.LOGIN_YOUR_JUSTIN)}</label>
                    <img src={LOGO} alt="justin"/>
                    <div className={classname.wrapperBtn}>
                        <a className={classname.mr_r20} href="https://my.justin.ua/login" target="_blank">
                            {t(DICTIONARY.INDIVIDUALS)}
                        </a>
                        <a href="https://biz.justin.ua/login" target="_blank">
                            {t(DICTIONARY.LEGAL_ENTITIES)}
                        </a>
                    </div>
                </div>
            </Grid>
        </Fragment>
    );
};

