import React, {Component, Fragment} from "react";
import {
    createStyles,
    Button,
    Grid, withStyles, WithStyles,
} from "@material-ui/core";
import {DICTIONARY} from "../../../enums/dictionary";
import {withTranslation, WithTranslation} from "react-i18next";
import ReCAPTCHA from "react-google-recaptcha";
import IntersectImg from "../../../images/intersect.svg";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import {Link} from "react-router-dom";
import {MobileNavigation} from "../../navigation/MobileNavigation";

export const useStyles = () =>
    createStyles({
            container: {
                position: "relative",
                background: "white",
                width: "600px",
                overflow: "auto",
                marginTop: "10px",
                color: "#104EFF",
                borderRadius: "20px",
                ["@media (max-width:812px)"]: {
                    width: "100%",
                    marginTop: "0px",
                },
            }
            ,
            intersect: {
                position: "absolute",
                bottom: 0,
                right: 0,
            }
            ,
            body: {
                padding: "60px 0 0 30px",
                fontSize: "30px",
                lineHeight: "37px",
                "font-weight": "700",
                fontFamily: "Helvetica",
                "& <:nth-chlid(1)": {
                    marginBottom: "50px"
                },
                "& div": {
                    display: "flex",
                    "& img": {
                        marginRight: "20px",
                    }
                }
            }
            ,
            support: {
                marginBottom: "30px",
            },
            header: {
                display: "flex",
                "justify-content": "space-between",
                alignItems: "center",
                "& label": {
                    fontSize: "22px",
                    lineHeight: "28px",
                    "font-weight": "300",
                    margin: "20px",
                },
                "& svg": {
                    transform: "rotate(90deg)",
                    marginLeft: "10px",
                },


            }
            ,
            wrapperForm: {
                display: "flex",
                "flex-direction": "column",
                padding: "0px 35px 65px 35px",
                "& span": {
                    fontSize: "18px",
                    color: "black",
                    margin: "10px 0",
                },
                "& input, select": {
                    paddingLeft: "10px",
                    height: "40px",
                    border: "1px solid black",
                    borderRadius: "7px",
                    marginBottom: "40px",
                },
                "& :nth-child(1)": {
                    marginTop: "0px",
                },
                "& textarea": {
                    minHeight: "100px",
                }

            },
            btnStyle: {
                "z-index": "1",
                padding: "20px 0",
                textTransform: "none",
                "& span": {
                    color: "white",
                    margin: "0",
                },

            },
            wrapperCheck: {
                display: "flex",
                "align-items": "center",
                margin: "30px 0 50px 0",
                "& span": {
                    margin: "0",
                },
                "& input": {
                    width: "34px",
                    marginRight: "15px",
                },
                "& a": {
                    fontSize: "24px",
                    marginLeft: "10px",
                }
            },
            error: {
                color: "red !important",
                margin: "0!important",
            },
            wrapperCaptcha: {
                "& .rc-anchor-light": {
                    width: "99% !important",
                },
                margin: "10px 0",
            }

        }
    );

interface IProps extends WithTranslation {
    t: any
    classes?: any
}

interface IState {
}

class SendMessageComponent extends Component<IProps & WithStyles<typeof useStyles>, IState> {

    state = {
        category: '',
        code: "",
        keyAccount: "",
        firstName: "",
        emailAddresses: "",
        messageHeader: "",
        message: "",
        isAllRight: false,
        isValid: true,
    };
    onSubmit = (data: any) => {
        const target = data.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        this.setState({
            [name]: value
        });
    };
    validForm = () => {
        if (this.state.category && this.state.code && this.state.keyAccount &&
            this.state.firstName && this.state.emailAddresses) {
            this.setState({isValid: true});
        } else {
            this.setState({isValid: false});
            return
        }
        alert("Request");
    };

    onChange = (value: any) => {
        this.setState({
            isAllRight: !!value
        });
    };

    render() {
        const {t, classes} = this.props;
        return (
            <Fragment>
                <Grid container spacing={1}>
                    {/*<MobileNavigation nowLink={t(DICTIONARY.ASK_A_QUESTION)}/>*/}
                    <div className={classes.container}>
                        <div className={classes.header}>
                            <Link to="/support">
                                <ExpandMoreIcon/>
                            </Link>
                            <label>{t(DICTIONARY.REQUIRED_FIELDS)}</label>
                        </div>
                        <div className={classes.container}>
                            <div className={classes.wrapperForm}>
                                <span>{t(DICTIONARY.CATEGORY_YOUR_QUESTION)}</span>
                                <select name={this.state.category}>
                                    <option
                                        value={t(DICTIONARY.CATEGORY_YOUR_QUESTION_SUB_1)}>{t(DICTIONARY.CATEGORY_YOUR_QUESTION_SUB_1)}</option>
                                    <option
                                        value={t(DICTIONARY.CATEGORY_YOUR_QUESTION_SUB_2)}>{t(DICTIONARY.CATEGORY_YOUR_QUESTION_SUB_2)}</option>
                                    <option
                                        value={t(DICTIONARY.CATEGORY_YOUR_QUESTION_SUB_3)}>{t(DICTIONARY.CATEGORY_YOUR_QUESTION_SUB_3)}</option>
                                    <option
                                        value={t(DICTIONARY.CATEGORY_YOUR_QUESTION_SUB_4)}>{t(DICTIONARY.CATEGORY_YOUR_QUESTION_SUB_4)}</option>
                                    <option
                                        value={t(DICTIONARY.CATEGORY_YOUR_QUESTION_SUB_5)}>{t(DICTIONARY.CATEGORY_YOUR_QUESTION_SUB_5)}</option>
                                </select>
                                <span>{t(DICTIONARY.REGISTRATION_CODE)}</span>
                                <input name="code" value={this.state.code} onChange={this.onSubmit}/>
                                <span>{t(DICTIONARY.COMPANY_NAME_OR_KEY)}</span>
                                <input name="keyAccount" value={this.state.keyAccount}
                                       onChange={this.onSubmit}/>
                                <span>{t(DICTIONARY.YOUR_NAME)}</span>
                                <input name="firstName" value={this.state.firstName} onChange={this.onSubmit}/>
                                <span>{t(DICTIONARY.YOUR_EMAIL)}</span>
                                <input name="emailAddresses" value={this.state.emailAddresses}
                                       onChange={this.onSubmit}/>
                                <span>{t(DICTIONARY.MESSAGE_HEADER)}</span>
                                <input name="messageHeader" value={this.state.messageHeader} onChange={this.onSubmit}/>
                                <span>*{t(DICTIONARY.MESSAGE)}</span>
                                <textarea name="message" value={this.state.message} onChange={this.onSubmit}/>
                                <div className={classes.wrapperCaptcha}>
                                    <ReCAPTCHA
                                        sitekey="6LccqrsZAAAAAAUOuIOSM-aoAJK73RmVAHzQSiO-"
                                        onChange={this.onChange}
                                    />
                                </div>
                                {!this.state.isValid &&
                                <span className={classes.error}>{t(DICTIONARY.ERROR_REQUIRED)}</span>}
                                <Button
                                    type="submit"
                                    className={classes.btnStyle}
                                    variant="contained"
                                    color="primary"
                                    disabled={!this.state.isAllRight}
                                    onClick={this.validForm}>
                                    {t(DICTIONARY.SEND_MESSAGE)}
                                </Button>
                            </div>
                        </div>

                        <img className={classes.intersect} src={IntersectImg} alt={IntersectImg.toString()}/>
                    </div>
                </Grid>
            </Fragment>
        )
    }
}

const SendMessageStyle = withStyles(useStyles)(SendMessageComponent);
export const SendMessage = withTranslation()(SendMessageStyle);