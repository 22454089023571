import * as actionTypes from "./actionTypes";
import {IInitialStateStore, SetLanguage, StoreAction} from "../types/type";

export function handleModalContacts(stateStore: IInitialStateStore) {
    const action: StoreAction = {
        type: actionTypes.HANDLE_MODAL_CONTACTS,
        payload: stateStore,
    };
    return action;
}

export function handleModalIntegration(stateStore: IInitialStateStore) {
    const action: StoreAction = {
        type: actionTypes.HANDLE_MODAL_INTEGRATION,
        payload: stateStore,
    };
    return action;
}

export function handleModalSupport(stateStore: IInitialStateStore) {
    const action: StoreAction = {
        type: actionTypes.HANDLE_MODAL_SUPPORT,
        payload: stateStore,
    };
    return action;
}

export function handleModalAuthorization(stateStore: IInitialStateStore) {
    const action: StoreAction = {
        type: actionTypes.HANDLE_MODAL_AUTHORIZATION,
        payload: stateStore,
    };
    return action;
}

export function handleModalRegistration(stateStore: IInitialStateStore) {
    const action: StoreAction = {
        type: actionTypes.HANDLE_MODAL_REGISTRATION,
        payload: stateStore,
    };
    return action;
}

export function setListMethodApi(listMethod: any) {
    const action: StoreAction = {
        type: actionTypes.SET_METHODS_API,
        payload: listMethod,
    };
    return action;
}

export function setLanguage(lang: any) {
    const action: StoreAction = {
        type: actionTypes.SET_LANGUAGE,
        payload: lang
    };
    return action;
}


