import React, {Fragment} from "react";
import {
    createStyles,
    makeStyles,
} from "@material-ui/core";
import {DICTIONARY} from "../../enums/dictionary";
import FacebookImg from "../../images/facebook.svg";
import TwitterImg from "../../images/twitter.svg";
import Justin2021Img from "../../images/justin2021.svg";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {IInitialStateStore} from "../../redux/types/type";
import {Dispatch} from "redux";
import {HANDLE_MODAL_CONTACTS} from "../../redux/actions/actionTypes";
import {connect, ConnectedProps} from "react-redux";


export const useStyles = makeStyles(() =>
    createStyles({
        root: {
            flexGrow: 1,
            backgroundColor: "white",
            height: "125px",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-evenly",
            ["@media (max-width:812px)"]: {
                flexDirection: "column",
                height: "auto",
                padding: "30px 60px 35px 15px",
                "align-items": "initial",
            },
            "& a, span": {
                cursor: "pointer",
                color: "#104EFF",
                textDecoration: "none",
                fontSize: "18px",
                lineHeight: "22px",
                fontWeight: "300",
                fontFamily: "Helvetica",
                "&:hover": {
                    color: "black",
                    fontSize: "22px",
                    lineHeight: "24px",
                },
            },
        },

        logoSize: {
            width: "32px",
            marginRight: "14px",
            "&:hover": {
                width: '40px',
            },
            ["@media (max-width:812px)"]: {
                width: "40px",
            },
        },
        logoPosition: {
            display: "flex",
            justifyContent: "center",
            flexBasis: "none",
            ["@media (max-width:812px)"]: {
                display: "none",
            },
        },
        logo: {
            display: "flex",
            justifyContent: "center",
            flexBasis: "none",
            "& img": {
                width: "80%",
                ["@media (max-width:812px)"]: {
                    width: "auto",
                }
            }
        },
        isMobileSoc: {
            display: "none",
            ["@media (max-width:812px)"]: {
                display: "flex",
                "justify-content": "flex-start",
                padding: "25px 0",
                "& a": {
                    "margin-right": "5%",
                }
            },
        },
        pd_top_mobile: {
            ["@media (max-width:812px)"]: {
                paddingTop: "20px",
                width: "39px",
                marginRight: "14px",
            },
        }


    })
);

const mapStateToProps = (state: IInitialStateStore) => ({isModalContacts: state.isModalContacts});

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        handleModal: () => dispatch({type: HANDLE_MODAL_CONTACTS})
    }
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>

interface IProps extends PropsFromRedux {
}


const Footer = (props: IProps) => {
    const {handleModal} = props;
    const classes = useStyles();
    const {t} = useTranslation();

    function LogoSoc() {
        return <>
            <a href="https://www.facebook.com/justinpostservice">
                <img src={FacebookImg} className={classes.logoSize} alt="FacebookImg"/>
            </a>
            <a href="https://t.me/justin_support_api">
                <img src={TwitterImg} className={classes.logoSize} alt="TwitterImg"/>
            </a>
        </>
    }

    return (
        <Fragment>
            <div className={classes.root}>
                <div className={classes.logoPosition}>
                    <LogoSoc/>
                </div>
                <Link to="/main">{t(DICTIONARY.TERMSOFPROVISION)}</Link>
                <span className={classes.pd_top_mobile} onClick={() => handleModal()}>{t(DICTIONARY.CONTACTS)}</span>
                <div className={classes.isMobileSoc}>
                    <LogoSoc/>
                </div>
                <div className={classes.logo}>
                    <img src={Justin2021Img} alt="Justin2021Img"/>
                </div>
            </div>
        </Fragment>
    );
};

export default connector(Footer);
