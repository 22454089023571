export enum DICTIONARY {
    INDIVIDUALS = "INDIVIDUALS",
    LEGAL_ENTITIES = "LEGAL_ENTITIES",
    ACCESS_JUSTIN_API = "ACCESS_JUSTIN_API",
    RECEIVE_KEY_SUB_1 = "RECEIVE_KEY_SUB_1",
    API_JUSTIN = "API_JUSTIN",
    RECEIVE_KEY_SUB_2 = "RECEIVE_KEY_SUB_2",
    LOGIN_YOUR_JUSTIN = "LOGIN_YOUR_JUSTIN",
    REGISTER_FOR_SITE = "REGISTER_FOR_SITE",
    REGISTER_FOR_SITE_SUB_1 = "REGISTER_FOR_SITE_SUB_1",
    REGISTER_FOR_SITE_SUB_2 = "REGISTER_FOR_SITE_SUB_2",
    REGISTER_FOR_SITE_SUB_3 = "REGISTER_FOR_SITE_SUB_3",
    ATTENTION = "ATTENTION",
    WHEN_REGISTRATION = "WHEN_REGISTRATION",
    REGISTER_FOR_SITE_SUB_4 = "REGISTER_FOR_SITE_SUB_4",
    FOR_EVERYONE_FIRST_RECEIVED = "FOR_EVERYONE_FIRST_RECEIVED",
    REQUEST_PARAMETERS = "REQUEST_PARAMETERS",

    POST_BT = "POST_BT",
    RESPONSE_OPTIONS = "RESPONSE_OPTIONS",
    EXAMPLE_REQUEST = "EXAMPLE_REQUEST",
    YES = "YES",
    NO = "NO",
    OK = "OK",
    HRN = "HRN",
    CHOOSE_LANGUEGE = "CHOOSE_LANGUEGE",
    REMEMBER_PASSWORD = "REMEMBER_PASSWORD",
    NEED_HELP = "NEED_HELP",
    DONT_HAVE_PROFILE = "DONT_HAVE_PROFILE",
    CRAETE_AN_ACCOUNT = "CRAETE_AN_ACCOUNT",
    SERVICE = "SERVICE",
    POLITICA = "POLITICA",
    KREDITPOS = "KREDITPOS",
    JUSTIN20 = "JUSTIN20",
    JUSTIN = "JUSTIN",
    TYPES_OF_SHIPMENTS = "TYPES_OF_SHIPMENTS",
    STATUS_MEAN_STEP_1 = "STATUS_MEAN_STEP_1",
    STATUS_MEAN_STEP_2 = "STATUS_MEAN_STEP_2",
    STATUS_MEAN_STEP_3 = "STATUS_MEAN_STEP_3",
    BENEFITS_INTEGRATION = "BENEFITS_INTEGRATION",
    JUSTIN_RANDOM_TITLE = "JUSTIN_RANDOM_TITLE",
    JUSTIN_RANDOM_BODY = "JUSTIN_RANDOM_BODY",
    JUSTIN_RANDOM_BODY_SEARCH = "JUSTIN_RANDOM_BODY_SEARCH",
    CREATION_STATUSES = "CREATION_STATUSES",
    SCHEDULED = "SCHEDULED",
    SCHEDULED_SUB = "SCHEDULED_SUB",
    SCHEDULED_FOR_PICKUP = "SCHEDULED_FOR_PICKUP",
    SCHEDULED_FOR_PICKUP_SUB = "SCHEDULED_FOR_PICKUP_SUB",
    CANCELED_BY_SENDER = "CANCELED_BY_SENDER",
    CANCELED_BY_SENDER_SUB = "CANCELED_BY_SENDER_SUB",
    STATUSES_OF_SHIPMENT = "STATUSES_OF_SHIPMENT",
    ACCEPTED_BY = "ACCEPTED_BY",
    ACCEPTED_BY_SUB = "ACCEPTED_BY_SUB",
    ACCEPTED_DEPARTMENT = "ACCEPTED_DEPARTMENT",
    ACCEPTED_DEPARTMENT_SUB = "ACCEPTED_DEPARTMENT_SUB",
    TRANSPORTATION_STATUSES = "TRANSPORTATION_STATUSES",
    SENT_TO_CITY = "SENT_TO_CITY",
    SENT_TO_CITY_SUB = "SENT_TO_CITY_SUB",
    SENT_TO_TRUNK = "SENT_TO_TRUNK",
    SENT_TO_TRUNK_SUB = "SENT_TO_TRUNK_SUB",
    DEPARTMENT_ACCEPTED = "DEPARTMENT_ACCEPTED",
    DEPARTMENT_ACCEPTED_SUB = "DEPARTMENT_ACCEPTED_SUB",
    ACCEPTED_BRANCH = "ACCEPTED_BRANCH",
    ACCEPTED_BRANCH_SUB = "ACCEPTED_BRANCH_SUB",
    FORWARDED_RECIPIENT = "FORWARDED_RECIPIENT",
    FORWARDED_RECIPIENT_SUB = "FORWARDED_RECIPIENT_SUB",
    SHIPMENT_REROUTING = "SHIPMENT_REROUTING",
    RETURNED_SENDER = "RETURNED_SENDER",
    RETURNED_SENDER_SUB = "RETURNED_SENDER_SUB",
    FORWARDED = "FORWARDED",
    FORWARDED_SUB = "FORWARDED_SUB",
    STATUS_OF_ISSUANCE = "STATUS_OF_ISSUANCE",
    ISSUED_TO_FINAL = "ISSUED_TO_FINAL",
    ISSUED_TO_FINAL_SUB = "ISSUED_TO_FINAL_SUB",
    RETURNED_TO_SENDER = "RETURNED_TO_SENDER",
    RETURNED_TO_SENDER_SUB = "RETURNED_TO_SENDER_SUB",
    STATUS_OF_UNSUCCESSFUL = "STATUS_OF_UNSUCCESSFUL",
    REFUSAL_TO_RECEIVE = "REFUSAL_TO_RECEIVE",
    REFUSAL_TO_RECEIVE_SUB = "REFUSAL_TO_RECEIVE_SUB",
    EXPIRED_SHELF = "EXPIRED_SHELF",
    EXPIRED_SHELF_SUB = "EXPIRED_SHELF_SUB",

    PROVIDES_POSTAL = "PROVIDES_POSTAL",
    B2C = "В2С",
    B2C_SUB = "В2С_SUB",
    C2C = "C2C",
    C2C_SUB = "C2C_SUB",
    B2B = "В2В",
    B2B_SUB = "В2В_SUB",
    C2B = "С2В",
    C2B_SUB = "С2В_SUB",
    FOR_INTEGRATION = "FOR_INTEGRATION",
    ECOMMERCE_PLATFORM = "ECOMMERCE_PLATFORM",
    ECOMMERCE_PLATFORM_STEP_1 = "ECOMMERCE_PLATFORM_STEP_1",
    ECOMMERCE_PLATFORM_STEP_2 = "ECOMMERCE_PLATFORM_STEP_2",
    INTEGRATION_OF_CRM = "INTEGRATION_OF_CRM",
    INTEGRATION_OF_CRM_STEP_1 = "INTEGRATION_OF_CRM_STEP_1",
    INTEGRATION_OF_CRM_STEP_2 = "INTEGRATION_OF_CRM_STEP_2",
    DIRECT_INTEGRATION = "DIRECT_INTEGRATION",
    DIRECT_INTEGRATION_STEP_1 = "DIRECT_INTEGRATION_STEP_1",
    APPLY_FOR_API = "APPLY_FOR_API",
    MANAGE_YOUR_SHIPMENTS = "MANAGE_YOUR_SHIPMENTS",

    BT_GO_TO = "BT_GO_TO",
    NEXT = "NEXT",
    REQUIRED_FIELDS = "REQUIRED_FIELDS",
    I_AGREE_WITH = "I_AGREE_WITH",
    TERMS_OF_AGREEMENT = "TERMS_OF_AGREEMENT",
    COOL_BUSINESS_TITLE = "COOL_BUSINESS_TITLE",
    LAST_NAME = "LAST_NAME",
    FIRS_NAME = "FIRS_NAME",
    EMAIL_ADDRESSES = "EMAIL_ADDRESSES",
    BUSINESS_SITE = "BUSINESS_SITE",
    LAST_NEWS_TITLE = "LAST_NEWS_TITLE",
    COOL_BUSINESS_BODY = "COOL_BUSINESS_BODY",
    CLIENT_BENEFITS_TITLE = "CLIENT_BENEFITS_TITLE",
    CLIENT_BENEFITS_BODY = "CLIENT_BENEFITS_BODY",
    API = "API",
    RE_PASSWORD = "RE_PASSWORD",
    USER_NAME = "USER_NAME",
    POPULAR_QUESTIONS = "POPULAR_QUESTIONS",
    ERRORS = "ERRORS",
    ANSWER = "ANSWER",
    ERROR_REQUIRED = "ERROR_REQUIRED",
    SUBMIT_APPLICATION = "SUBMIT_APPLICATION",
    STARTINTEGRATION = "STARTINTEGRATION",
    PLUGINSANDRESOURCES = "PLUGINSANDRESOURCES",
    SEND = "SEND",
    SUBSCRIPTION = "SUBSCRIPTION",
    DOWNLOAD = "DOWNLOAD",
    MAIN_PAGE = "MAIN_PAGE",
    MAIN = "MAIN",
    START_INTEGRATION = "START_INTEGRATION",
    API_PRODUCTS = "API_PRODUCTS",
    API_DOCUMENTATION = "API_DOCUMENTATION",
    SERVICE_SUPPORT = "SERVICE_SUPPORT",
    LANGUAGES = "LANGUAGES",
    UA = "UA",
    RU = "RU",
    EN = "EN",
    SING_IN_TEXT = "SING_IN_TEXT",
    AUTHORIZATION = "AUTHORIZATION",
    TERMSOFPROVISION = "TERMSOFPROVISION",
    CONTACTS = "CONTACTS",
    SUPPORT_CLIENTS = "SUPPORT_CLIENTS",
    UKR = "укр",
    RUS = "руc",
    ENGl = "анг",
    MEDIA_TYPE = "MEDIA_TYPE",
    EXAMPLE_VALUE = "EXAMPLE_VALUE",
    PERSONAL_AREA = "PERSONAL_AREA",
    ALL_NEWS = "ALL_NEWS",
    DESCRMODULE = "DESCRMODULE",
    NEWS = "NEWS",
    MODULETEXT = "MODULETEXT",
    INTEGRATION = "INTEGRATION",
    SELECT_PLATFORM = "SELECT_PLATFORM",
    POSEREDNYKY = "POSEREDNYKY",
    AUTHORIZE = "AUTHORIZE",
    FEEDBACK = "FEEDBACK",
    DROPZONETEXT = "DROPZONETEXT",
    REGISTRATION = "REGISTRATION",
    REGISTRATION_TITLE = "REGISTRATION_TITLE",
    NAME_COMPANY = "NAME_COMPANY",
    LOGIN = "LOGIN",
    E_MAIL = "E_MAIL",
    NAME = "NAME",
    PASSWORD = "PASSWORD",
    CONFIRM_PASSWORD = "CONFIRM_PASSWORD",
    PHONE = "PHONE",
    COMPANY_NAME = "COMPANY_NAME",
    ASTERISK = "ASTERISK",
    PROFILE = "PROFILE",
    EDIT = "EDIT",
    USLOVIA = "USLOVIA",
    MESSAGE = "MESSAGE",
    TITLE_MESSAGE = "TITLE_MESSAGE",
    VARIANT_CALL = "VARIANT_CALL",
    ENTER_EMAIL = "ENTER_EMAIL",
    FROM = "FROM",
    TO = "TO",
    EMAIL = "EMAIL",
    EXIT = "EXIT",
    LOGIN_ERROR = "LOGIN_ERROR",
    SERVER_ERROR = "SERVER_ERROR",
    PAGINATION_PAGE = "PAGINATION_PAGE",
    SELECT_FILE = "SELECT_FILE",
    SUBSCRIPTION_REGISTERED = "SUBSCRIPTION_REGISTERED",
    INPUT_SELECT_PLATFORM = "INPUT_SELECT_PLATFORM",
    MESSAGE_SUCCESS = "MESSAGE_SUCCES",
    ADD = "ADD",
    FAQ = "FAQ",
    HAPPY_TO_ANSWER = "HAPPY_TO_ANSWER",
    SAVE_YOUR_TIME = "SAVE_YOUR_TIME",
    GET_API_KEY_HEADER = "GET_API_KEY_HEADER",
    GET_API_KEY_SUB = "GET_API_KEY_SUB",
    HEADER_TEST_THE_API = "HEADER_TEST_THE_API",
    TEST_THE_API = "TEST_THE_API",
    KEY_ACCOUNT = "KEY_ACCOUNT",
    PASSWORD_ACCOUNT = "PASSWORD_ACCOUNT",
    API_KEY = "API_KEY",
    TEST_PATH = "TEST_PATH",
    VERSION_OF_THE_METHOD = "VERSION_OF_THE_METHOD",
    GET_PERSONAL_KEY = "GET_PERSONAL_KEY",
    RECEIVE_PERSONAL_KEY = "RECEIVE_PERSONAL_KEY",
    RECEIVE_PERSONAL_KEY_SUB_1 = "RECEIVE_PERSONAL_KEY_SUB_1",
    RECEIVE_PERSONAL_KEY_SUB_2 = "RECEIVE_PERSONAL_KEY_SUB_2",
    RECEIVE_PERSONAL_KEY_SUB_3 = "RECEIVE_PERSONAL_KEY_SUB_3",
    RECEIVE_PERSONAL_KEY_SUB_4 = "RECEIVE_PERSONAL_KEY_SUB_4",
    PERSONAL_KEY = "PERSONAL_KEY",
    CORRECTLY_KEY_HEADER = "CORRECTLY_KEY_HEADER",
    ORDER_ASK = "ORDER_ASK",
    CORRECTLY_KEY_STEP_1 = "CORRECTLY_KEY_STEP_1",
    CORRECTLY_KEY_STEP_2 = "CORRECTLY_KEY_STEP_2",
    CORRECTLY_KEY_STEP_3 = "CORRECTLY_KEY_STEP_3",
    CORRECTLY_KEY_STEP_4 = "CORRECTLY_KEY_STEP_4",
    GENERATE_A_SIGN_HEADER = "GENERATE_A_SIGN_HEADER",
    GENERATE_A_SIGN_SUB = "GENERATE_A_SIGN_SUB",
    GET_INFORMATION_HEADER = "GET_INFORMATION_HEADER",
    GET_INFORMATION_SUB = "GET_INFORMATION_SUB",
    JUSTIN_BILLING_HEADER = "JUSTIN_BILLING_HEADER",
    JUSTIN_BILLING_SUB = "JUSTIN_BILLING_SUB",
    GET_INFORMATION_ON_THE_CURRENT_HEADER = "GET_INFORMATION_ON_THE_CURRENT_HEADER",
    GET_INFORMATION_ON_THE_CURRENT_SUB = "GET_INFORMATION_ON_THE_CURRENT_SUB",
    DEPARTURE_PLANNED_HEADER = "DEPARTURE_PLANNED_HEADER",
    DEPARTURE_PLANNED_SUB = "DEPARTURE_PLANNED_SUB",
    REGISTER_OF_SHIPMENTS_HEADER = "REGISTER_OF_SHIPMENTS_HEADER",
    REGISTER_OF_SHIPMENTS_SUB = "REGISTER_OF_SHIPMENTS_SUB",
    COD_AMOUNT_HEADER = "COD_AMOUNT_HEADER",
    COD_AMOUNT_SUB = "COD_AMOUNT_SUB",
    NEED_HELP_HEADER = "NEED_HELP_HEADER",
    NEED_HELP_SUB = "NEED_HELP_SUB",
    ASK_A_QUESTION = "ASK_A_QUESTION",

    CATEGORY_YOUR_QUESTION = "CATEGORY_YOUR_QUESTION",
    CATEGORY_YOUR_QUESTION_SUB_1 = "CATEGORY_YOUR_QUESTION_SUB_1",
    CATEGORY_YOUR_QUESTION_SUB_2 = "CATEGORY_YOUR_QUESTION_SUB_2",
    CATEGORY_YOUR_QUESTION_SUB_3 = "CATEGORY_YOUR_QUESTION_SUB_3",
    CATEGORY_YOUR_QUESTION_SUB_4 = "CATEGORY_YOUR_QUESTION_SUB_4",
    CATEGORY_YOUR_QUESTION_SUB_5 = "CATEGORY_YOUR_QUESTION_SUB_5",
    REGISTRATION_CODE = "REGISTRATION_CODE",
    COMPANY_NAME_OR_KEY = "COMPANY_NAME_OR_KEY",
    YOUR_NAME = "YOUR_NAME",
    YOUR_EMAIL = "YOUR_EMAIL",
    MESSAGE_HEADER = "MESSAGE_HEADER",
    SEND_MESSAGE = "SEND_MESSAGE",
}

export enum FORM_NAMES {
    NAME = "name",
    E_MAIL = "email",
    PASSWORD = "password",
    CONFIRM_PASSWORD = "password_confirmation",
    COMPANY_NAME = "company",
    PHONE = "phone",
}

export enum TYPE_MESSAGE {
    ERROR = "error",
    WARNING = "warning",
    INFORMATION = "information",
    SUCCESS = "success",
}

export enum LANGUAGE {
    UA = "ua",
    RU = "ru",
    EN = "en",
}

export enum TYPES_REQUESTS {
    GET = "GET",
    POST = "POST",
    PUT = "PUT",
    DELETE = "DELETE",
}
