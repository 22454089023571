import React, { FC, Fragment } from "react";
import { createStyles, makeStyles, Theme, Grid } from "@material-ui/core";
import { DICTIONARY } from "../../../enums/dictionary";
import {useTranslation} from "react-i18next";

export const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		wrapper: {
			display: " flex",
			justifyContent: "center",
			padding: theme.spacing(1),
		},
		textStyle: {
			fontSize: "20px",
		},
	})
);

export const KredytniPoserednyky: FC = () => {
	const classes = useStyles();
	const { t } = useTranslation();
	return (
		<Fragment>
			<Grid container>
				<Grid item xs={12} className={classes.wrapper}>
					<p className={classes.textStyle}>
						{t(DICTIONARY.POSEREDNYKY)}
					</p>
				</Grid>
			</Grid>
		</Fragment>
	);
};
