import React, {FC, Fragment} from "react";
import SliderSlick from "react-slick";
import {
    createStyles,
    makeStyles,
    Theme,
} from "@material-ui/core";
import {Grid} from "@material-ui/core";
import {DICTIONARY} from "../../../enums/dictionary";
import {useTranslation} from "react-i18next";

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({

        root: {
            width: "50%",
            display: "flex",
            background: "white",
            borderRadius: "10px",
            color: "#104EFF",
            position: "relative",
            padding: "65px 75px 100px 30px",
            ["@media (max-width:812px)"]: {
                width: "100%",
                padding: "50px 10px 70px 10px",
                marginBottom: "25px",
            },
            "& h3": {
                fontSize: "32px",
                lineHeight: "38px",
                fontWeight: "700",
                fontFamily: "Helvetica",
                ["@media (max-width:812px)"]: {
                    fontSize: "24px",
                    lineHeight: "28px",
                },
            },
            "& p": {
                marginTop: "24px",
                lineHeight: "28px",
                "font-weight": "400",
                fontFamily: "Helvetica",
                ["@media (max-width:812px)"]: {
                    fontSize: "16px",
                    lineHeight: "18px",
                },
            },


        },
        wrapperInfo: {
            width: "100%",
            fontSize: "22px",
            lineHeight: "38px",
            "font-weight": "400",
            fontFamily: "Helvetica",
            "& .slick-dots": {
                bottom: "20px",
                textAlign: "left",
            },
            "& .slick-slider": {
                position: "inherit",
            }
        },
        news: {
            display: "flex",
            width: "100%"
        },


    })
);


export const News: FC = () => {
    const {t} = useTranslation();
    const classname = useStyles();
    const settings = {
        dots: true,
        infinite: true,
        speed: 800,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        autoplay: true,
    };
    return (
        <Fragment>
            <Grid className={classname.root} container spacing={1}>
                <Grid className={classname.wrapperInfo}>
                    <SliderSlick {...settings}>
                        <div className={classname.news}>
                            <h3>{t(DICTIONARY.LAST_NEWS_TITLE)}</h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur fermentum efficitur
                                neque
                                in condimentum. Morbi a dolor in sapien vulputate vestibulum sed vitae quam.
                            </p>
                            <p> Donec ac nunc nec massa volutpat faucibus. Aliquam sit amet nisi vehicula, feugiat
                                tellus
                                sed, posuere urna. Mauris nec nibh nibh.
                            </p>
                        </div>
                        <div>
                            <h3>{t(DICTIONARY.LAST_NEWS_TITLE)}</h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur fermentum efficitur
                                neque
                                in condimentum. Morbi a dolor in sapien vulputate vestibulum sed vitae quam.
                            </p>
                            <p> Donec ac nunc nec massa volutpat faucibus. Aliquam sit amet nisi vehicula, feugiat
                                tellus
                                sed, posuere urna. Mauris nec nibh nibh.
                            </p>
                        </div>
                        <div>
                            <h3>{t(DICTIONARY.LAST_NEWS_TITLE)}</h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur fermentum efficitur
                                neque
                                in condimentum. Morbi a dolor in sapien vulputate vestibulum sed vitae quam.
                            </p>
                            <p> Donec ac nunc nec massa volutpat faucibus. Aliquam sit amet nisi vehicula, feugiat
                                tellus
                                sed, posuere urna. Mauris nec nibh nibh.
                            </p>
                        </div>
                    </SliderSlick>
                </Grid>
            </Grid>
        </Fragment>
    );
};
