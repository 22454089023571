import React, {Fragment, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {
    createStyles,
} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {IErrors, IField, IMethodsAPI} from "../../../interfaces/interfaces";
import axios from "axios";
import {HashLink} from 'react-router-hash-link';
import {DICTIONARY} from "../../../enums/dictionary";
import {rootUrl} from "../../../shared/get-root-url";

export const useStyles = makeStyles(() =>
    createStyles({
        root: {
            display: "flex",
            flexDirection: "column",
            fontSize: "24px",
            lineHeight: "28px",
            "font-weight": "400",

        },
        listAPI: {
            display: "flex",
            flexDirection: "column",
            width: "20%",
            background: "white",
            padding: "30px 50px 0 30px",
            // width: "30%",
            "& p": {
                fontSize: "24px",
                lineHeight: "28px",
                "font-weight": "700",
                textDecoration: "underline",
                margin: "20px 0 0 20px",
            },
            // "& nav": {
            //     position: "fixed",
            // }
        },
        boxSelect: {
            background: "white",
            padding: "20px",
            marginBottom: "10px",
            borderRadius: "7px",
            "& select": {
                width: "100%",
                height: "30px",
            }
        },
        boxOutput: {
            background: "white",
            padding: "20px",
            marginBottom: "10px",
            borderRadius: "7px",
            "& label": {
                fontSize: "32px",
                lineHeight: "36px",
                color: "#104eff",
                marginRight: "10px",
            }
        },
        boxData: {
            display: "flex",
        },
        boxInfo: {
            background: "white",
            padding: "20px",
            borderRadius: "7px",
            marginLeft: "20px",
            width: "100%",
            overflow: "auto",
            "& th, td": {
                border: ".5px solid black",
            },
            "& td": {
                padding: "20px",
            },
            "& table": {
                borderSpacing: "0",
                marginBottom: "50px",
            }
        },
        boxTable: {
            marginTop: "50px",
            "& h3": {
                margin: "30px 0",
                color: "#104eff",
            }
        },
        boxOutputInfo: {
            marginBottom: "25px",
            borderRadius: "7px",
            "& label": {
                fontSize: "32px",
                lineHeight: "36px",
                color: "#104eff",
                marginRight: "10px",
            }
        },
        red: {
            color: "red",
        },
        tableError: {
            width: "100%",
            "& th": {
                background: "#DEEAF6",
                // background:"#83A3EC",
                // background:"#C4C4C4;",
            }

        },
        tableView: {
            width: "100%",
            "& th, td": {
                border: "1px solid white",
            },
            "& th": {
                background: "#83A3EC",
                padding: "20px 30px",
                margin: "2px",
                // background:"#C4C4C4;",
            },
            "& td": {
                background: "#DEEAF6;",
            }
        },
        blueColor: {
            color: "#104EFF",
        },
        btBlue: {
            background: "#104EFF",
            color: "white",
            fontSize: "18px",
            lineHeight: "21px",
            "font-weight": "700",
            padding: "10px 20px",
            borderRadius: "30px",
            borderColor: "#104EFF",
            width: "100px",
            cursor: "pointer",
            marginRight: "15px",
            textTransform: 'uppercase',
        },
        btWhite: {
            background: "white",
            color: "#104EFF",
            fontSize: "18px",
            lineHeight: "21px",
            "font-weight": "700",
            padding: "10px 20px",
            borderRadius: "30px",
            marginRight: "15px",
            width: "100px",
            borderColor: "#104EFF",
            cursor: "pointer",
        },
        testJSON: {
            background: "darkgrey",
        },
        preJSON: {
            marginTop: "20px",
            fontSize: "14px",
            lineHeight: "16px",
            color: "blue",
        },
        key: {
            color: "blue"
        },
        number: {
            color: "green",
        },
        string: {
            color: "grey"
        },
        backPre: {
            background: "black",
            fontSize: "18px",
        },
        subTitle: {
            "font-weight": "700",
            lineHeight: "21px",
        },
        subTitleTable: {
            "font-weight": "700",
            lineHeight: "21px",
            margin: "15px 0"
        },
        boxAnswer: {
            marginTop: "40px",
        },

    }));

const getTable = (method: IField) => {
    return <table>
        <tbody>
        <tr>
            <th>Name</th>
            <th>Type</th>
            <th>Required</th>
            <th>Requiredif</th>
            <th>Description</th>
            <th>Date publicated</th>
        </tr>
        <tr>
            <td>{method.name}</td>
            <td>{method.type}</td>
            <td>{method.required ? "+" : "-"}</td>
            <td>{method.requiredif}</td>
            <td>{method.description}</td>
            <td>{method.publicated_at}</td>
        </tr>
        </tbody>
    </table>
};


const defaultDateField = [{
    name: "",
    description: "",
    type: "",
    required: false,
    requiredif: "",
    publicated_at: "",
    created_at: "",
    example: "",

}];
const defaultDateMethod = {
    id: "",
    name: "",
    method: "",
    description: "",
    publicated_at: "",
    created_at: "",
    url: "",
    http: "",
    links: {
        main: "",
    },
    fields_request: defaultDateField,
    fields_response: defaultDateField,
    examples: [
        {
            description: "",
            id: 0,
            publish_at: "",
            request: "",
            response: "",
        }
    ],
    errors: [{
        code: "",
        custom_name: "",
        description: "",
        id: 0,
        name: "",
        publish_at: "",
    }],
};
const defaultDateList = {
    id: "",
    name: "",
    method: "",
    url: "",
    description: "",
    publicated_at: "",
    created_at: "",
    meta: {
        url: "",
    }
};

const getLng = () => {
    switch (localStorage.getItem("i18nextLng")) {
        case "en":
            return "en";
        case "ru" :
            return "ru";
        case "ua" :
            return "uk";
    }
};
export const ApiPage = () => {
    const classname = useStyles();
    const [methods, setMethods] = useState<IMethodsAPI[]>([defaultDateMethod]);
    const [itemSelect, setItemSelect] = useState<number>(0);

    const getDescriptionError = (description: string) => {
        return <td dangerouslySetInnerHTML={{__html: description}}/>
    };

    const getTableError = (method: IErrors[]) => {
        return <table className={classname.tableError}>
            <tbody>
            <tr>
                <th>Код</th>
                <th>Атрибут</th>
                <th>Ошибка</th>
                <th>Описание</th>
            </tr>
            {method.map((item, index) => {
                return <tr key={item.name + index}>
                    <td>{item.code}</td>
                    <td>{item.name}</td>
                    <td>{item.custom_name}</td>
                    {getDescriptionError(item.description)}
                </tr>
            })}
            </tbody>
        </table>
    };
    const getTableRequest = (method: IField[]) => {
        return <table className={classname.tableView}>
            <tbody>
            <tr>
                <th>Поле</th>
                <th>Тип</th>
                <th>Обов'язковість</th>
                <th>Опис</th>
                <th>Приклад</th>
            </tr>
            {method.map((item, index) => {
                return <tr key={item.name + index}>
                    <td>{item.name}</td>
                    <td>{item.type}</td>
                    <td>{item.required ? "Так" : "Нi"}</td>
                    <td>{item.description}</td>
                    <td>{item.example}</td>
                </tr>
            })}
            </tbody>
        </table>
    };
    const getTableResponse = (method: IField[]) => {
        return <table className={classname.tableView}>
            <tbody>
            <tr>
                <th>Поле</th>
                <th>Тип</th>
                <th>Опис</th>
                <th>Приклад</th>
            </tr>
            {method.map((item, index) => {
                return <tr key={item.name + index}>
                    <td>{item.name}</td>
                    <td>{item.type}</td>
                    <td>{item.description}</td>
                    <td>{item.example}</td>
                </tr>
            })}
            </tbody>
        </table>
    };
    const {t} = useTranslation();

    const getListApi = async () => {
        try {
            return await axios.get(
                `${rootUrl}api/doc/app?locale=${getLng()}`,
            )
        } catch (error) {

        }
    };
    const getMethodListApi = async (url: string) => {
        let listMethods: string[];
        try {
            await axios.get(
                // `${rootUrl}api/doc_api/${id}`,
                `${url}?locale=${getLng()}`,
            ).then(({data: {data}}) => {
                return setMethods(data);
            })
        } catch (error) {

        }
    };

    useEffect(() => {
        getListApi().then(({data}: any) => {
            return data.data.forEach((item: any) => {
                if (item.main) {
                    return getMethodListApi(item.links.methods)
                }
            });
        });
    }, [localStorage.getItem("i18nextLng")]);

    const getListApiItem = async (e: any) => {
        setItemSelect(e.target.selectedIndex);
        try {
            const {data} = await axios.get(
                `${e.target.value}`,
            );
            return JSON.parse(data);
        } catch (error) {

        }
    };

    function syntaxHighlight(json: any) {
        if (typeof json != 'string') {
            json = JSON.stringify(json, undefined, 2);
        }
        json = json.replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;');
        return json.replace(/("(\\u[a-zA-Z0-9]{4}|\\[^u]|[^\\"])*"(\s*:)?|\b(true|false|null)\b|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?)/g, function (match: any) {
            let cls: any = classname.number;

            if (/^"/.test(match)) {
                if (/:$/.test(match)) {
                    cls = classname.key;
                } else {
                    cls = classname.string;
                }
            } else if (/true|false/.test(match)) {
                cls = 'boolean';
            } else if (/null/.test(match)) {
                cls = 'null';
            }
            return '<span class="' + cls + '">' + match + '</span>';
        });
    }

    function getJson(json: any, index: number) {
        const test = syntaxHighlight(json);
        return <pre className={classname.backPre} id={`json-${index}`} dangerouslySetInnerHTML={{__html: test}}/>
    }


    function getDescription(str: any) {
        return <div dangerouslySetInnerHTML={{__html: str}}/>

    }

    function getUrl(url: string) {
        return url.substr(0, 1) === "/" ? `https://api.justin.ua${url}` : `https://api.justin.ua/${url}`
    }

    return (<Fragment>
            <div className={classname.root}>
                <div className={classname.boxData}>
                    <div className={classname.listAPI}>
                        <nav>
                            {/*<h3>{listApi[itemSelect].name}</h3>*/}
                            {methods.map((item, index) => {
                                return <div key={`${item.id}_`}>
                                    <HashLink
                                        to={`/doc#${item.id}_`}
                                        scroll={(el) => el.scrollIntoView({
                                            behavior: 'smooth',
                                            block: "center",
                                            inline: "nearest"
                                        })}
                                    >
                                        <p
                                            className={window.location.hash === `#${item.id}_` ? classname.red : ""}>{
                                            item.name}
                                        </p>
                                    </HashLink>
                                </div>
                            })}
                        </nav>
                    </div>
                    <div className={classname.boxInfo}>
                        {!methods
                            ? <div></div>
                            : <div>
                                {methods.map((item, index) => {
                                    return <div id={`${item.id}`} key={`method_${item.name}`}
                                                className={classname.boxTable}>
                                        <h3>{item.name}</h3>
                                        {getDescription(item.description)}
                                        {item.errors.length > 0 &&
                                        <p className={classname.subTitleTable}>{t(DICTIONARY.ERRORS)}</p>}
                                        {item.errors.length > 0 && getTableError(item.errors)}
                                        <p className={classname.subTitleTable}>    {t(DICTIONARY.RESPONSE_OPTIONS)}</p>
                                        <div className={classname.boxOutputInfo} id={`${item.id}_`}>
                                            <button className={classname.btBlue}>{item.http}</button>
                                            <span
                                                className={classname.subTitle}>{getUrl(item.url)}
                                            </span>
                                        </div>

                                        {getTableRequest(item.fields_request)}

                                        <p className={classname.subTitleTable}>{t(DICTIONARY.RESPONSE_OPTIONS)}</p>

                                        {getTableResponse(item.fields_response)}

                                        <div className={classname.boxOutputInfo}>
                                            <p className={classname.subTitleTable}>{t(DICTIONARY.EXAMPLE_REQUEST)}</p>
                                            <button className={classname.btWhite}>JSON</button>
                                        </div>
                                        <div>
                                            {item.examples[0].request && getJson(item.examples[0].request, index)}
                                            <div className={classname.boxAnswer}>
                                                <span className={classname.subTitleTable}>{t(DICTIONARY.ANSWER)}</span>
                                                <pre className={classname.preJSON}>
                                                <code>
                                                    {item.examples[0].request && item.examples[0].response}
                                               </code>
                                           </pre>
                                            </div>
                                        </div>
                                    </div>
                                })}
                            </div>}

                    </div>
                </div>
            </div>
        </Fragment>
    );
};