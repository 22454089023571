import React, {Component, Fragment} from "react";
import {Dispatch} from "redux";
import {connect} from 'react-redux'
import {
    createStyles,
    withStyles,
} from "@material-ui/core";
import {Grid} from "@material-ui/core";
import CloseImg from "../../../images/close.svg";
import IntersectImg from "../../../images/intersect.svg";
import PhoneImg from "../../../images/phone.svg";
import EmailImg from "../../../images/email.svg";
import SupportImg from "../../../images/support.svg";
import {DICTIONARY} from "../../../enums/dictionary";
import {IInitialStateStore} from "../../../redux/types/type";
import {HANDLE_MODAL_CONTACTS} from "../../../redux/actions/actionTypes";
import {withTranslation, WithTranslation} from 'react-i18next';

export const useStyles = () =>
    createStyles({
            modal: {
                position: "fixed",
                left: 0,
                top: 0,
                right: 0,
                bottom: 0,
                width: "100%",
                backgroundColor: "rgba(0,0,0,0.5)",
                "z-index": "9999",
                display: "flex",
                "justify-content": "center",
                "align-items": "center",
                "& h3":
                    {
                        fontSize: "36px",
                        lineHeight:
                            "41px",
                        fontWeight:
                            "700",
                        fontFamily:
                            "Helvetica",
                    }
                ,
                "& p":
                    {
                        marginTop: "25px",
                        lineHeight:
                            "28px",
                    }
            },
            container: {
                position: "relative",
                // height:"max-content",
                background: "white",
                width: "650px",
                height: "350px",
                color: "#104EFF",
                borderRadius: "20px",
            }
            ,
            intersect: {
                position: "absolute",
                bottom:
                    0,
                right:
                    0,
            }
            ,
            body: {
                padding: "60px 0 0 30px",
                fontSize:
                    "30px",
                lineHeight:
                    "37px",
                "font-weight":
                    "700",
                fontFamily:
                    "Helvetica",
                "& <:nth-chlid(1)": {
                    marginBottom: "50px"
                },
                "& div": {
                    display: "flex",
                    "& img": {
                        marginRight: "20px",
                    }
                }
            }
            ,
            support: {
                marginBottom: "30px",
            },
            header: {
                display: "flex",
                "align-items":
                    "center",
                "justify-content":
                    "space-between",
                fontSize:
                    "36px",
                lineHeight:
                    "41px",
                "font-weight":
                    "700",
                fontFamily:
                    "Helvetica",
                padding:
                    "20px 40px 0 70px",
                "& img":
                    {
                        cursor: "pointer",
                    }
            }
            ,
            wrapperInfo: {
                width: "50%",
                fontSize:
                    "24px",
                lineHeight:
                    "41px",
                "font-weight":
                    "400",
                fontFamily:
                    "Helvetica",
            },
            output: {
                color: "black",
                fontSize: "24px",
            }
        }
    )
;

export interface ISub {
    "imgSub": string,
    "text": string,
    "linkImg"?: string,
    "body"?: string,
}

interface IProps extends WithTranslation {
    t: any
    classes?: any
    handleModal: any
    isModalContacts: boolean
}

interface IState {
}


class ModalComponent extends Component<IProps, IState> {

    render() {
        const {t, classes, handleModal, isModalContacts} = this.props;
        const arraySubSupport: ISub[] = [
            {
                imgSub: SupportImg,
                text: t(DICTIONARY.SUPPORT_CLIENTS),
                body: ""
            },
            {
                imgSub: PhoneImg,
                text: t(DICTIONARY.PHONE),
                body: "+38(044)354-62-66"
            },
            {
                imgSub: EmailImg,
                text: t(DICTIONARY.EMAIL),
                body: "APIsupport@justin.ua"
            }
        ];
        return (
            <Fragment>
                {isModalContacts &&
                <Grid className={classes.modal} container spacing={1}>
                    {/*test {this.props.isModalContacts}*/}
                    <div className={classes.container}>
                        <div className={classes.header}>
                            {t(DICTIONARY.CONTACTS)}
                            <img onClick={() => handleModal()} src={CloseImg} alt={CloseImg.toString()}/>
                        </div>
                        <div className={classes.body}>
                            {arraySubSupport.map(({imgSub, text, linkImg, body}, index) => {
                                return <div className={!linkImg ? classes.support : ""} key={`${text}${index}`}>
                                    <img src={imgSub} alt={imgSub.toString()}/>
                                    {imgSub === SupportImg ? text
                                        : <label>{`${text}: `}<label className={classes.output}>{body}</label></label>}
                                </div>
                            })}
                        </div>
                        <img className={classes.intersect} src={IntersectImg} alt={IntersectImg.toString()}/>
                    </div>
                </Grid>}
            </Fragment>
        )
    }
}


const mapStateToProps = (state: IInitialStateStore) => ({isModalContacts: state.isModalContacts});

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        handleModal: () => dispatch({type: HANDLE_MODAL_CONTACTS})
    }
};

const ModalConnect = connect(mapStateToProps, mapDispatchToProps)(ModalComponent);
const ModalStyles = withStyles(useStyles)(ModalConnect);
export const ModalContacts = withTranslation()(ModalStyles);
// export const ModalContacts =  compose(withTranslation(),connect(mapStateToProps, mapDispatchToProps),withStyles(useStyles))(ModalComponent);
