import React, {useEffect, useState} from 'react';
import NumberFormat from 'react-number-format';
import TextField from '@material-ui/core/TextField';
import {FieldAttributes, useField} from "formik";
import {useStylesTextFieldPlaceHolder} from "./cast.textfield.password";

interface NumberFormatCustomProps {
    inputRef: (instance: NumberFormat | null) => void;
    onChange: (event: { target: { name: string; value: string } }) => void;
    onFocus: () => void;
    name: string;
}

function NumberFormatCustom(props: NumberFormatCustomProps) {
    const { inputRef,
        onChange,
        // onFocus,
        ...other } = props;
    const [foc, setFoc] = useState<boolean>(false);

    const hendleFoc = () => {
        setFoc(true)
    }
    useEffect(()=>{
    }, [foc])


    return (
        <NumberFormat
            {...other}
            getInputRef={inputRef}
            onValueChange={(values) => {
                onChange({
                    target: {
                        name: props.name,
                        value: values.value,
                    },
                });
            }}
            onFocus={hendleFoc}
            allowEmptyFormatting={true}
            format={"+38(###) ###-##-##"}
            // placeholderChar={'\u2000'}
        />
    );
}


type MyPhoneFieldProps = {label?: string, variant: any, size: any} & FieldAttributes<{}>;

export const CastPhoneField: React.FC<MyPhoneFieldProps> =
    ({
         label,
         variant,
         size,
         placeholder,
         required,
         ...props
     }) => {
        const [field, meta] = useField<{}>(props);
        let errorText = meta.error && meta.touched ? meta.error : '';
        const classes = useStylesTextFieldPlaceHolder();

        return <TextField className={classes.root}
                          {...field}
                          placeholder={placeholder}
                          helperText={errorText}
                          label={label}
                          variant={variant}
                          size={size}
                          error={!!errorText}
                          required={required}
                          InputProps={{
                              inputComponent: NumberFormatCustom as any,
                          }}

        />;
    };
