import React, { FC, Fragment, useContext, useEffect, useState } from "react";
import {
	createStyles,
	makeStyles,
	Theme,
	Grid,
	Typography,
} from "@material-ui/core";
import { Subscription } from "../main.page/subscription/subscription";
import { DICTIONARY, TYPE_MESSAGE } from "../../../enums/dictionary";
import { ContextStore } from "../../../store";
import axios from "axios";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { ISnackBarData } from "../../../interfaces/interfaces";
import { ACTION_TYPES } from "../../../reduser";

export const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			display: "flex",
			justifyContent: "center",
			height: "100%",
		},
		wrapper: {},
		imgContent: {
			display: "flex",
			justifyContent: "center",
		},
		imgStyle: { width: "100%", borderRadius: "15px" },
		titleStyle: {
			fontSize: "25px",
			paddingBottom: theme.spacing(2),
			[theme.breakpoints.down("xs")]: {
				fontSize: "22px",
			},
		},
		dateStyle: {
			fontSize: "20px",
			color: "#104eff",
			padding: theme.spacing(2),
			[theme.breakpoints.down("xs")]: {
				fontSize: "16px",
			},
		},
		textStyle: {
			fontSize: "18px",
			[theme.breakpoints.down("xs")]: {
				fontSize: "16px",
			},
		},
		subscriptionStyle: {
			paddingTop: theme.spacing(3),
		},
	})
);

export type ISingleNews = {
	id: number | null;
	title: string;
	image: string;
	created_at: string;
	content: string;
};

export const NewsDetails: FC = () => {
	const classes = useStyles();
	const { t } = useTranslation();
	const { state, dispatch } = useContext(ContextStore);
	const [language, setLanguage] = useState<string | null>("");

	const [singleNewsDetails, setSingleNews] = useState<ISingleNews>({
		id: null,
		title: "",
		image: "",
		created_at: "",
		content: "",
	});

	useEffect(() => {
		if (state.newsId !== null) {
			const newsId: any = state.newsId;
			localStorage.setItem("news_id", newsId);
		}
	}, [state.newsId]);

	useEffect(() => {
		if (state.language) {
			setLanguage(state.language);
		} else {
			const lang = localStorage.getItem("i18nextLng");
			setLanguage(lang);
		}
	}, [state.language]);

	useEffect(() => {
		const newsId = localStorage.getItem("news_id");
		let getSingleNews = async () => {
			try {
				const response = await axios.get(
					`${process.env.REACT_APP_API_END_POINT}api/open/news/${newsId}`,
					{
						params: {
							lang: language,
						},
						headers: {
							"content-type": "application/json",
							Accept: "application/json",
						},
					}
				);
				if (
					response.data.data !== null &&
					response.data.data !== undefined
				) {
					const singleNews = response.data.data;
					setSingleNews(singleNews);
				}
			} catch (error) {
				// console.log("error = ", error);
				// if (error.response.status === 500) {
				// 	const snackData: ISnackBarData = {
				// 		code: "500",
				// 		message: t(DICTIONARY.SERVER_ERROR),
				// 		typeMessage: t(TYPE_MESSAGE.ERROR),
				// 	};
				// 	dispatch({
				// 		type: ACTION_TYPES.SET_SNACK_BAR_DATA,
				// 		payload: snackData,
				// 	});
				// }
			}
		};
		getSingleNews();
	}, [language]);

	return (
		<Fragment>
			<Grid container spacing={0} className={classes.root}>
				<Grid item xs={12} className={classes.wrapper}>
					<Grid item xs={12} className={classes.imgContent}>
						<img
							alt="kartinka"
							src={singleNewsDetails.image}
							className={classes.imgStyle}
						></img>
					</Grid>
					<Typography className={classes.dateStyle}>
						{t(DICTIONARY.FROM)}
						{moment(singleNewsDetails.created_at).format(
							"DD.MM.YYYY"
						)}
						{t(DICTIONARY.TO)}
						{moment(singleNewsDetails.created_at).format(
							"DD.MM.YYYY"
						)}
					</Typography>
					<Typography variant="h1" className={classes.titleStyle}>
						{singleNewsDetails.title}
					</Typography>
					<Typography className={classes.textStyle}>
						{singleNewsDetails.content}
					</Typography>
				</Grid>

				<Grid item xs={12} className={classes.subscriptionStyle}>
					<Subscription />
				</Grid>
			</Grid>
		</Fragment>
	);
};
