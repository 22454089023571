import * as Yup from "yup";

const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

export const feedbackValidationSchema = Yup.object().shape({
	userName: Yup.string()
		.required("Обов'язкове для заповнення")
		.typeError("Введіть коректне ім'я користувача")
		.min(3, "Закоротке ім'я"),
	email: Yup.string()
		.required("Обов'язкове для заповнення")
		.typeError("Введіть коректний Email користувача")
		.email("Веддіть коректний Email")
		.min(5, "Закороткий Email"),
	phone: Yup.string()
		.matches(phoneRegExp, "Введіть телефон у форматі 096...")
		.required("Обов'язкове для заповнення")
		.typeError("Введіть телефон у форматі 096...")
		.min(10, "Закороткий номер телефону"),
	variantCall: Yup.string().required("Обов'язкове для заповнення"),
	titleMessage: Yup.string().required("Обов'язкове для заповнення"),
	message: Yup.string()
		.required("Обов'язкове для заповнення")
		.min(10, "Закоротке повідомлення")
		.max(300),
	success: Yup.boolean(),
});
