import React, { FC, Fragment, useContext, useEffect, useState } from "react";
import { Form, Formik } from "formik";
import { Button, createStyles, Paper, Theme } from "@material-ui/core";
import {
	DICTIONARY,
	FORM_NAMES,
	TYPE_MESSAGE,
} from "../../../enums/dictionary";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { validationSchemaRegistration } from "../validation/validate";
import { CastTextField } from "../castom.fields/cast.text.field";
import { CastTextFieldPass } from "../castom.fields/cast.textfield.password";
import { ContextStore } from "../../../store";
import { IRegistration, ISnackBarData } from "../../../interfaces/interfaces";
import { rootUrl } from "../../../shared/get-root-url";
import axios from "axios";
import { ACTION_TYPES } from "../../../reduser";
import { useTranslation } from "react-i18next";
import { CastPhoneField } from "../castom.fields/custom.phone.field";

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			"& .MuiTextField-root": {
				boxSizing: "border-box",
				margin: theme.spacing(1),
			},
		},
		conteiner: {
			background: "#c8f7ff",
			boxSizing: "border-box",
			margin: theme.spacing(1),
		},
		formWrap: {
			margin: theme.spacing(1),
			display: "flex",
			flexDirection: "column",
			alignItems: "center",
			justifyContent: "center",
			paddingTop: theme.spacing(2),
			paddingBottom: theme.spacing(2),
		},
		formItem: {
			margin: theme.spacing(1),
			display: "flex",
			maxWidth: "202px",
			width: "100%",
			justifyContent: "center",
			alignItems: "center",
		},
		label: {
			boxSizing: "border-box",
			marginRight: theme.spacing(1),
		},
	})
);
export interface FormFieldProps {
	label: string;
	name: string;
	value: string;
}

export const Registration: FC = () => {
	const classes = useStyles();
	const { dispatch } = useContext(ContextStore);
	const { t } = useTranslation();
	const [fields] = useState<Array<FormFieldProps>>([
		{
			label: DICTIONARY.NAME,
			name: FORM_NAMES.NAME,
			value: DICTIONARY.NAME,
		},
		{
			label: DICTIONARY.E_MAIL,
			name: FORM_NAMES.E_MAIL,
			value: DICTIONARY.E_MAIL,
		},
		{
			label: DICTIONARY.PASSWORD,
			name: FORM_NAMES.PASSWORD,
			value: DICTIONARY.PASSWORD,
		},
		{
			label: DICTIONARY.CONFIRM_PASSWORD,
			name: FORM_NAMES.CONFIRM_PASSWORD,
			value: DICTIONARY.CONFIRM_PASSWORD,
		},
		{
			label: DICTIONARY.PHONE,
			name: FORM_NAMES.PHONE,
			value: DICTIONARY.PHONE,
		},
		{
			label: DICTIONARY.COMPANY_NAME,
			name: FORM_NAMES.COMPANY_NAME,
			value: DICTIONARY.COMPANY_NAME,
		},
	]);
	const [initVal] = useState<object>(getInitialValues());

	// i18next.on('languageChanged', function(e: Event) {
	//     // E.g. set the moment locale with the current language
	//     // moment.locale(lng);
	//     console.log("перерендер")
	//     e.preventDefault()
	//     // preventDefault();
	//     // then re-render your app
	//
	//
	// });

	function getInitialValues() {
		return Object.fromEntries(
			fields.map(({ name }) => {
				if (name === FORM_NAMES.PHONE) {
					return [name, ""];
				} else {
					return [name, ""];
				}
			})
		);
	}

	useEffect(() => {
		// console.log(initVal);
	}, [initVal]);

	function renderForm(fields: Array<FormFieldProps>) {
		return (
			<Fragment>
				{fields.map((field, index) => {
					if (
						field.name === FORM_NAMES.PASSWORD ||
						field.name === FORM_NAMES.CONFIRM_PASSWORD
					) {
						return (
							<div className={classes.formItem} key={index}>
								<CastTextFieldPass
									label={t(field.label)}
									name={field.name}
									value={t(field.value)}
									type="input"
									variant="outlined"
									size="small"
									InputProps=""
								/>
							</div>
						);
					} else if (field.name === FORM_NAMES.PHONE) {
						return (
							<div className={classes.formItem} key={index}>
								<CastPhoneField
									// label={t(field.label)}
									name={field.name}
									value={t(field.value)}
									type="input"
									variant="outlined"
									size="small"
								/>
							</div>
						);
					} else {
						return (
							<div className={classes.formItem} key={index}>
								<CastTextField
									label={t(field.label)}
									name={field.name}
									value={t(field.value)}
									type="input"
									variant="outlined"
									size="small"
								/>
							</div>
						);
					}
				})}
			</Fragment>
		);
	}

	const asyncSendRegistrationData = async (url: string, data: any) => {
		const body: IRegistration = {
			[FORM_NAMES.NAME]: data["name"],
			[FORM_NAMES.E_MAIL]: data["email"],
			[FORM_NAMES.PASSWORD]: data["password"],
			[FORM_NAMES.CONFIRM_PASSWORD]: data["password_confirmation"],
			[FORM_NAMES.COMPANY_NAME]: data["company"],
			[FORM_NAMES.PHONE]: "+38" + data["phone"],
		};
		// console.log("body", body);
		try {
			console.log(url);
			const { data } = await axios.post(
				`${process.env.REACT_APP_API_END_POINT}api/auth/register`,
				body
			);
			return data;
		} catch (error) {
			// console.log("error response", error.response.status);
			// console.log("error.response.data", error.response.data);
			// if (error.response.status === 422) {
			// 	if (error.response.data.errors["email"]) {
			// 		const snackData: ISnackBarData = {
			// 			code: error.response.status.toString(),
			// 			message: error.response.data.errors["email"],
			// 			typeMessage: TYPE_MESSAGE.ERROR,
			// 		};
			// 		dispatch({
			// 			type: ACTION_TYPES.SET_SNACK_BAR_DATA,
			// 			payload: snackData,
			// 		});
			// 	}
			// }
		}
	};

	return (
		<Paper className={classes.conteiner}>
			<Formik
				validateOnChange={true}
				// validateOnMount={true}
				validationSchema={validationSchemaRegistration}
				initialValues={initVal}
				onSubmit={(data, { setSubmitting, resetForm }) => {
					setSubmitting(true);
					// asinc call
					const url = `${rootUrl}api/auth/register`;
					asyncSendRegistrationData(url, data)
						.then(
							(resData) => {
								console.log(
									"asyncSendRegistrationData",
									resData
								);
								console.log("res", resData);
								if (resData) {
									const snackData: ISnackBarData = {
										code: resData["code"],
										message: resData["message"],
										typeMessage: TYPE_MESSAGE.SUCCESS,
									};
									dispatch({
										type: ACTION_TYPES.SET_SNACK_BAR_DATA,
										payload: snackData,
									});
								}
							},
							(err) => {
								console.log("reject", err);
							}
						)
						.then(() => {
							resetForm();
						});

					// console.log(data);
					// ToDo: data from back response
					// const snackData: ISnackBarData = {
					//     			code: "200",
					//     			message: "Успішна реєстрація",
					//     			typeMessage: TYPE_MESSAGE.SUCCESS,
					//     		};
				}}
			>
				{({ values, errors, isSubmitting }) => (
					<Form className={classes.formWrap}>
						{renderForm(fields)}
						<div className={classes.formItem}>
							<Button
								variant="outlined"
								color="primary"
								size="small"
								type="submit"
								disabled={isSubmitting}
							>
								{t(DICTIONARY.REGISTRATION)}
							</Button>
						</div>
						<pre>{JSON.stringify(values, null, 2)}</pre>
						<pre>{JSON.stringify(errors, null, 2)}</pre>
					</Form>
				)}
			</Formik>
		</Paper>
	);
};
