import React, { FC, Fragment } from "react";
import { createStyles, makeStyles, Theme, Grid } from "@material-ui/core";
import { politicaText } from "./politica.array.text";

export const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		wrapper: {},
		titleStyle: {
			padding: theme.spacing(2),
		},
		messageStyle: {},
	})
);

export const PoliticaKonfidentsialnosti: FC = () => {
	const classes = useStyles();
	return (
		<Fragment>
			<Grid container>
				<Grid item xs={12} className={classes.wrapper}>
					{politicaText && politicaText.length > 0 ? (
						politicaText.map(({ title, message }, i) => {
							return (
								<div key={i}>
									<h1 className={classes.titleStyle}>
										{title}
									</h1>
									<p className={classes.messageStyle}>
										{message}
									</p>
								</div>
							);
						})
					) : (
						<div>No text</div>
					)}
				</Grid>
			</Grid>
		</Fragment>
	);
};
