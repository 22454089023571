import React, {FC, Fragment} from "react";
import {
    createStyles,
    makeStyles,
    Theme,
    Button,
} from "@material-ui/core";
import {Grid} from "@material-ui/core";
import {DICTIONARY} from "../../../enums/dictionary";
import PluginsImg from "../../../images/testPlugins.svg";
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: "100%",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            ["@media (max-width:812px)"]: {
                flexDirection: "column",
                "& :nth-child(1)": {
                    marginTop: "0px"
                },
            },
        },
        wrapperInfo: {
            width: "30%",
            marginTop: "50px",
            background: "white",
            fontSize: "24px",
            lineHeight: "41px",
            "font-weight": "400",
            fontFamily: "Helvetica",
            borderRadius: "10px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            paddingBottom:"50px",
            ["@media (max-width:812px)"]: {
                width: "100%",
                display: "flex",
                flexDirection: "column",
                "align-items": "center",
                padding: "12px 40px",
            },
            "& h3": {
                fontSize: "36px",
                lineHeight: "41px",
                fontWeight: "700",
                fontFamily: "Helvetica",
                margin: "30px 0 40px 0"
            },
            "& p": {
                marginTop: "24px",
                lineHeight: "28px",
                "font-weight": "400",
                fontFamily: "Helvetica",
            },
            "& a": {
                textDecoration: "none",
            },
        },
        news: {
            display: "flex",
            width: "100%"
        },
        bt: {
            fontSize: "24px",
            lineHeight: "30px",
            "font-weight": "400",
            fontFamily: "Helvetica",
            padding: "15px 25%;",
            textTransform: "capitalize",
            ["@media (max-width:1100px)"]: {
                padding: "15px 15%;",
            },
            ["@media (max-width:500px)"]: {
                padding: "15px 25%;",
            },
        },
        mr_0: {
            ["@media (max-width:812px)"]: {
                margin: "0",
            },
        },
        wrapperLink: {
            width: "100%",
            textAlign: "center",
        }

    })
);


export const PluginsResources: FC = () => {
    const {t} = useTranslation();
    const classname = useStyles();
    const arrayNew = [
        {
            header: "Модуль 1С",
            plugin_img: PluginsImg,
            linkID: "1"
        },
        {
            header: "Модуль 2С",
            plugin_img: PluginsImg,
            linkID: "2"
        },
        {
            header: "Модуль 3С",
            plugin_img: PluginsImg,
            linkID: "3"
        }
    ];

    return (
        <Fragment>
            <Grid className={classname.root} container spacing={1}>
                {arrayNew.map((item, index) => {
                    return <Grid key={item.header + index} className={classname.wrapperInfo}>
                        <img src={item.plugin_img} alt={item.plugin_img.toString()}/>
                        <h3>{item.header}</h3>
                        <div className={classname.wrapperLink}>
                            <Link to={`/plugins_resources/${item.linkID}`}>
                                <Button className={classname.bt} variant="contained"
                                        color="primary">
                                    Продовжити
                                </Button>
                            </Link>
                        </div>
                    </Grid>
                })}
            </Grid>
        </Fragment>
    );
};
