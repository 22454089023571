import React, {Component, createRef, Fragment} from "react";
import {Dispatch} from "redux";
import {connect} from 'react-redux'
import {
    createStyles,
    withStyles,
} from "@material-ui/core";
import {Grid} from "@material-ui/core";
import CloseImg from "../../images/close.svg";
import IntersectImg from "../../images/intersect.svg";
import {DICTIONARY} from "../../enums/dictionary";
import {IInitialStateStore} from "../../redux/types/type";
import {HANDLE_MODAL_INTEGRATION} from "../../redux/actions/actionTypes";
import {withTranslation, WithTranslation} from 'react-i18next';
import ViewPassword from "../../images/view-password.svg";


export const useStyles = () =>
    createStyles({
            modal: {
                position: "fixed",
                left: 0,
                top: 0,
                right: 0,
                bottom: 0,
                width: "100%",
                backgroundColor: "rgba(0,0,0,0.5)",
                fontFamily: "Helvetica",
                "z-index": "9999",
                display: "flex",
                "justify-content": "center",
                "align-items": "center",
                "& h3":
                    {
                        fontSize: "36px",
                        lineHeight: "41px",
                        fontWeight: "700",
                    }
                ,
                "& p":
                    {
                        marginTop: "25px",
                        lineHeight: "28px",
                    }
            },
            container: {
                position: "relative",
                background: "white",
                width: "800px",
                overflow: "auto",
                marginTop: "40px",
                color: "#104EFF",
                borderRadius: "20px",
                ["@media (max-width:812px)"]: {
                    width: "100%",
                },
            }
            ,
            intersect: {
                position: "absolute",
                bottom: 0,
                right: 0,
            }
            ,
            body: {
                padding: "60px 0 0 30px",
                fontSize: "30px",
                lineHeight: "37px",
                "font-weight": "700",
                "& <:nth-chlid(1)": {
                    marginBottom: "50px"
                },
                "& div": {
                    display: "flex",
                    "& img": {
                        marginRight: "20px",
                    }
                }
            }
            ,
            support: {
                marginBottom: "30px",
            },
            header: {
                display: "flex",
                "align-items": "flex-end",
                "justify-content": "space-between",
                fontSize: "36px",
                lineHeight: "41px",
                "font-weight": "700",
                padding: "20px 20px 0 70px",
                "& img": {
                    cursor: "pointer",
                }
            }
            ,
            wrapperForm: {
                display: "flex",
                "flex-direction": "column",
                "& span": {
                    fontSize: "24px",
                    color: "black",
                    margin: "30px 0 15px 0",
                },
                "& input": {
                    height: "60px",
                    fontSize: "24px",
                    border: "1px solid black",
                    borderRadius: "7px",
                    paddingLeft: "10px",
                },
                "& :nth-child(1)": {
                    marginTop: "0px",
                },
                "&  input[type=submit]": {
                    background: "#104EFF",
                    color: "white",
                    "z-index": "1",
                },

            },
            btnStyle: {
                padding: "15px 260px",
                textTransform: "none",
                "& span": {
                    color: "white",
                    margin: "0",
                },

            },
            wrapperCheck: {
                display: "flex",
                "align-items": "center",
                margin: "30px 0 50px 0",
                "& span": {
                    margin: "0",
                },
                "& input": {
                    width: "34px",
                    marginRight: "15px",
                },
                "& a": {
                    fontSize: "24px",
                    marginLeft: "10px",
                }
            },
            headerBox: {
                display: "flex",
                "align-items": "flex-end",
                "& label": {
                    fontSize: "24px",
                    lineHeight: "28px",
                    "font-weight": "300",
                    marginRight: "10px",
                }

            },
            error: {
                color: "red !important",
                margin: "0!important",
            },
            boxForm: {
                padding: "0px 70px 65px 70px",
                "& p": {
                    fontSize: "30px",
                    lineHeight: "34px",
                    "font-weight": "700",
                }
            },
            wrapperInput: {
                display: "flex",
                flexDirection: "column",
                fontSize: "24px",
                lineHeight: "30px",
                "font-weight": "400",
                color: "black",
                marginTop: "30px !important",
                "& input": {
                    marginTop: "15px",
                },


            },
            boxPassword: {
                width: "100%",
                position: "relative",
                "z-index": "1",
                "& input": {
                    width: "100%",
                },
                "& img": {
                    position: "absolute",
                    right: "30px",
                    top: "30%",
                },
            },
            boxLink: {
                margin: "50px 0",
                color: "#104EFF",
                display: "flex",
                justifyContent: "space-between",
                "z-index": "1",
                textDecoration: "underline",
                cursor: "pointer",
            },
            boxCreate: {
                color: "#104EFF",
                display: "flex",
                fontSize: "30px",
                lineHeight: "34px",
                "font-weight": "700",
            },
            createProfile: {
                margin: "0 0 50px 10px",
                textDecoration: "underline",
            }
        },
    )
;


export interface ISub {
    "imgSub": string,
    "text": string,
    "linkImg"?: string
}

// interface IProps extends WithTranslation {
//     prop: any;
// }

interface IProps extends WithTranslation {
    t: any
    classes?: any
    handleModalIteration: any
    isModalIntegration: boolean
}

interface IState {
}

class AutorizationComponent extends Component<IProps, IState> {
    state = {
        email: "",
        password: "",
        isAllRight: false,
        isValid: true,
        passwordRef: '' as any,
        isModal: false,
    };

    componentDidMount(): void {
        this.setState({
            passwordRef: createRef(),
        });
    }

    handleChangeState = (data: any) => {
        const target = data.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        this.setState({
            [name]: value
        });
    };

    handleSubmit(event: any) {
        console.log('Отправленное имя: ', this.state.email);
        event.preventDefault();
    }

    changePassword() {
        this.state.passwordRef.current.type === "password"
            ? this.state.passwordRef.current.type = "text"
            : this.state.passwordRef.current.type = "password";
    }

    render() {
        const {t, classes, handleModalIteration, isModalIntegration} = this.props;
        return (
            <Fragment>
                {this.state.isModal &&
                <Grid className={classes.modal} container spacing={1}>
                    <div className={classes.container}>
                        <div className={classes.header}>
                            {t(DICTIONARY.AUTHORIZATION)}
                            <div className={classes.headerBox}>
                                <label>{t(DICTIONARY.REQUIRED_FIELDS)}</label>
                                <img onClick={() => handleModalIteration()} src={CloseImg} alt={CloseImg.toString()}/>
                            </div>
                        </div>
                        <div className={classes.boxForm}>
                            <p>{t(DICTIONARY.SING_IN_TEXT)}</p>
                            <form className={classes.wrapperForm} onSubmit={this.handleSubmit}>
                                <label className={classes.wrapperInput}>
                                    Email:
                                    <input type="text" name="email" value={this.state.email}
                                           onChange={this.handleChangeState}/>
                                </label>
                                <label className={classes.wrapperInput}>
                                    Password:
                                    <div className={classes.boxPassword}>
                                        <input ref={this.state.passwordRef} type="password" name="password"
                                               value={this.state.password}
                                               onChange={this.handleChangeState}/>
                                        <img onClick={() => this.changePassword()} src={ViewPassword}
                                             alt={ViewPassword.toString()}/>

                                    </div>
                                    <div className={classes.boxLink}>
                                        <label>{t(DICTIONARY.REMEMBER_PASSWORD)}</label>
                                        <label>{t(DICTIONARY.NEED_HELP)}</label>
                                    </div>
                                    <div className={classes.boxCreate}>
                                        <label>{t(DICTIONARY.DONT_HAVE_PROFILE)}</label>
                                        <label
                                            className={classes.createProfile}>{t(DICTIONARY.CRAETE_AN_ACCOUNT)}</label>
                                    </div>
                                </label>
                                <input type="submit" value={t(DICTIONARY.AUTHORIZATION)}/>
                            </form>
                        </div>
                        <img className={classes.intersect} src={IntersectImg} alt={IntersectImg.toString()}/>
                    </div>
                </Grid>}
            </Fragment>
        )
    }
}


const mapStateToProps = (state: IInitialStateStore) => ({isModalIntegration: state.isModalIntegration});

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        handleModalIteration: () => dispatch({type: HANDLE_MODAL_INTEGRATION})
    }
};

const AutorizationConnect = connect(mapStateToProps, mapDispatchToProps)(AutorizationComponent);
const AutorizationStyles = withStyles(useStyles)(AutorizationConnect);
export const AutorizationModal = withTranslation()(AutorizationStyles);
// export const ModalContacts =  compose(withTranslation(),connect(mapStateToProps, mapDispatchToProps),withStyles(useStyles))(ModalComponent);
