import React, { FC, useContext } from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { Button, createStyles, Link, Theme } from "@material-ui/core";
import { Form, Formik } from "formik";
import {
	DICTIONARY,
	FORM_NAMES,
	TYPE_MESSAGE,
} from "../../../enums/dictionary";
import { validationSchemaAuthorization } from "../validation/validate";
import { CastTextField } from "../castom.fields/cast.text.field";
import { CastTextFieldPass } from "../castom.fields/cast.textfield.password";
import axios from "axios";
import { ACTION_TYPES } from "../../../reduser";
import { ContextStore } from "../../../store";
import { ISnackBarData } from "../../../interfaces/interfaces";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			"& .MuiTextField-root": {
				boxSizing: "border-box",
				margin: theme.spacing(1),
			},
		},
		container: {
			maxWidth: "220px",
		},
		formWrap: {
			margin: theme.spacing(1),
			display: "flex",
			flexDirection: "column",
			alignItems: "center",
			justifyContent: "center",
			paddingTop: theme.spacing(2),
			paddingBottom: theme.spacing(2),
		},
		formItem: {
			margin: theme.spacing(1),
			maxWidth: "202px",
			width: "100%",
		},
	})
);

export type IParams = {
	[FORM_NAMES.E_MAIL]: string;
	[FORM_NAMES.PASSWORD]: string;
};

export const LoginForm: FC = () => {
	const classes = useStyles();
	const { dispatch } = useContext(ContextStore);
	const { t } = useTranslation();

	return (
		<div className={classes.container}>
			<Formik
				validateOnChange={true}
				validationSchema={validationSchemaAuthorization}
				initialValues={{
					showPassword: true,
					[FORM_NAMES.E_MAIL]: "",
					[FORM_NAMES.PASSWORD]: "",
				}}
				onSubmit={(data, { setSubmitting }) => {
					setSubmitting(true);
					// asinc call

					const getAuthorize = async () => {
						const params: IParams = {
							[FORM_NAMES.E_MAIL]: data.email,
							[FORM_NAMES.PASSWORD]: data.password,
						};

						try {
							const response = await axios.post(
								`${process.env.REACT_APP_API_END_POINT}api/auth/login`,
								null,
								{
									params: params,
									headers: {
										"content-type": "application/json",
										Accept: "application/json",
									},
								}
							);

							if (response.statusText === "OK") {
								// setCookie(
								// 	'token',
								// 	response.data.accessToken,
								// 	{ path: '/', sameSite: true }
								// 	);
								dispatch({
									type: ACTION_TYPES.SET_EMAIL,
									payload: response.config.params.email,
								});
								localStorage.setItem(
									"email",
									response.config.params.email
								);
								localStorage.setItem(
									"token",
									response.data.accessToken
								);
								console.log(
									"get token and email from res and set to locStorage and store"
								);
							}
						} catch (error) {
							// if (error.response.status === 422) {
							// 	const snackData: ISnackBarData = {
							// 		code: "422",
							// 		message: t(DICTIONARY.LOGIN_ERROR),
							// 		typeMessage: t(TYPE_MESSAGE.ERROR),
							// 	};
							// 	dispatch({
							// 		type: ACTION_TYPES.SET_SNACK_BAR_DATA,
							// 		payload: snackData,
							// 	});
							// }
						}
					};

					getAuthorize();
					setSubmitting(false);
				}}
			>
				{({ values, errors, isSubmitting }) => (
					<Form className={classes.formWrap}>
						<div className={classes.formItem}>
							<CastTextField
								label={`${t(DICTIONARY.E_MAIL)}`}
								name={FORM_NAMES.E_MAIL}
								value=""
								type="input"
								variant="outlined"
								size="small"
							/>
						</div>
						<div className={classes.formItem}>
							<CastTextFieldPass
								label={`${t(DICTIONARY.PASSWORD)}`}
								name={FORM_NAMES.PASSWORD}
								value=""
								type="input"
								variant="outlined"
								size="small"
								InputProps=""
							/>
						</div>
						<div className={classes.formItem}>
							<Button
								variant="outlined"
								color="primary"
								size="small"
								type="submit"
								disabled={isSubmitting}
							>
								{t(DICTIONARY.AUTHORIZE)}
							</Button>
						</div>
						<div className={classes.formItem}>
							<Link href={"/registration"}>
								{t(DICTIONARY.REGISTRATION)}
							</Link>
						</div>
						{/*<pre>{JSON.stringify(values, null, 2)}</pre>*/}
						{/*<pre>{JSON.stringify(errors, null, 2)}</pre>*/}
					</Form>
				)}
			</Formik>
		</div>
	);
};
