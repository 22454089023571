import React, { useReducer } from "react";
import { reducer } from "./reduser";
import { IContext } from "./types";
import { ISnackBarData } from "./interfaces/interfaces";

/***
 * Create interface for state
 */
export interface IContextState {
	captcha: string;
	showRegistrationForm: boolean;
	snackBarDataRender: ISnackBarData | null;
	showLoginForm: boolean;
	newsId: number | null;
	moduleId: number | null;
	email: string | null;
	language: string | null;
}

/***
 * Create state (initial)
 */
export const contextState: IContextState = {
	captcha: "",
	showRegistrationForm: false,
	snackBarDataRender: null,
	showLoginForm: true,
	newsId: null,
	moduleId: null,
	email: null,
	language: null,
};
/***
 * Create react context.
 */
export const ContextStore = React.createContext({
	state: contextState,
} as IContext);

/***
 * Create app context provider.
 */
export const ContextStoreProvider = (props: any): JSX.Element => {
	const [state, dispatch] = useReducer(reducer, contextState);

	return (
		<ContextStore.Provider value={{ state, dispatch }}>
			{props.children}
		</ContextStore.Provider>
	);
};

/***
 * Create app context consumer.
 */

// export function WithContext<propsType>(Component: any) {
//     return function ComponentBoundWithAppContext(props: propsType) {
//         return (
//             <ContextStore.Consumer>
//                 {(context) => {
//                     return <Component {...props} {...context} />;
//                 }}
//             </ContextStore.Consumer>
//         );
//     };
// }
