import React, {Component, CSSProperties, Fragment, useState} from "react";
import {Dispatch} from "redux";
import {connect} from 'react-redux'
import {
    createStyles,
    withStyles,
    Button,
} from "@material-ui/core";
import {Grid} from "@material-ui/core";
import CloseImg from "../../images/close.svg";
import IntersectImg from "../../images/intersect.svg";
import {DICTIONARY} from "../../enums/dictionary";
import {IInitialStateStore} from "../../redux/types/type";
import {HANDLE_MODAL_INTEGRATION} from "../../redux/actions/actionTypes";
import {useTranslation, withTranslation, WithTranslation} from 'react-i18next';
import {useForm} from 'react-hook-form';

export const useStyles = () =>
    createStyles({
            modal: {
                position: "fixed",
                left: 0,
                top: 0,
                right: 0,
                bottom: 0,
                width: "100%",
                backgroundColor: "rgba(0,0,0,0.5)",
                "z-index": "9999",
                display: "flex",
                "justify-content": "center",
                "align-items": "center",
                "& h3":
                    {
                        fontSize: "36px",
                        lineHeight: "41px",
                        fontWeight: "700",
                        fontFamily: "Helvetica",
                    }
                ,
                "& p":
                    {
                        marginTop: "25px",
                        lineHeight: "28px",
                    }
            },
            container: {
                position: "relative",
                height:"1060px",
                background: "white",
                width: "800px",
                overflow: "auto",
                marginTop: "40px",
                color: "#104EFF",
                borderRadius: "20px",
                ["@media (max-width:812px)"]: {
                    width: "100%",
                },
            }
            ,
            intersect: {
                position: "absolute",
                bottom: 0,
                right: 0,
            }
            ,
            body: {
                padding: "60px 0 0 30px",
                fontSize: "30px",
                lineHeight: "37px",
                "font-weight": "700",
                fontFamily: "Helvetica",
                "& <:nth-chlid(1)": {
                    marginBottom: "50px"
                },
                "& div": {
                    display: "flex",
                    "& img": {
                        marginRight: "20px",
                    }
                }
            }
            ,
            support: {
                marginBottom: "30px",
            },
            header: {
                display: "flex",
                "align-items": "flex-end",
                "justify-content": "space-between",
                fontSize: "36px",
                lineHeight: "41px",
                "font-weight": "700",
                fontFamily: "Helvetica",
                padding: "20px 20px 0 70px",
                "& img": {
                    cursor: "pointer",
                }
            }
            ,
            wrapperForm: {
                display: "flex",
                "flex-direction": "column",
                padding: "0px 70px 65px 70px",
                "& span": {
                    fontSize: "24px",
                    color: "black",
                    margin: "30px 0 15px 0",
                },
                "& input": {
                    height: "60px",
                    border: "1px solid black",
                    borderRadius: "7px",
                },
                "& :nth-child(1)":{
                    marginTop:"0px",
                }

            },
            btnStyle: {
                padding: "15px 260px",
                textTransform: "none",
                "& span": {
                    color: "white",
                    margin: "0",
                },

            },
            wrapperCheck: {
                display: "flex",
                "align-items": "center",
                margin: "30px 0 50px 0",
                "& span": {
                    margin: "0",
                },
                "& input": {
                    width: "34px",
                    marginRight: "15px",
                },
                "& a": {
                    fontSize: "24px",
                    marginLeft: "10px",
                }
            },
            headerBox: {
                display: "flex",
                "align-items": "flex-end",
                "& label": {
                    fontSize: "24px",
                    lineHeight: "28px",
                    "font-weight": "300",
                    marginRight: "10px",
                }

            },
            error: {
                color: "red !important",
                margin: "0!important",
            },

        }
    )
;

export interface ISub {
    "imgSub": string,
    "text": string,
    "linkImg"?: string
}

// interface IProps extends WithTranslation {
//     prop: any;
// }

interface IProps extends WithTranslation {
    t: any
    classes?: any
    handleModalIteration: any
    isModalIntegration: boolean
}

interface IState {
}

class ModalComponent extends Component<IProps, IState> {
    state = {
        firstName: "",
        lastName: "",
        emailAddresses: "",
        nameCompany: "",
        businessSite: "",
        isAllRight: false,
        isValid: true,
    };
    onSubmit = (data: any) => {
        const target = data.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        this.setState({
            [name]: value
        });
    };
    validForm = () => {
        if (this.state.firstName && this.state.lastName && this.state.emailAddresses) {
            this.setState({isValid: true});
        } else {
            this.setState({isValid: false});
            return
        }
        alert("Request");
        this.props.handleModalIteration();
    };

    render() {
        const {t, classes, handleModalIteration, isModalIntegration} = this.props;
        return (
            <Fragment>
                {isModalIntegration &&
                <Grid className={classes.modal} container spacing={1}>
                    {/*test {this.props.isModalContacts}*/}
                    <div className={classes.container}>
                        <div className={classes.header}>
                            {t(DICTIONARY.SUBMIT_APPLICATION)}
                            <div className={classes.headerBox}>
                                <label>{t(DICTIONARY.REQUIRED_FIELDS)}</label>
                                <img onClick={() => handleModalIteration()} src={CloseImg} alt={CloseImg.toString()}/>
                            </div>
                        </div>
                        <div className={classes.container}>
                            <div className={classes.wrapperForm}>
                                <span>{t(DICTIONARY.FIRS_NAME)}</span>
                                <input name="firstName" value={this.state.firstName} onChange={this.onSubmit}/>
                                <span>{t(DICTIONARY.LAST_NAME)}</span>
                                <input name="lastName" value={this.state.lastName} onChange={this.onSubmit}/>
                                <span>{t(DICTIONARY.EMAIL_ADDRESSES)}</span>
                                <input name="emailAddresses" value={this.state.emailAddresses}
                                       onChange={this.onSubmit}/>
                                <span>{t(DICTIONARY.NAME_COMPANY)}</span>
                                <input name="nameCompany" value={this.state.nameCompany} onChange={this.onSubmit}/>
                                <span>{t(DICTIONARY.BUSINESS_SITE)}</span>
                                <input name="businessSite" value={this.state.businessSite} onChange={this.onSubmit}/>
                                <div className={classes.wrapperCheck}>
                                    <input type="checkbox" onChange={this.onSubmit} name="isAllRight"/>
                                    <span>{t(DICTIONARY.I_AGREE_WITH)}</span>
                                    <a href="/integration">{t(DICTIONARY.TERMS_OF_AGREEMENT)}</a>
                                    <p> {this.state.isAllRight}             </p>
                                </div>
                                {!this.state.isValid &&
                                <span className={classes.error}>{t(DICTIONARY.ERROR_REQUIRED)}</span>}
                                <Button
                                    type="submit"
                                    className={classes.btnStyle}
                                    variant="contained"
                                    color="primary"
                                    disabled={!this.state.isAllRight}
                                    onClick={this.validForm}>
                                    {t(DICTIONARY.NEXT)}
                                </Button>
                            </div>
                        </div>

                        <img className={classes.intersect} src={IntersectImg} alt={IntersectImg.toString()}/>
                    </div>
                </Grid>}
            </Fragment>
        )
    }
}


const mapStateToProps = (state: IInitialStateStore) => ({isModalIntegration: state.isModalIntegration});

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        handleModalIteration: () => dispatch({type: HANDLE_MODAL_INTEGRATION})
    }
};

const ModalConnect = connect(mapStateToProps, mapDispatchToProps)(ModalComponent);
const ModalStyles = withStyles(useStyles)(ModalConnect);
export const ModalCustom = withTranslation()(ModalStyles);
// export const ModalContacts =  compose(withTranslation(),connect(mapStateToProps, mapDispatchToProps),withStyles(useStyles))(ModalComponent);
