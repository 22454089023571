import React, { FC, Fragment, useContext, useState, useEffect } from "react";
import {
	createStyles,
	makeStyles,
	Theme,
	Grid,
	Button,
} from "@material-ui/core";
import { DICTIONARY, TYPE_MESSAGE } from "../../../enums/dictionary";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import "./product.details.css";
import { Feedback } from "../../forms/feedback.form/feedback.form";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { ContextStore } from "../../../store";
import { ISnackBarData } from "../../../interfaces/interfaces";
import { ACTION_TYPES } from "../../../reduser";

export const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			display: "flex",
			justifyContent: "center",
		},
		description: {
			margin: theme.spacing(1),
			width: "100%",
			height: "30vw",
			maxHeight: "308px",
			borderRadius: "15px",
			backgroundColor: "#0ed1be",
			display: "flex",
			alignItems: "center",
		},
		descriptionText: {
			color: " white",
			padding: theme.spacing(2),
			textAlign: "center",
			[theme.breakpoints.up("md")]: {
				fontSize: "21px",
			},
			[theme.breakpoints.down("md")]: {
				fontSize: "18px",
			},
			[theme.breakpoints.down("sm")]: {
				fontSize: "15px",
			},
			[theme.breakpoints.down("xs")]: {
				fontSize: "12px",
			},
		},
		callbackForm: {
			margin: theme.spacing(1),
			width: "100%",
			borderRadius: "15px",
		},
		btnStyle: {
			margin: theme.spacing(1),
			textTransform: "none",
		},
		btnPosition: {
			display: "flex",
			justifyContent: "center",
		},
		imgStyle: {
			width: "100%",
			height: "40vw",
			maxHeight: "460px",
			borderRadius: "15px",
		},

		[theme.breakpoints.down("md")]: {
			imgStyle: {
				height: "45vw",
			},
		},
		[theme.breakpoints.down("xs")]: {
			dexcriptionText: {
				fontSize: "3vw",
			},
			description: {
				height: "35vw",
			},
		},
	})
);

export type ISingleModule = {
	id: number | null;
	image: string;
	content: string;
	created_at?: string;
	download_link: string;
	git_link?: string;
};

export const ProductDetail: FC = () => {
	const classes = useStyles();
	const { t } = useTranslation();
	const { state, dispatch } = useContext(ContextStore);
	const [language, setLanguage] = useState<string | null>("");

	const [singleModule, setSingleModule] = useState<ISingleModule>({
		id: null,
		image: "",
		content: "",
		created_at: "",
		download_link: "",
		git_link: "",
	});

	useEffect(() => {
		if (state.language) {
			setLanguage(state.language);
		} else {
			const lang = localStorage.getItem("i18nextLng");
			setLanguage(lang);
		}
	}, [state.language]);

	useEffect(() => {
		if (state.moduleId !== null) {
			const moduleId: any = state.moduleId;
			localStorage.setItem("module_id", moduleId);
		}
	}, [state.moduleId]);

	useEffect(() => {
		const moduleId = localStorage.getItem("module_id");
		let getSingleModule = async () => {
			try {
				const response = await axios.get(
					`${process.env.REACT_APP_API_END_POINT}api/open/libs/${moduleId}`,
					{
						params: {
							lang: language,
						},
						headers: {
							"content-type": "application/json",
							Accept: "application/json",
						},
					}
				);

				if (
					response.data.data !== null &&
					response.data.data !== undefined
				) {
					const singleModule = response.data.data;
					setSingleModule(singleModule);
				}
			} catch (error) {
				// console.log("error = ", error);
				// if (error.response.status === 500) {
				// 	const snackData: ISnackBarData = {
				// 		code: "500",
				// 		message: t(DICTIONARY.SERVER_ERROR),
				// 		typeMessage: t(TYPE_MESSAGE.ERROR),
				// 	};
				// 	dispatch({
				// 		type: ACTION_TYPES.SET_SNACK_BAR_DATA,
				// 		payload: snackData,
				// 	});
				// }
			}
		};
		getSingleModule();
	}, [language]);

	const handleDownload = () => {
		console.log("DOWNLOAD = ", singleModule.download_link);
	};

	const handleOpenGit = () => window.open(`${singleModule.git_link}`);

	const responsive = {
		superLargeDesktop: {
			// the naming can be any, depends on you.
			breakpoint: { max: 4000, min: 3000 },
			items: 1,
		},
		desktop: {
			breakpoint: { max: 3000, min: 1024 },
			items: 1,
		},
		tablet: {
			breakpoint: { max: 1024, min: 464 },
			items: 1,
		},
		mobile: {
			breakpoint: { max: 600, min: 0 },
			items: 1,
		},
	};

	return (
		<Fragment>
			<Grid container spacing={1} className={classes.root}>
				<Grid item xs={12} sm={10}>
					<Carousel responsive={responsive} showDots infinite={true}>
						<img
							alt="modulePhoto"
							src={singleModule.image}
							className={classes.imgStyle}
						></img>
					</Carousel>
				</Grid>
				<Grid item xs={10} className={classes.btnPosition}>
					<Button
						className={classes.btnStyle}
						variant="contained"
						color="primary"
						onClick={handleDownload}
					>
						{t(DICTIONARY.DOWNLOAD)}
					</Button>
					<Button
						className={classes.btnStyle}
						variant="outlined"
						onClick={handleOpenGit}
					>
						GitHub
					</Button>
				</Grid>
				<Grid item xs={12} sm={10} className={classes.description}>
					<p className={classes.descriptionText}>
						{t(singleModule.content)}
					</p>
				</Grid>
				<Grid item xs={12} sm={10} className={classes.callbackForm}>
					<Feedback />
				</Grid>
			</Grid>
		</Fragment>
	);
};
