import ReCAPTCHA from "react-google-recaptcha";
import React, { FC, useContext } from "react";
import { makeStyles, Theme, createStyles, Box } from "@material-ui/core";
import { ContextStore } from "../../store";
import { ACTION_TYPES } from "../../reduser";

export const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		capchaStyle: {
			[theme.breakpoints.down(400)]: {
				size: "compact",
			},
		},
	})
);

export const GoogleCapcha: FC = () => {
	const classes = useStyles();
	const startWidth = document.documentElement.clientWidth;

	const { dispatch } = useContext(ContextStore);

	const onChange = (value: any) => {
		dispatch({ type: ACTION_TYPES.SET_CAPTCHA, payload: value });
	};

	return (
		<Box className={classes.capchaStyle}>
			<ReCAPTCHA
				sitekey="6LccqrsZAAAAAAUOuIOSM-aoAJK73RmVAHzQSiO-
				"
				onChange={onChange}
				size={startWidth < 400 ? "compact" : "normal"}
			/>
		</Box>
	);
};
