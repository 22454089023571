import React, {useContext, useEffect} from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert, {AlertProps} from '@material-ui/lab/Alert';
import {makeStyles, Theme} from '@material-ui/core/styles';
import {TYPE_MESSAGE} from "../../enums/dictionary";
import {ContextStore} from "../../store";
import {ISnackBarData} from "../../interfaces/interfaces";
import {ClickAwayListener} from "@material-ui/core";
import {ACTION_TYPES} from "../../reduser";


function Alert(props: AlertProps) {
	return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme: Theme) => ({
	root: {
		width: '100%',
		'& > * + *': {
			marginTop: theme.spacing(2),
		},
	},
	alertColorError: {
		background: theme.palette.warning.light
	},
	alertColorWarning: {
		background: theme.palette.error.main
	},
	alertColorInformation: {
		background: theme.palette.primary.main
	},
	alertColorSuccess: {
		background: theme.palette.info.light
	}
}));


export const CustomizedSnackbars: React.FC = () => {
	const classes = useStyles();
	const [open, setOpen] = React.useState(false);
	const [data, setData] = React.useState<ISnackBarData | null>(null);
	const { state, dispatch } = useContext(ContextStore);

	useEffect(() => {
		if (state.snackBarDataRender) {
			setOpen(true);
			setData(state.snackBarDataRender)
		} else {
			setOpen(false);
		}
	}, [state.snackBarDataRender]);

	const handleClickAway = () => {
		dispatch({type: ACTION_TYPES.SET_SNACK_BAR_DATA, payload: null});

	};

	function alertRender (data: ISnackBarData) {
		switch (data.typeMessage) {
			case TYPE_MESSAGE.ERROR:
				return (<Alert severity="error" className={classes.alertColorError} >{data.message}</Alert>);
			case TYPE_MESSAGE.WARNING:
				return (<Alert severity="warning" className={classes.alertColorWarning} >{data.message}</Alert>);
			case TYPE_MESSAGE.INFORMATION:
				return (<Alert severity="info" className={classes.alertColorInformation} >{data.message}</Alert>);
			case TYPE_MESSAGE.SUCCESS:
				return (<Alert severity="success" className={classes.alertColorSuccess} >{data.message}</Alert>);
			default:
				return <></>;
		}
	}

	return (
		data ?
			<ClickAwayListener onClickAway={handleClickAway}>
				<div className={classes.root}>
					<Snackbar
						open={open}
						// autoHideDuration={2000}
						onClose={handleClickAway}

					>
						{alertRender(data)}
					</Snackbar>
				</div>
			</ClickAwayListener>
			 : null
	);
};
