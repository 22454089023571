import {arrayStartIntegration} from "./ArrayStartIntegration.text";
import {Accordion, AccordionDetails, AccordionSummary, createStyles, Theme, Typography} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import {DICTIONARY} from "../../../enums/dictionary";
import React from "react";
import {useTranslation} from "react-i18next";
import {makeStyles} from "@material-ui/core/styles";


export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: "flex",
            background: "white",
            borderRadius: "10px",
            padding: "35px 75px",
            "& h3": {
                fontFamily: "Helvetica",
                fontSize: "24px",
                lineHeight: "28px",
                "font-weight": "700",
            },
            fontFamily: "Helvetica",
            fontSize: "20px",
            lineHeight: "24px",
            "font-weight": "400",
        },
        messageStyle: {
            whiteSpace: "pre-line",
            fontFamily: "Helvetica",
            fontSize: "16px",
            lineHeight: "20px",
            "font-weight": "400",
            ["@media (max-width:812px)"]: {
                padding: "0 14px",
            },
        },
        heading: {
            color: "#104EFF",
            fontFamily: "Helvetica",
            "font-weight": "700 !important",
            ["@media (max-width:812px)"]: {
                padding: "0 14px",
                fontSize: "16px !important",
                lineHeight: "20px !important",
            },
        },
        acordeonStyle: {
            paddingBottom: theme.spacing(3),
        },
        wrapperBox: {
            padding: "0 15% 70px",
            ["@media (max-width:812px)"]: {
                padding: "0 0 50px",
            },
            "& .MuiAccordionSummary-root": {
                padding: "0",
                "& p": {
                    padding: "0",
                    margin: "0",
                }
            },
            "& .MuiPaper-elevation1": {
                padding: "0 10%",
            },
            "& .MuiAccordionDetails-root": {
                padding: "0",
                "& p": {
                    padding: "0",
                }
            }
        },
        blueColor: {
            color: "#104EFF",
        },
        boxInfo: {
            padding: "0 10%",
            "& label": {
                fontSize: "16px",
            },
            "& p": {
                marginLeft: "0 !important",
                "font-weight": "600 !important",
            }
        },
        mr_l_16: {
            marginLeft: "16px",
        },
        accordionSummary: {
            "& p": {
                fontFamily: "Helvetica",
                fontSize: "20px",
                lineHeight: "24px",
                "font-weight": "400",
            },
            "& span": {
                color: "#104EFF",
                transform: "rotate(270deg)",
                ["@media (max-width:812px)"]: {
                    "& svg": {
                        width: "44px",
                        height: "44px",
                    },
                },
            },
            "& .Mui-expanded": {
                "& span": {
                    color: "black",
                    transform: "rotate(180deg)",
                },
                "& p": {
                    color: "black",
                    textDecoration: "underline",
                },
            },
        },
        separator: {
            width: "100%",
            height: "2px",
            background: "grey",
            opacity: '0.3',
        },
        wrapperSeparator: {
            padding: "20px 10% 50px",
            background: "white",
            ["@media (max-width:812px)"]: {
                padding: "20px 18px 50px",

            }
        },
        wrapperSeparatorManage: {
            padding: "20px 0 50px",
            background: "white",
        }
    })
);

// export const getListIn = () => {
//     /* eslint-disable */
//     const classname = useStyles();
//     const {t} = useTranslation();
//     const [expanded, setExpanded] = React.useState<string | false>('panel1');
//     const handleChange = (panel: string) => (event: React.ChangeEvent<{}>, newExpanded: boolean) => {
//         setExpanded(newExpanded ? panel : false);
//     };
//
//     return (<>
//             <Accordion
//                 square expanded={expanded === 'panel1'}
//                 onChange={handleChange("panel1")}
//             >
//                 <AccordionSummary
//                     className={classname.accordionSummary}
//                     expandIcon={<ExpandMoreIcon/>}
//                     aria-controls="panel1a-content"
//                     id="panel1a-header"
//                 >
//                     <Typography className={classname.heading}>
//                         {t(DICTIONARY.PROVIDES_POSTAL)}
//                     </Typography>
//                 </AccordionSummary>
//                 <AccordionDetails>
//                     <Typography className={classname.messageStyle}>
//                         <br/>
//                         <label className={classname.blueColor}>{t(DICTIONARY.B2B)}</label>
//                         <label>{t(DICTIONARY.B2B_SUB)}</label><br/><br/>
//                         <label className={classname.blueColor}>{t(DICTIONARY.C2C)}</label>
//                         <label>{t(DICTIONARY.C2C_SUB)}</label><br/><br/>
//                         <label className={classname.blueColor}>{t(DICTIONARY.B2C)}</label>
//                         <label>{t(DICTIONARY.B2C_SUB)}</label><br/><br/>
//                         <label className={classname.blueColor}>{t(DICTIONARY.C2B)}</label>
//                         <label>{t(DICTIONARY.C2B_SUB)}</label>
//                     </Typography>
//                 </AccordionDetails>
//             </Accordion>
//         </>
//     );
// };
export const getListIn = () => {
        /* eslint-disable */
        const classname = useStyles();
        const {t} = useTranslation();
        const [expanded, setExpanded] = React.useState<string | false>('panel1');
        const handleChange = (panel: string) => (event: React.ChangeEvent<{}>, newExpanded: boolean) => {
            setExpanded(newExpanded ? panel : false);
        };

        return (<div className={classname.boxInfo}>
                <p>{t(DICTIONARY.PROVIDES_POSTAL)}</p>
                <br/>
                <div className={classname.mr_l_16}>
                    <label className={classname.blueColor}>{t(DICTIONARY.B2B)}</label>
                    <label>{t(DICTIONARY.B2B_SUB)}</label><br/><br/>
                    <label className={classname.blueColor}>{t(DICTIONARY.C2C)}</label>
                    <label>{t(DICTIONARY.C2C_SUB)}</label><br/><br/>
                    <label className={classname.blueColor}>{t(DICTIONARY.B2C)}</label>
                    <label>{t(DICTIONARY.B2C_SUB)}</label><br/><br/>
                    <label className={classname.blueColor}>{t(DICTIONARY.C2B)}</label>
                    <label>{t(DICTIONARY.C2B_SUB)}</label>
                </div>
            </div>
        );
    }
;

interface IAccordion {
    title: string,
    Body: Function,
    isOpen: boolean,
    handleOpen: Function,
    key: string
}


function getOpenAccordion({title, Body, isOpen, handleOpen, key}: IAccordion): JSX.Element {
    const classname = useStyles();
    return <>
        <Accordion
            square expanded={isOpen}
            onChange={handleOpen(key)}
        >
            <AccordionSummary
                className={classname.accordionSummary}
                expandIcon={<ExpandMoreIcon/>}
                aria-controls="panel1a-content"
                id="panel1a-header"
            >
                <Typography className={classname.heading}>
                    <br/>
                    {title}
                </Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Typography className={classname.messageStyle}>
                    <br/>
                    <Body/>
                </Typography>
            </AccordionDetails>
        </Accordion>
        <div className={classname.wrapperSeparator}>
            <div className={classname.separator}/>
        </div>
    </>
}


export const getListInJustin = () => {
    const {t} = useTranslation();
    const [expanded, setExpanded] = React.useState<boolean>(true);
    const [crm, setCrm] = React.useState<boolean>(true);
    const [platform, setPlatform] = React.useState<boolean>(true);

    const handleChange = (key: string) => () => {
        switch (key) {
            case "DIRECT_INTEGRATION":
                return setExpanded(!expanded);
            case "INTEGRATION_OF_CRM":
                return setCrm(!crm);
            case "ECOMMERCE_PLATFORM":
                return setPlatform(!platform);
            default:
                return;
        }
    };

    const arraysOpenAccordion: IAccordion[] = [
        {
            title: t(DICTIONARY.DIRECT_INTEGRATION),
            Body: () => {
                return <>{t(DICTIONARY.DIRECT_INTEGRATION_STEP_1)}</>
            },
            isOpen: expanded,
            handleOpen: handleChange,
            key: "DIRECT_INTEGRATION",
        },
        {
            title: t(DICTIONARY.INTEGRATION_OF_CRM),
            Body: () => {
                return <> <br/>
                    {t(DICTIONARY.INTEGRATION_OF_CRM_STEP_1)}<br/><br/>
                    {t(DICTIONARY.INTEGRATION_OF_CRM_STEP_2)}</>
            },
            isOpen: crm,
            handleOpen: handleChange,
            key: "INTEGRATION_OF_CRM",
        },
        {
            title: t(DICTIONARY.ECOMMERCE_PLATFORM),
            Body: () => {
                return <>  <br/>
                    {t(DICTIONARY.ECOMMERCE_PLATFORM_STEP_1)}<br/><br/>
                    {t(DICTIONARY.ECOMMERCE_PLATFORM_STEP_2)}</>
            },
            isOpen: platform,
            handleOpen: handleChange,
            key: "ECOMMERCE_PLATFORM",
        }
    ];

    return (<>
            {arraysOpenAccordion.map((item) => {
                    return <div key={item.key}>{getOpenAccordion(item)}</div>
                }
            )}
        </>
    );
};

export const getListManageYourShipments = () => {

    const classname = useStyles();
    const {t} = useTranslation();
    const [scheduled, setScheduled] = React.useState<boolean>(true);
    const [shipment, setShipment] = React.useState<boolean>(true);
    const [transportation, setTransportation] = React.useState<boolean>(true);
    const [rerouting, setRerouting] = React.useState<boolean>(true);
    const [issuance, setIssuance] = React.useState<boolean>(true);
    const [unsuccessful, setUnsuccessful] = React.useState<boolean>(true);

    const handleChange = (key: string) => () => {
        switch (key) {
            case "SCHEDULED":
                return setScheduled(!scheduled);
            case "STATUSES_OF_SHIPMENT":
                return setShipment(!shipment);
            case "TRANSPORTATION_STATUSES":
                return setTransportation(!transportation);
            case "SHIPMENT_REROUTING":
                return setRerouting(!rerouting);
            case "STATUS_OF_ISSUANCE":
                return setIssuance(!issuance);
            case "STATUS_OF_UNSUCCESSFUL":
                return setUnsuccessful(!unsuccessful);
            default:
                return;
        }
    };

    const arraysOpenAccordionShipments: IAccordion[] = [
        {
            title: t(DICTIONARY.CREATION_STATUSES),
            Body: () => {
                return <>
                    <label className={classname.blueColor}> {t(DICTIONARY.SCHEDULED)}</label>
                    {t(DICTIONARY.SCHEDULED_SUB)}<br/><br/>
                    <label className={classname.blueColor}> {t(DICTIONARY.SCHEDULED_FOR_PICKUP)}</label>
                    {t(DICTIONARY.SCHEDULED_FOR_PICKUP_SUB)}<br/><br/>
                    <label className={classname.blueColor}> {t(DICTIONARY.CANCELED_BY_SENDER)}</label>
                    {t(DICTIONARY.CANCELED_BY_SENDER_SUB)}<br/><br/>
                </>
            },
            isOpen: scheduled,
            handleOpen: handleChange,
            key: "SCHEDULED",
        },
        {
            title: t(DICTIONARY.STATUSES_OF_SHIPMENT),
            Body: () => {
                return <>
                    <label className={classname.blueColor}> {t(DICTIONARY.ACCEPTED_BY)}</label>
                    {t(DICTIONARY.ACCEPTED_BY_SUB)}<br/><br/>
                    <label className={classname.blueColor}> {t(DICTIONARY.ACCEPTED_DEPARTMENT)}</label>
                    {t(DICTIONARY.ACCEPTED_DEPARTMENT_SUB)}<br/><br/>
                </>
            },
            isOpen: shipment,
            handleOpen: handleChange,
            key: "STATUSES_OF_SHIPMENT",
        },
        {
            title: t(DICTIONARY.TRANSPORTATION_STATUSES),
            Body: () => {
                return <>
                    <label className={classname.blueColor}> {t(DICTIONARY.SENT_TO_CITY)}</label>
                    {t(DICTIONARY.SENT_TO_CITY_SUB)}<br/><br/>
                    <label className={classname.blueColor}> {t(DICTIONARY.SENT_TO_TRUNK)}</label>
                    {t(DICTIONARY.SENT_TO_TRUNK_SUB)}<br/><br/>
                    <label className={classname.blueColor}> {t(DICTIONARY.DEPARTMENT_ACCEPTED)}</label>
                    {t(DICTIONARY.DEPARTMENT_ACCEPTED_SUB)}<br/><br/>
                    <label className={classname.blueColor}> {t(DICTIONARY.ACCEPTED_BRANCH)}</label>
                    {t(DICTIONARY.ACCEPTED_BRANCH_SUB)}<br/><br/>
                    <label className={classname.blueColor}> {t(DICTIONARY.FORWARDED_RECIPIENT)}</label>
                    {t(DICTIONARY.FORWARDED_RECIPIENT_SUB)}<br/><br/>
                </>
            },
            isOpen: transportation,
            handleOpen: handleChange,
            key: "TRANSPORTATION_STATUSES",
        },
        {
            title: t(DICTIONARY.SHIPMENT_REROUTING),
            Body: () => {
                return <>
                    <label className={classname.blueColor}> {t(DICTIONARY.RETURNED_SENDER)}</label>
                    {t(DICTIONARY.RETURNED_SENDER_SUB)}<br/><br/>
                    <label className={classname.blueColor}> {t(DICTIONARY.FORWARDED)}</label>
                    {t(DICTIONARY.FORWARDED_SUB)}<br/><br/>
                </>
            },
            isOpen: rerouting,
            handleOpen: handleChange,
            key: "SHIPMENT_REROUTING",
        },
        {
            title: t(DICTIONARY.STATUS_OF_ISSUANCE),
            Body: () => {
                return <>
                    <label className={classname.blueColor}> {t(DICTIONARY.RETURNED_SENDER)}</label>
                    {t(DICTIONARY.RETURNED_SENDER_SUB)}<br/><br/>
                    <label className={classname.blueColor}> {t(DICTIONARY.FORWARDED)}</label>
                    {t(DICTIONARY.FORWARDED_SUB)}<br/><br/>
                </>
            },
            isOpen: issuance,
            handleOpen: handleChange,
            key: "STATUS_OF_ISSUANCE",
        },
        {
            title: t(DICTIONARY.STATUS_OF_UNSUCCESSFUL),
            Body: () => {
                return <>
                    <label className={classname.blueColor}> {t(DICTIONARY.REFUSAL_TO_RECEIVE)}</label>
                    {t(DICTIONARY.REFUSAL_TO_RECEIVE_SUB)}<br/><br/>
                    <label className={classname.blueColor}> {t(DICTIONARY.EXPIRED_SHELF)}</label>
                    {t(DICTIONARY.EXPIRED_SHELF_SUB)}<br/><br/>
                </>
            },
            isOpen: unsuccessful,
            handleOpen: handleChange,
            key: "STATUS_OF_UNSUCCESSFUL",
        },
    ];


    return (<div className={classname.wrapperBox}>
            {arraysOpenAccordionShipments.map((item) => {
                    return <div key={item.key}>{getOpenAccordion(item)}</div>
                }
            )}

            {/*<div className={classname.wrapperSeparatorManage}>*/}
            {/*    <div className={classname.separator}/>*/}
            {/*</div>*/}
            {/*<Accordion>*/}
            {/*    <AccordionSummary*/}
            {/*        className={classname.accordionSummary}*/}
            {/*        expandIcon={<ExpandMoreIcon/>}*/}
            {/*        aria-controls="panel1a-content"*/}
            {/*        id="panel1a-header"*/}
            {/*    >*/}
            {/*        <Typography className={classname.heading}>*/}
            {/*            {t(DICTIONARY.STATUSES_OF_SHIPMENT)}*/}
            {/*        </Typography>*/}
            {/*    </AccordionSummary>*/}
            {/*    <AccordionDetails>*/}
            {/*        <Typography className={classname.messageStyle}>*/}
            {/*            <label className={classname.blueColor}> {t(DICTIONARY.ACCEPTED_BY)}</label>*/}
            {/*            {t(DICTIONARY.ACCEPTED_BY_SUB)}<br/><br/>*/}
            {/*            <label className={classname.blueColor}> {t(DICTIONARY.ACCEPTED_DEPARTMENT)}</label>*/}
            {/*            {t(DICTIONARY.ACCEPTED_DEPARTMENT_SUB)}<br/><br/>*/}
            {/*        </Typography>*/}
            {/*    </AccordionDetails>*/}
            {/*</Accordion>*/}
            {/*<div className={classname.wrapperSeparatorManage}>*/}
            {/*    <div className={classname.separator}/>*/}
            {/*</div>*/}
            {/*<Accordion>*/}
            {/*    <AccordionSummary*/}
            {/*        className={classname.accordionSummary}*/}
            {/*        expandIcon={<ExpandMoreIcon/>}*/}
            {/*        aria-controls="panel1a-content"*/}
            {/*        id="panel1a-header"*/}
            {/*    >*/}
            {/*        <Typography className={classname.heading}>*/}
            {/*            {t(DICTIONARY.TRANSPORTATION_STATUSES)}*/}
            {/*        </Typography>*/}
            {/*    </AccordionSummary>*/}
            {/*    <AccordionDetails>*/}
            {/*        <Typography className={classname.messageStyle}>*/}
            {/*            <label className={classname.blueColor}> {t(DICTIONARY.SENT_TO_CITY)}</label>*/}
            {/*            {t(DICTIONARY.SENT_TO_CITY_SUB)}<br/><br/>*/}
            {/*            <label className={classname.blueColor}> {t(DICTIONARY.SENT_TO_TRUNK)}</label>*/}
            {/*            {t(DICTIONARY.SENT_TO_TRUNK_SUB)}<br/><br/>*/}
            {/*            <label className={classname.blueColor}> {t(DICTIONARY.DEPARTMENT_ACCEPTED)}</label>*/}
            {/*            {t(DICTIONARY.DEPARTMENT_ACCEPTED_SUB)}<br/><br/>*/}
            {/*            <label className={classname.blueColor}> {t(DICTIONARY.ACCEPTED_BRANCH)}</label>*/}
            {/*            {t(DICTIONARY.ACCEPTED_BRANCH_SUB)}<br/><br/>*/}
            {/*            <label className={classname.blueColor}> {t(DICTIONARY.FORWARDED_RECIPIENT)}</label>*/}
            {/*            {t(DICTIONARY.FORWARDED_RECIPIENT_SUB)}<br/><br/>*/}
            {/*        </Typography>*/}
            {/*    </AccordionDetails>*/}
            {/*</Accordion>*/}
            {/*<div className={classname.wrapperSeparatorManage}>*/}
            {/*    <div className={classname.separator}/>*/}
            {/*</div>*/}
            {/*<Accordion>*/}
            {/*    <AccordionSummary*/}
            {/*        className={classname.accordionSummary}*/}
            {/*        expandIcon={<ExpandMoreIcon/>}*/}
            {/*        aria-controls="panel1a-content"*/}
            {/*        id="panel1a-header"*/}
            {/*    >*/}
            {/*        <Typography className={classname.heading}>*/}
            {/*            {t(DICTIONARY.SHIPMENT_REROUTING)}*/}
            {/*        </Typography>*/}
            {/*    </AccordionSummary>*/}
            {/*    <AccordionDetails>*/}
            {/*        <Typography className={classname.messageStyle}>*/}
            {/*            <label className={classname.blueColor}> {t(DICTIONARY.RETURNED_SENDER)}</label>*/}
            {/*            {t(DICTIONARY.RETURNED_SENDER_SUB)}<br/><br/>*/}
            {/*            <label className={classname.blueColor}> {t(DICTIONARY.FORWARDED)}</label>*/}
            {/*            {t(DICTIONARY.FORWARDED_SUB)}<br/><br/>*/}
            {/*        </Typography>*/}
            {/*    </AccordionDetails>*/}
            {/*</Accordion>*/}
            {/*<div className={classname.wrapperSeparatorManage}>*/}
            {/*    <div className={classname.separator}/>*/}
            {/*</div>*/}
            {/*<Accordion>*/}
            {/*    <AccordionSummary*/}
            {/*        className={classname.accordionSummary}*/}
            {/*        expandIcon={<ExpandMoreIcon/>}*/}
            {/*        aria-controls="panel1a-content"*/}
            {/*        id="panel1a-header"*/}
            {/*    >*/}
            {/*        <Typography className={classname.heading}>*/}
            {/*            {t(DICTIONARY.STATUS_OF_ISSUANCE)}*/}
            {/*        </Typography>*/}
            {/*    </AccordionSummary>*/}
            {/*    <AccordionDetails>*/}
            {/*        <Typography className={classname.messageStyle}>*/}
            {/*            <label className={classname.blueColor}> {t(DICTIONARY.ISSUED_TO_FINAL)}</label>*/}
            {/*            {t(DICTIONARY.ISSUED_TO_FINAL_SUB)}<br/><br/>*/}
            {/*            <label className={classname.blueColor}> {t(DICTIONARY.RETURNED_TO_SENDER)}</label>*/}
            {/*            {t(DICTIONARY.RETURNED_TO_SENDER_SUB)}<br/><br/>*/}
            {/*        </Typography>*/}
            {/*    </AccordionDetails>*/}
            {/*</Accordion>*/}
            {/*<div className={classname.wrapperSeparatorManage}>*/}
            {/*    <div className={classname.separator}/>*/}
            {/*</div>*/}
            {/*<Accordion>*/}
            {/*    <AccordionSummary*/}
            {/*        className={classname.accordionSummary}*/}
            {/*        expandIcon={<ExpandMoreIcon/>}*/}
            {/*        aria-controls="panel1a-content"*/}
            {/*        id="panel1a-header"*/}
            {/*    >*/}
            {/*        <Typography className={classname.heading}>*/}
            {/*            {t(DICTIONARY.STATUS_OF_UNSUCCESSFUL)}*/}
            {/*        </Typography>*/}
            {/*    </AccordionSummary>*/}
            {/*    <AccordionDetails>*/}
            {/*        <Typography className={classname.messageStyle}>*/}
            {/*            <label className={classname.blueColor}> {t(DICTIONARY.REFUSAL_TO_RECEIVE)}</label>*/}
            {/*            {t(DICTIONARY.REFUSAL_TO_RECEIVE_SUB)}<br/><br/>*/}
            {/*            <label className={classname.blueColor}> {t(DICTIONARY.EXPIRED_SHELF)}</label>*/}
            {/*            {t(DICTIONARY.EXPIRED_SHELF_SUB)}<br/><br/>*/}
            {/*        </Typography>*/}
            {/*    </AccordionDetails>*/}
            {/*</Accordion>*/}
        </div>
    );
}

