export const politicaText: Array<any> = [
	{
		title: `Політика конфіденційності`,
		message: `Товариство з обмеженою відповідальністю «ДЖАСТ ІН» (далі — «ДЖАСТ ІН») дотримується принципів конфіденційності й недоторканості інформації, отриманої від клієнтів ДЖАСТ ІН в рамках надання поштово-логістичних та інших послуг, що надаються нашою компанією (далі – «Послуги»).

	ДЖАСТ ІН гарантує збереження інформації з обмеженим доступом та захист персональних даних користувачів Послуг (далі – Користувачі), у т.ч. при використанні офіційного сайту ДЖАСТ ІН, мобільних додатків, прикладних програмних інтерфейсів та застосувань, платежів та переказів за платіжними системами тощо (далі – Послуги).
	
	Політика конфіденційності (далі – Політика) націлена на публічне інформування Користувачів про порядок збору (обробки), зберігання, передачі персональних даних та інформації, що надається в рамках надання Послуг ДЖАСТ ІН.
	
	Використовуючи Послуги ДЖАСТ ІН, Користувач погоджується з усіма правилами та умовами Політики.
	
	Перед початком використання Послуг просимо ознайомитися з Політикою.`,
	},
	{
		title: `Мета обробки персональних даних`,
		message: `Метою обробки персональних даних є надання Послуг.`,
	},

	{
		title: `Збір персональних даних`,
		message: `Персональні дані – відомості чи сукупність відомостей про Користувача, який ідентифікований або може бути конкретно ідентифікований.

Неперсоніфікована інформація — інформація, яка не прив’язана до конкретного Користувача Послуг.

Персональні дані, які обробляє ДЖАСТ ІН, охоплюють контактні дані, які надаються Користувачами при використанні Послуг (прізвище, ім’я, по батькові, номер телефону, адреса електронної пошти тощо); яка використовується для оплати товарів та Послуг ДЖАСТ ІН; номери та історію відправлень тощо. Інформація отримується при використанні Користувачем Послуг, у т.ч. за допомогою сайту ДЖАСТ ІН, мобільних додатків тощо. Дані передаються Користувачами добровільно з метою забезпечення можливості виконання ДЖАСТ ІН зобов’язань за Послугами.

Неперсоніфікована інформація використовується виключно для формування статистичних даних та з метою покращення Послуг, у т.ч. інформування Користувача про нові Послуги. Зокрема у разі використання мобільного додатку, – версія операційної системи, приблизне місцезнаходження мобільного пристрою у разі самостійного надання згоди Користувачем на використання гео-локації, IP-адреса пристрою, дата та час авторизації у мобільному застосунку.`,
	},

	{
		title: `Використання інформації`,
		message: `Інформація, яку отримує ДЖАСТ ІН використовується для:

створення облікового запису Користувачем;
надання Послуги або функції, яку замовив Користувач;
зв’язку з Користувачами з метою інформування про персоналізовані унікальні пропозиції та акції ДЖАСТ ІН;
оцінки та аналізу ринку, клієнтів, продуктів та сервісів, які надаються ДЖАСТ ІН;
ефективного клієнтського обслуговування;
забезпечення оновлення і технічної підтримки Послуг, у т.ч. мобільного застосунку на пристрої Користувача;
будь-яких інших цілей в обсязі, достатньому для якісного надання Послуг.`,
	},

	{
		title: `Передача інформації третім особам`,
		message: `ДЖАСТ ІН передає інформацію отриману від Користувачів в рамках надання Послуг третім особам з метою належного виконання своїх обов’язків за цими Послугами.

Поширення ДЖАСТ ІН персональних даних без згоди Користувача або уповноваженої ним особи дозволяється у випадках, визначених законом, і лише (якщо це необхідно) в інтересах національної безпеки, економічного добробуту та прав людини.`,
	},

	{
		title: `Захист конфіденційної інформації та інформації про Користувача`,
		message: `Конфіденційна інформація, персональні дані та неперсоніфікована інформація зберігаються на внутрішніх ресурсах ДЖАСТ ІН, доступ до яких обмежується згідно політик інформаційної безпеки та положень Політики інформаційної безпеки ТОВ «ДЖАСТ ІН».`,
	},

	{
		title: `Безпека`,
		message: `На веб-ресурсах ДЖАСТ ІН вжиті достатні заходи для захисту інформації, проте просимо прийняти до уваги, що абсолютно безпечного та безпомилкового шляху передачі даних мережею Інтернет не існує.

ДЖАСТ ІН не несе відповідальності за функціонування серверів, постачальників послуги доступу до мережі Інтернет, роботу третіх осіб, з вини яких може бути неможливий чи обмежений доступ до сайту, можливий витік інформації з вини третіх осіб, а також за розголошення Користувачем своїх персональних даних, у т.ч. даних авторизації на сайті ДЖАСТ ІН, у мобільних додатках, прикладних програмних інтерфейсах та застосуваннях.

Інформація, що розміщена на веб-ресурсах ДЖАСТ ІН, охороняється діючим законодавством, яке регулює право інтелектуальної власності. Тиражування, переклад, запис в архіві, копіювання на інші електронні носії інформації, яка розміщена, заборонена.`,
	},

	{
		title: `Права та обов’язки Користувача`,
		message: `Користувач має право на отримання інформації про порядок обробки своїх персональних даних, а також інші права, визначені частиною 2 статті 8 Закону України «Про захист персональних даних» від 01.06.2010 № 2297-VI (зі змінами) (далі – Закон).

Користувач зобов’язаній, зокрема але не обмежуючись:

 не вчиняти дії, спрямовані на введення інших Користувачів в оману;
 не надавати в користування свої персональні дані третім особам;
 не здійснювати збирання, систематизацію, зберігання, обробку або поширення персональної інформації інших Користувачів;
 не намагатися одержати доступ до персональних даних інших Користувачів будь-яким способом, включаючи, але не обмежуючись, шляхом обману, зловживання довірою, підбору даних аутентифікації та ідентифікації.
Якщо Користувач реєструється на офіційному сайті ДЖАСТ ІН, у мобільних додатках, прикладних програмних інтерфейсах та застосуваннях від імені та в інтересах третіх осіб, то надаючи дану згоду Користувач підтверджує те, що має достатній обсяг повноваження для вчинення таких дій, в тому числі – щодо надання від їх імені згоди на обробку персональних даних на викладених вище умовах, як їх представник (батько, мати, опікун, та інші, передбачені чинним законодавством, особи).`,
	},

	{
		title: `Згода Користувача`,
		message: `Відповідно до Закону, з метою отримання Послуг Користувачі надають згоду на обробку персональних даних за Послугами ДЖАСТ ІН.

Реєструючись на офіційному сайті ДЖАСТ ІН, у мобільних додатках, прикладних програмних інтерфейсах та застосуваннях, Користувач підтверджує те, що ознайомлений з умовами цієї Політики конфіденційності, зокрема і з метою обробки персональних даних, зазначених у цій Політиці, і правами, наданими суб’єктам персональних даних згідно зі ст. 8 Закону, та використання персональних даних ДЖАСТ ІН, шляхом проставлення галочки у графі «Погоджуюсь з Політикою конфіденційності».`,
	},

	{
		title: `Файли cookie`,
		message: `Cookie — це невеликі текстові файли, які зберігаються на пристроях Користувача під час відвідування сайту ДЖАСТ ІН. На сайті ДЖАСТ ІН можуть бути використані файли cookie, щоб зрозуміти, який вміст вас цікавить, і запам’ятати вас, якщо ви знову завітаєте на сайт. ДЖАСТ ІН зберігає файли cookie, щоб забезпечити технічно бездоганне і оптимізоване надання Послуг.

Файли cookie, які можуть бути використані ДЖАСТ ІН, не можуть завдати шкоди пристроям Користувача. Більшість файлів cookie, які можуть бути використані ДЖАСТ ІН, є так званими сеансовими файлами cookie, які автоматично видаляються в кінці відвідування Користувачем веб-сайту ДЖАСТ ІН. Інші файли cookie залишаються на пристроях Користувача до тих пір, поки він їх не видалить. Ці файли cookie дозволяють розпізнавати браузер Користувача при наступному відвідуванні.`,
	},

	{
		title: `Додаткова інформація`,
		message: `ДЖАСТ ІН залишає за собою право змінювати Політику конфіденційності.

У випадку виникнення запитань щодо Політики конфіденційності, надсилайте лист на електронну пошту office@justin.ua.`,
	},
];
