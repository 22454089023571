import React, { FC, Fragment, useContext } from "react";
import { LoginForm } from "../../forms/login.form/login.form";

import { createStyles, Theme, makeStyles, Grid } from "@material-ui/core";
import { AreaForm } from "./area.form";
import { ContextStore } from "../../../store";

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			display: "flex",
			justifyContent: "center",
		},
		wrapper: {
			display: "flex",
			justifyContent: "center",
		},
	})
);

export const Area: FC = () => {
	const classes = useStyles();
	const { state } = useContext(ContextStore);

	return (
		<Fragment>
			<Grid container spacing={0} className={classes.root}>
				<Grid item xs={12} className={classes.wrapper}>
					{!state.email ? <LoginForm /> : <AreaForm />}
				</Grid>
			</Grid>
		</Fragment>
	);
};
