import React, { FC, Fragment } from "react";
import ApiSelect from "./select/select";
import AccordionApiDocumentation from "./select/accordeon/accordeon";


export const ApiDocumentation: FC = () => {
    return (
        <Fragment>
            <ApiSelect/>
            <AccordionApiDocumentation/>
        </Fragment>
    );
};

