import React, {FC, Fragment} from "react";
import {
    createStyles,
    makeStyles,
    Theme,
    Button,
} from "@material-ui/core";
import {Grid} from "@material-ui/core";
import {DICTIONARY} from "../../../enums/dictionary";
import {useTranslation} from "react-i18next";
import JustinLocationImg from "../../../images/justin-location.svg"

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: "100%",
            display: "flex",
            background: "#104EFF",
            color: "white",
            borderRadius: "10px",
            padding: "45px 10px 40px 30px",
            marginLeft: "20px",
            "justify-content": "space-between",
            ["@media (max-width:812px)"]: {
                marginLeft: "0px",
                padding: "25px 10px 20px 10px",
            },
            "& h3": {
                fontSize: "32px",
                lineHeight: "38px",
                fontWeight: "700",
                fontFamily: "Helvetica",
                ["@media (max-width:812px)"]: {
                    fontSize: "24px",
                    lineHeight: "28px",
                },
            },
            "& p": {
                marginTop: "25px",
                lineHeight: "28px",
                ["@media (max-width:812px)"]: {
                    fontSize: "16px",
                    lineHeight: "18px",
                },
            }
        },
        wrapperInfo: {
            width: "50%",
            fontSize: "22px",
            lineHeight: "38px",
            "font-weight": "400",
            fontFamily: "Helvetica",
            ["@media (max-width:812px)"]: {
                width: "100%",
                display: "flex",
                "flex-direction": "column",
                "align-items": "center",
            }
        },
        btGo: {
            cursor: "pointer",
            background: "#FEF1F3",
            color: "#104EFF",
            padding: "20px 60px",
            borderRadius: "90px",
            marginTop: "30px",
            "&:hover": {
                background: "#ffdfce",
            },
            ["@media (max-width:812px)"]: {
                padding: "22px 110px",
                fontSize: "14px",
                lineHeight: "18px",
            },
        },
        mobileImg: {
            display: "none",
            ["@media (max-width:812px)"]: {
                display: "block",
                width: "120px",
            },
        },
        desktopImg: {
            ["@media (max-width:1300px)"]: {
                width: "200px",
            },
            ["@media (max-width:812px)"]: {
                display: "none",
            },
        },
        wrapperMobileImg: {
            display: "flex",
        }

    })
);


export const JustinRandom: FC = () => {
    const {t} = useTranslation();
    const classname = useStyles();
    return (
        <Fragment>
            <Grid className={classname.root} container spacing={1}>
                <Grid className={classname.wrapperInfo}>
                    <h3>{t(DICTIONARY.JUSTIN_RANDOM_TITLE)}</h3>
                    <div className={classname.wrapperMobileImg}>
                        <div>
                            <p>{t(DICTIONARY.JUSTIN_RANDOM_BODY)}</p>
                            <p>{t(DICTIONARY.JUSTIN_RANDOM_BODY_SEARCH)}</p>
                        </div>
                        <img className={classname.mobileImg} src={JustinLocationImg}
                             alt={JustinLocationImg.toString()}/>
                    </div>
                    <a href="https://justin.ua/" target="_black">
                        <button  className={classname.btGo}>
                            {t(DICTIONARY.BT_GO_TO)}
                        </button>
                    </a>
                </Grid>
                <img className={classname.desktopImg} src={JustinLocationImg} alt={JustinLocationImg.toString()}/>
            </Grid>
        </Fragment>
    );
};
