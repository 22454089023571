import React, {FC, Fragment} from "react";
import {
    createStyles,
    makeStyles,
    Theme,
} from "@material-ui/core";
import {Grid} from "@material-ui/core";
import {DICTIONARY} from "../../../enums/dictionary";
import {useTranslation} from "react-i18next";
import StepIntegrationImg from "../../../images/step-integration.svg";

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: "100%",
            display: "flex",
            // background: "#104EFF",
            color: "white",
            "justify-content": "space-between",
            "& h3": {
                fontSize: "32px",
                lineHeight: "38px",
                fontWeight: "700",
                fontFamily: "Helvetica",
                ["@media (max-width:812px)"]: {
                    fontSize: "24px !important",
                    lineHeight: "28px !important",
                }
            },
            "& p": {
                marginTop: "25px",
                lineHeight: "28px",
                ["@media (max-width:812px)"]: {
                    fontSize: "16px",
                }
            }
        },
        wrapperInfo: {
            width: "50%",
            fontSize: "20px",
            lineHeight: "36px",
            "font-weight": "400",
            fontFamily: "Helvetica",
            ["@media (max-width:812px)"]: {
                width: "100%",
            }
        },
        steps: {
            ["@media (max-width:1400px)"]: {
                width: "500px",
            },
            ["@media (max-width:1100px)"]: {
                width: "355px",
            },
            ["@media (max-width:812px)"]: {
                display: "none"
            }
        }
    })
);


export const BenefitsIntegration: FC = () => {
    const {t} = useTranslation();
    const classname = useStyles();
    const arrayBenefits = [
        {
            header: t(DICTIONARY.COOL_BUSINESS_TITLE),
            textInfo: t(DICTIONARY.COOL_BUSINESS_BODY),
        },
        {
            header: t(DICTIONARY.CLIENT_BENEFITS_TITLE),
            textInfo: t(DICTIONARY.CLIENT_BENEFITS_BODY),
        }
    ];
    return (
        <Fragment>
            <Grid className={classname.root} container spacing={1}>
                <Grid className={classname.wrapperInfo}>
                    <h3>{t(DICTIONARY.BENEFITS_INTEGRATION)}</h3>
                    {arrayBenefits.map((item, index) => {
                        return <div key={index.toString() + item}>
                            <p>{item.header}</p>
                            <p>{item.textInfo}</p>
                        </div>
                    })}
                </Grid>
                <img className={classname.steps} src={StepIntegrationImg} alt={StepIntegrationImg.toString()}/>
            </Grid>
        </Fragment>
    );
};
