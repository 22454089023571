import React, { FC, Fragment, useContext, useState } from "react";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import {
	createStyles,
	Theme,
	makeStyles,
	Grid,
	Button,
	TextField,
	Typography,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { useFormik } from "formik";
import { ISnackBarData } from "../../../interfaces/interfaces";
import { TYPE_MESSAGE } from "../../../enums/dictionary";
import { ACTION_TYPES } from "../../../reduser";
import { addingNewsValidationSchema as validationSchema } from "./admin.adding.news.validation";
import { ContextStore } from "../../../store";
import DropzoneAreaExample from "../../forms/feedback.form/dropzone";
import TextEditor from "../../text.editor/text.editor";

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {},
		formStyle: {
			marginTop: theme.spacing(2),
		},
		btnStyle: {
			textTransform: "none",
			marginRight: theme.spacing(2),
		},
		btnWrapper: {
			display: "flex",
			justifyContent: "center",
		},
		labelStyle: {
			marginTop: theme.spacing(1),
			marginBottom: theme.spacing(1),
		},
	})
);

export const AdminAddingNews: FC = () => {
	const classes = useStyles();
	const history = useHistory();
	const { dispatch } = useContext(ContextStore);
	const [contentUA, setContentUA] = useState<any>("UA");
	const [contentRU, setContentRU] = useState<any>("RU");
	const [contentENG, setContentENG] = useState<any>("ENG");

	const handleEditorChange = (
		contentUA: any
		// contentRU: any,
		// contentENG: any
	) => {
		setContentUA(contentUA);
		// setContentRU(contentRU);
		// setContentENG(contentENG);
	};

	const handleBackTo = () => {
		history.push("/admin/listnews");
	};

	const {
		handleSubmit,
		handleChange,
		resetForm,
		values,
		errors,
		touched,
		handleBlur,
	} = useFormik({
		initialValues: {
			titleUA: "",
			titleRU: "",
			titleENG: "",
			contentUA: "",
			contentRU: "",
			contentENG: "",
			startDate: "",
			endDate: "",
		},
		onSubmit: ({
			titleUA,
			titleRU,
			titleENG,
			contentUA,
			contentRU,
			contentENG,
			startDate,
			endDate,
		}) => {
			console.log("values", values);
			resetForm();
			const snackData: ISnackBarData = {
				code: "200",
				message: "Новина успішно збережена",
				typeMessage: TYPE_MESSAGE.SUCCESS,
			};
			dispatch({
				type: ACTION_TYPES.SET_SNACK_BAR_DATA,
				payload: snackData,
			});
		},
		validationSchema,
	});

	return (
		<Fragment>
			<Grid container spacing={0} className={classes.root}>
				<Grid item xs={12}>
					<Button
						variant="outlined"
						size="small"
						onClick={handleBackTo}
					>
						<ArrowBackIcon />
					</Button>
					<form onSubmit={handleSubmit} className={classes.formStyle}>
						<Grid container spacing={2}>
							<Grid item xs={12}>
								<TextField
									size="small"
									value={values.titleUA}
									onChange={handleChange}
									id="titleUA"
									name="titleUA"
									error={
										!!(errors.titleUA && touched.titleUA)
									}
									helperText={errors.titleUA}
									onBlur={handleBlur}
									color="primary"
									fullWidth
									label="Заголовок UA"
									variant="outlined"
								/>
							</Grid>
							<Grid item xs={12}>
								<TextField
									size="small"
									value={values.titleRU}
									onChange={handleChange}
									id="titleRU"
									name="titleRU"
									error={
										!!(errors.titleRU && touched.titleRU)
									}
									helperText={errors.titleRU}
									onBlur={handleBlur}
									color="primary"
									fullWidth
									label="Заголовок RU"
									variant="outlined"
								/>
							</Grid>
							<Grid item xs={12}>
								<TextField
									size="small"
									value={values.titleENG}
									onChange={handleChange}
									id="titleENG"
									name="titleENG"
									error={
										!!(errors.titleENG && touched.titleENG)
									}
									helperText={errors.titleENG}
									onBlur={handleBlur}
									color="primary"
									fullWidth
									label="Заголовок ENG"
									variant="outlined"
								/>
							</Grid>
							<Grid item xs={12}>
								<Typography className={classes.labelStyle}>
									Детальний опис UA
								</Typography>
								<TextEditor
									id="ContentUA"
									required
									content={contentUA}
									// value={content}
									onEditorChange={handleEditorChange}
								/>
								<Typography className={classes.labelStyle}>
									Детальний опис RU
								</Typography>
								<TextEditor
									id="ContentRU"
									required
									content={contentRU}
									// value={content}
									onEditorChange={handleEditorChange}
								/>
								<Typography className={classes.labelStyle}>
									Детальний опис ENG
								</Typography>
								<TextEditor
									id="ContentENG"
									required
									content={contentENG}
									// value={content}
									onEditorChange={handleEditorChange}
								/>
							</Grid>

							<Grid item xs={12} md={6}>
								<TextField
									size="small"
									value={values.startDate}
									onChange={handleChange}
									id="startDate"
									name="startDate"
									error={
										!!(
											errors.startDate &&
											touched.startDate
										)
									}
									helperText={errors.startDate}
									onBlur={handleBlur}
									color="primary"
									fullWidth
									label="Дата початку"
									variant="outlined"
								/>
							</Grid>
							<Grid item xs={12} md={6}>
								<TextField
									size="small"
									value={values.endDate}
									onChange={handleChange}
									name="endDate"
									id="endDate"
									error={
										!!(errors.endDate && touched.endDate)
									}
									helperText={errors.endDate}
									onBlur={handleBlur}
									color="primary"
									fullWidth
									label="Дата закінчення"
									variant="outlined"
								></TextField>
							</Grid>

							<Grid item xs={12}>
								<Typography>Завантажте зображення</Typography>
							</Grid>
							<Grid item xs={12}>
								<DropzoneAreaExample />
							</Grid>

							<Grid item xs={12} className={classes.btnWrapper}>
								<Button
									className={classes.btnStyle}
									size="small"
									type="submit"
									variant="contained"
								>
									Зберегти
								</Button>
								<Button
									className={classes.btnStyle}
									size="small"
									variant="contained"
									color="primary"
								>
									Зберегти та опублікувати
								</Button>
								<Button
									className={classes.btnStyle}
									size="small"
									variant="contained"
								>
									Скасувати
								</Button>
							</Grid>
						</Grid>
					</form>
				</Grid>
			</Grid>
		</Fragment>
	);
};
