import * as Yup from "yup";

export const updateSingleNewsValidationSchema = Yup.object().shape({
	titleUA: Yup.string()
		.required("Обов'язкове для заповнення")
		.min(10, "Закоротка назва"),
	titleRU: Yup.string()
		.required("Обов'язкове для заповнення")
		.min(10, "Закоротка назва"),
	titleENG: Yup.string()
		.required("Обов'язкове для заповнення")
		.min(10, "Закоротка назва"),
	contentUA: Yup.string().required("Обов'язкове для заповнення"),
	contentRU: Yup.string().required("Обов'язкове для заповнення"),
	contentENG: Yup.string().required("Обов'язкове для заповнення"),
	startDate: Yup.string().required("Обов'язкове для заповнення"),
	endDate: Yup.string().required("Обов'язкове для заповнення"),
});
