import React, {Component, Fragment} from "react";
import {Dispatch} from "redux";
import {connect} from 'react-redux'
import {
    createStyles,
    withStyles,
    Button,
} from "@material-ui/core";
import {Grid} from "@material-ui/core";
import CloseImg from "../../../images/close.svg";
import IntersectImg from "../../../images/intersect.svg";
import {DICTIONARY} from "../../../enums/dictionary";
import {IInitialStateStore} from "../../../redux/types/type";
import {HANDLE_MODAL_SUPPORT} from "../../../redux/actions/actionTypes";
import {withTranslation, WithTranslation} from 'react-i18next';
import ReCAPTCHA from "react-google-recaptcha";
import {Link} from "react-router-dom";

export const useStyles = () =>
    createStyles({
            modal: {
                backgroundColor: "white",
                display: "flex",
                "justify-content": "center",
                "align-items": "center",
                borderRadius: "10px",
                "& h3":
                    {
                        fontSize: "32px",
                        lineHeight: "41px",
                        fontWeight: "700",
                        fontFamily: "Helvetica",
                    }
                ,
                "& p":
                    {
                        marginTop: "25px",
                        lineHeight: "28px",
                    }
            },
            container: {
                position: "relative",
                background: "white",
                maxWidth: "700px",
                overflow: "auto",
                color: "#104EFF",
                borderRadius: "20px",
                ["@media (max-width:812px)"]: {
                    // height: "800px",
                    width: "100%",
                    marginTop: "0px",
                },
            },
            wrapperFrontForm: {
                position: "relative",
                margin: "25px 0",
                borderRadius: "20px",
            }
            ,
            intersect: {
                position: "absolute",
                bottom: 0,
                right: 0,
            }
            ,
            body: {
                padding: "60px 0 0 30px",
                fontSize: "30px",
                lineHeight: "37px",
                "font-weight": "700",
                fontFamily: "Helvetica",
                "& <:nth-chlid(1)": {
                    marginBottom: "50px"
                },
                "& div": {
                    display: "flex",
                    "& img": {
                        marginRight: "20px",
                    }
                }
            }
            ,
            support: {
                marginBottom: "30px",
            },
            header: {
                display: "flex",
                "align-items": "flex-end",
                "justify-content": "space-between",
                fontSize: "36px",
                lineHeight: "41px",
                "font-weight": "700",
                fontFamily: "Helvetica",
                padding: "0px 20px 0 35px",
                color: "#104EFF",
                marginBottom: "20px",
                "& img": {
                    cursor: "pointer",
                }
            }
            ,
            wrapperForm: {
                display: "flex",
                "flex-direction": "column",
                padding: "0px 35px 65px 35px",
                "& span": {
                    fontSize: "18px",
                    color: "black",
                    margin: "20px 0",
                },
                "& input, select": {
                    paddingLeft: "10px",
                    height: "40px",
                    border: "1px solid black",
                    borderRadius: "7px",
                },
                "& :nth-child(1)": {
                    marginTop: "0px",
                },
                "& textarea": {
                    minHeight: "100px",
                }

            },
            btnStyle: {
                "z-index": "1",
                padding: "20px 0",
                textTransform: "none",
                "& span": {
                    color: "white",
                    margin: "0",
                },

            },
            wrapperCheck: {
                display: "flex",
                "align-items": "center",
                margin: "30px 0 50px 0",
                "& span": {
                    margin: "0",
                },
                "& input": {
                    width: "34px",
                    marginRight: "15px",
                },
                "& a": {
                    fontSize: "24px",
                    marginLeft: "10px",
                }
            },
            headerBox: {
                display: "flex",
                "align-items": "flex-end",
                color: "#104EFF",
                "& label": {
                    fontSize: "22px",
                    lineHeight: "28px",
                    "font-weight": "300",
                    marginRight: "10px",
                },
                "& img": {
                    width: "50px",
                }

            },
            error: {
                color: "red !important",
                margin: "0!important",
            },
            errorValid: {
                color: "red !important",
            },
            wrapperCaptcha: {
                margin: "40px 0",
            },
            errorValidInput: {
                border: "solid 1px red !important"
            },

        }
    )
;

export interface ISub {
    "imgSub": string,
    "text": string,
    "linkImg"?: string
}

// interface IProps extends WithTranslation {
//     prop: any;
// }

interface IProps extends WithTranslation {
    t: any
    classes?: any
    handleModalSupport: any
    isModalSupport: boolean
}

interface IState {
}

class ModalComponent extends Component<IProps, IState> {
    state = {
        category: "",
        code: "",
        keyAccount: "",
        firstName: "",
        emailAddresses: "",
        messageHeader: "",
        message: "",
        isCode: false,
        isKeyAccount: false,
        isFirstName: false,
        isEmailAddresses: false,
        isMessageHeader: false,
        isMessage: false,
        isAllRight: false,
        isValid: true,
    };

    clearFields() {
        this.setState({
            code: "",
            keyAccount: "",
            firstName: "",
            emailAddresses: "",
            messageHeader: "",
            message: "",
        });

    }

    setValid(key: string, value: boolean) {
        return this.setState({
            [key]: !value
        });
    }

    onSubmit = (data: any) => {

        const target = data.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        switch (name) {
            case "keyAccount": {
                this.setValid("isKeyAccount", (!!value));
                break;
            }
            case "firstName": {
                this.setValid("isFirstName", (!!value));
                break;
            }
            case "emailAddresses": {
                this.setValid("isEmailAddresses", (!!value));
                break;
            }
            case "messageHeader": {
                this.setValid("isMessageHeader", (!!value));
                break;
            }
            case "message": {
                this.setValid("isMessage", (!!value));
                break;
            }
        }

        this.setState({
            [name]: value
        });
    };

    validForm = () => {
        if (this.state.message && this.state.keyAccount &&
            this.state.firstName && this.state.emailAddresses && this.state.messageHeader) {
            this.setState({isValid: true});
            this.clearFields();
            alert("Request");
            // this.props.handleModalSupport();
        } else {
            this.setState({
                isValid: false,
                isKeyAccount: !this.state.keyAccount,
                isFirstName: !this.state.firstName,
                isEmailAddresses: !this.state.emailAddresses,
                isMessageHeader: !this.state.messageHeader,
                isMessage: !this.state.message,
            });
            return
        }


    };

    onChange = (value: any) => {
        this.setState({
            isAllRight: !!value
        });

    };

    render() {
        const {t, classes, handleModalSupport, isModalSupport} = this.props;
        return (
            <Fragment>
                <Grid className={classes.modal} container spacing={1}>
                    {/*test {this.props.isModalContacts}*/}
                    <div className={classes.wrapperFrontForm}>
                        <div className={classes.header}>
                            {t(DICTIONARY.SUBMIT_APPLICATION)}
                            <div className={classes.headerBox}>
                                <label>{t(DICTIONARY.REQUIRED_FIELDS)}</label>
                                {/*<Link to="support">*/}
                                {/*    <img src={CloseImg} alt="close"/>*/}
                                {/*</Link>*/}
                            </div>
                        </div>
                        <div className={classes.container}>
                            <div className={classes.wrapperForm}>
                                <span>{t(DICTIONARY.CATEGORY_YOUR_QUESTION)}</span>
                                <select name={this.state.category}>
                                    <option
                                        value={t(DICTIONARY.CATEGORY_YOUR_QUESTION_SUB_1)}>{t(DICTIONARY.CATEGORY_YOUR_QUESTION_SUB_1)}</option>
                                    <option
                                        value={t(DICTIONARY.CATEGORY_YOUR_QUESTION_SUB_2)}>{t(DICTIONARY.CATEGORY_YOUR_QUESTION_SUB_2)}</option>
                                    <option
                                        value={t(DICTIONARY.CATEGORY_YOUR_QUESTION_SUB_3)}>{t(DICTIONARY.CATEGORY_YOUR_QUESTION_SUB_3)}</option>
                                    <option
                                        value={t(DICTIONARY.CATEGORY_YOUR_QUESTION_SUB_4)}>{t(DICTIONARY.CATEGORY_YOUR_QUESTION_SUB_4)}</option>
                                    <option
                                        value={t(DICTIONARY.CATEGORY_YOUR_QUESTION_SUB_5)}>{t(DICTIONARY.CATEGORY_YOUR_QUESTION_SUB_5)}</option>
                                </select>
                                <span>
                                    {t(DICTIONARY.REGISTRATION_CODE)}
                                </span>
                                <input name="code"
                                       value={this.state.code}
                                       onChange={this.onSubmit}/>

                                <span
                                    className={this.state.isKeyAccount ? classes.errorValid : ""}>
                                    *{t(DICTIONARY.COMPANY_NAME_OR_KEY)}
                                </span>
                                <input className={this.state.isKeyAccount ? classes.errorValidInput : ""}
                                       name="keyAccount"
                                       value={this.state.keyAccount}
                                       onChange={this.onSubmit}/>

                                <span className={this.state.isFirstName ? classes.errorValid : ""}>
                                    *{t(DICTIONARY.YOUR_NAME)}
                                </span>
                                <input className={this.state.isFirstName ? classes.errorValidInput : ""}
                                       name="firstName"
                                       value={this.state.firstName}
                                       onChange={this.onSubmit}/>

                                <span className={this.state.isEmailAddresses ? classes.errorValid : ""}>
                                    *{t(DICTIONARY.YOUR_EMAIL)}
                                </span>
                                <input className={this.state.isEmailAddresses ? classes.errorValidInput : ""}
                                       name="emailAddresses"
                                       value={this.state.emailAddresses}
                                       onChange={this.onSubmit}/>

                                <span className={this.state.isMessageHeader ? classes.errorValid : ""}>
                                    *{t(DICTIONARY.MESSAGE_HEADER)}
                                </span>
                                <input className={this.state.isMessageHeader ? classes.errorValidInput : ""}
                                       name="messageHeader"
                                       value={this.state.messageHeader}
                                       onChange={this.onSubmit}/>

                                <span className={this.state.isMessage ? classes.errorValid : ""}>
                                    *{t(DICTIONARY.MESSAGE)}
                                </span>
                                <textarea
                                    className={this.state.isMessage ? classes.errorValidInput : ""}
                                    name="message"
                                    value={this.state.message}
                                    onChange={this.onSubmit}/>

                                <div className={classes.wrapperCaptcha}>
                                    <ReCAPTCHA
                                        sitekey="6LccqrsZAAAAAAUOuIOSM-aoAJK73RmVAHzQSiO-"
                                        onChange={this.onChange}
                                    />
                                </div>
                                {/*<div className={classes.wrapperCheck}>*/}
                                {/*    <input type="checkbox" onChange={this.onSubmit} name="isAllRight"/>*/}
                                {/*    <span>{t(DICTIONARY.I_AGREE_WITH)}</span>*/}
                                {/*    <a href="/integration">{t(DICTIONARY.TERMS_OF_AGREEMENT)}</a>*/}
                                {/*    <p> {this.state.isAllRight}             </p>*/}
                                {/*</div>*/}
                                {!this.state.isValid &&
                                <span className={classes.error}>{t(DICTIONARY.ERROR_REQUIRED)}</span>}
                                <Button
                                    type="submit"
                                    className={classes.btnStyle}
                                    variant="contained"
                                    color="primary"
                                    disabled={!this.state.isAllRight}
                                    onClick={this.validForm}>
                                    {t(DICTIONARY.SEND_MESSAGE)}
                                </Button>
                            </div>
                        </div>

                        {/*<img className={classes.intersect} src={IntersectImg} alt="backgroundImg"/>*/}
                    </div>
                </Grid>
            </Fragment>
        )
    }
}


const mapStateToProps = (state: IInitialStateStore) => ({isModalSupport: state.isModalSupport});

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        handleModalSupport: () => dispatch({type: HANDLE_MODAL_SUPPORT})
    }
};

const ModalConnect = connect(mapStateToProps, mapDispatchToProps)(ModalComponent);
const ModalStyles = withStyles(useStyles)(ModalConnect);
export const ApplySupport = withTranslation()(ModalStyles);
// export const ModalContacts =  compose(withTranslation(),connect(mapStateToProps, mapDispatchToProps),withStyles(useStyles))(ModalComponent);
