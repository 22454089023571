import {FieldHookConfig, useField} from "formik";
import React from "react";
import {createStyles, IconButton, InputAdornment, TextField, Theme} from "@material-ui/core";
import {Visibility, VisibilityOff} from "@material-ui/icons";
import makeStyles from "@material-ui/core/styles/makeStyles";

export const useStylesTextFieldPlaceHolder = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            '& .MuiFormLabel-root': {
                fontSize: "1em",
            }
        },
    }));

type MyInputProps = {
    InputProps: any,
    label: string,
    variant: any,
    size: any
} & FieldHookConfig<{}>;
// .MuiFormLabel-root
type MyInputState = {
    showPassword: boolean
};

export const CastTextFieldPass: React.FC<MyInputProps> = (
    {InputProps, label, size, variant, placeholder, required, ...props}) => {
    const [field, meta] = useField<{}>(props);
    const [values, setValues] = React.useState<MyInputState>({
        showPassword: false,
    });
    const classes = useStylesTextFieldPlaceHolder();
    const handleClickShowPassword = () => {
        setValues({ ...values, showPassword: !values.showPassword });
    };

    let errorText = meta.error && meta.touched ? meta.error : '';
    return <TextField className={classes.root}
                    {...field}
                    placeholder={placeholder}
                    helperText={errorText}
                    label={label}
                    variant={variant}
                    size={size}
                    error={!!errorText}
                    required={required}
                    type={values.showPassword ? 'text' : 'password'}
                    InputProps={{
                          endAdornment:
                              <InputAdornment position="end">
                                  <IconButton
                                      aria-label="toggle password visibility"
                                      onClick={handleClickShowPassword}
                                      edge="end"
                                  >
                                      {values.showPassword ? <Visibility /> : <VisibilityOff />}
                                  </IconButton>
                              </InputAdornment>
                    }}
    />;
};
