import React, { FC } from "react";
import { DropzoneArea } from "material-ui-dropzone";
import { DICTIONARY } from "../../../enums/dictionary";
import { useTranslation } from "react-i18next";

export const DropzoneAreaExample: FC = () => {
	const { t } = useTranslation();
	
	return (
		<DropzoneArea
			filesLimit={1}
			maxFileSize={2000000}
			acceptedFiles={["image/jpeg", "image/png", "image/jpg"]}
			dropzoneText={t(DICTIONARY.SELECT_FILE)}
		/>
	);
};

export default DropzoneAreaExample;
