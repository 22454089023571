import React, { FC } from "react";
import {
	createStyles,
	makeStyles,
	Theme,
	Accordion,
	AccordionSummary,
	Typography,
	AccordionDetails,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { termsArray } from "./terms.array.text";
import { DICTIONARY } from "../../../enums/dictionary";
import {useTranslation} from "react-i18next";

export const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			width: "100%",
		},
		titleStyle: {
			padding: theme.spacing(2),
		},
		messageStyle: {
			whiteSpace: "pre-line",
		},
		heading: {
			fontSize: theme.typography.pxToRem(15),
			fontWeight: theme.typography.fontWeightRegular,
		},
		secondaryHeading: {
			fontSize: theme.typography.pxToRem(15),
			color: theme.palette.text.secondary,
		},
	})
);

export const Terms: FC = () => {
	const classes = useStyles();
	const { t } = useTranslation();

	return (
		<div className={classes.root}>
			<h1 className={classes.titleStyle}>{t(DICTIONARY.USLOVIA)}</h1>
			{termsArray && termsArray.length > 0 ? (
				termsArray.map(({ title, message }, i) => {
					return (
						<Accordion key={i}>
							<AccordionSummary
								expandIcon={<ExpandMoreIcon />}
								aria-controls="panel1a-content"
								id="panel1a-header"
							>
								<Typography className={classes.heading}>
									{title}
								</Typography>
							</AccordionSummary>
							<AccordionDetails>
								<Typography className={classes.messageStyle}>
									{message}
								</Typography>
							</AccordionDetails>
						</Accordion>
					);
				})
			) : (
				<div>Nothing</div>
			)}
		</div>
	);
};
