import React, { FC, Fragment, useState } from "react";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { useHistory } from "react-router-dom";
import {
	createStyles,
	Theme,
	makeStyles,
	Grid,
	Button,
	TextField,
	Typography,
	ListItemText,
	List,
	ListItem,
	IconButton,
	Menu,
	MenuItem,
} from "@material-ui/core";
import { DICTIONARY } from "../../../enums/dictionary";
import { useTranslation } from "react-i18next";
import Pagination from "@material-ui/lab/Pagination";

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {},
		paginationStyle: {
			width: "100%",
			display: "grid",
			justifyContent: "center",
			textAlign: "center",
			marginTop: theme.spacing(3),
		},
		paginPageStyle: {
			paddingBottom: theme.spacing(1),
		},
		buttonStyle: {
			textTransform: "none",
			marginBottom: theme.spacing(2),
			marginRight: theme.spacing(10),
		},
		searchInputStyle: {
			width: "300px",
			marginRight: theme.spacing(2),
		},
		searchBarWrapper: {
			display: "flex",
			justifyContent: "center",
		},
		listStyle: {
			height: "100%",
		},
	})
);

//BURGER_MENU
const options = ["Редагувати", "Видалити", "Публікувати", "Зняти з публікації"];

const ITEM_HEIGHT = 48;

export const BurgerMenu = () => {
	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
	const open = Boolean(anchorEl);

	const handleClick = (event: React.MouseEvent<HTMLElement>) => {
		event.stopPropagation();
		setAnchorEl(event.currentTarget);
	};

	const handleClose = (event: React.MouseEvent<HTMLElement>) => {
		event.stopPropagation();
		setAnchorEl(null);
	};

	return (
		<div>
			<IconButton
				aria-label="more"
				aria-controls="long-menu"
				aria-haspopup="true"
				onClick={handleClick}
			>
				<MoreVertIcon />
			</IconButton>
			<Menu
				id="long-menu"
				anchorEl={anchorEl}
				keepMounted
				open={open}
				onClose={handleClose}
				PaperProps={{
					style: {
						maxHeight: ITEM_HEIGHT * 4.5,
						width: "20ch",
					},
				}}
			>
				{options.map((option) => (
					<MenuItem
						key={option}
						selected={option === "Pyxis"}
						onClick={handleClose}
					>
						{option}
					</MenuItem>
				))}
			</Menu>
		</div>
	);
};
//BURGER_MENU

const ListNews = [
	{
		id: 1,
		title: "Ut beatae quo perferendis quia nisi ullam ad.",
		published: "true",
	},
	{
		id: 2,
		title: "Ut beatae quo perferendis quia nisi ullam ad.",
		published: "false",
	},
	{
		id: 3,
		title: "Ut beatae quo perferendis quia nisi ullam ad.",
		published: "true",
	},
	{
		id: 4,
		title: "Ut beatae quo perferendis quia nisi ullam ad.",
		published: "true",
	},
	{
		id: 5,
		title: "Ut beatae quo perferendis quia nisi ullam ad.",
		published: "false",
	},
	{
		id: 6,
		title: "Ut beatae quo perferendis quia nisi ullam ad.",
		published: "true",
	},
];

export const AdminListNews: FC = () => {
	const classes = useStyles();
	const { t } = useTranslation();
	const [page, setPage] = useState<number>(1);
	const history = useHistory();

	const handleAddNews = () => {
		console.log("Add news");
		history.push("/admin/addingnews");
	};

	const handleChangePagination = (
		event: React.ChangeEvent<unknown>,
		value: number
	) => {
		setPage(value);
	};

	return (
		<Fragment>
			<Grid container spacing={0} className={classes.root}>
				<Grid item xs={12} className={classes.searchBarWrapper}>
					<Button
						className={classes.buttonStyle}
						variant="contained"
						color="primary"
						onClick={handleAddNews}
					>
						{t(DICTIONARY.ADD)}
					</Button>
					<TextField
						className={classes.searchInputStyle}
						variant="outlined"
						size="small"
					></TextField>
					<Button
						className={classes.buttonStyle}
						variant="contained"
						color="primary"
					>
						Пошук
					</Button>
				</Grid>

				<Grid item xs={12} className={classes.listStyle}>
					<List>
						<ListItem>
							<ListItemText
								style={{ width: "5%", textAlign: "center" }}
							>
								№
							</ListItemText>
							<ListItemText
								style={{ width: "70%", textAlign: "center" }}
							>
								Назва
							</ListItemText>
							<ListItemText
								style={{ width: "15%", textAlign: "right" }}
							>
								Опубліковано
							</ListItemText>
							<ListItemText
								style={{ width: "10%" }}
							></ListItemText>
						</ListItem>
						{ListNews && ListNews.length > 0 ? (
							ListNews.map(({ id, title, published }) => {
								return (
									<ListItem
										style={{ cursor: "pointer" }}
										key={id}
										onClick={() => {
											history.push("/admin/singlenews");
											console.log("ListItemID = ", id);
										}}
									>
										<ListItemText style={{ width: "5%" }}>
											{id}
										</ListItemText>
										<ListItemText
											style={{
												width: "70%",
												whiteSpace: "break-spaces",
											}}
										>
											{title}
										</ListItemText>
										<ListItemText
											style={{
												width: "15%",
												textAlign: "center",
											}}
										>
											{published === "true" ? (
												<CheckCircleIcon color="primary" />
											) : (
												""
											)}
										</ListItemText>
										<BurgerMenu />
									</ListItem>
								);
							})
						) : (
							<div></div>
						)}
					</List>
				</Grid>
				<Grid item xs={12} className={classes.paginationStyle}>
					<Typography className={classes.paginPageStyle}>
						{t(DICTIONARY.PAGINATION_PAGE)} {page}
					</Typography>
					<Pagination
						// count={lastPage}
						page={page}
						onChange={handleChangePagination}
					/>
				</Grid>
			</Grid>
		</Fragment>
	);
};
