import React, { useEffect } from "react";
import redactor from "./redactor";

let options: any = {
	editor: null,
	bookmark: null,
	checkerTimeout: null,
	isTyping: false,
	timeout: 300,
};

const TextEditor = (props: any) => {
	useEffect(() => {
		const submitNewContent = () => {
			options.isTyping = true;
			if (options.checkerTimeout !== null) {
				clearTimeout(options.checkerTimeout);
			}

			options.checkerTimeout = setTimeout(() => {
				if (options.editor) {
					if (options.editor.selection) {
						options.bookmark = options.editor.selection.getBookmark(
							2,
							true
						);
					}
					options.isTyping = false;
					props.onEditorChange(options.editor.getContent());
				}
			}, options.timeout);
		};

		const initEditor = (editorData: any) => {
			editorData.on("keyup change", () => {
				if (editorData.getContent() !== props.content) {
					submitNewContent();
				}
			});
			editorData.on("init", () => {
				editorData.setContent(props.content);
			});
			if (props.readonly) {
				editorData.setMode("readonly");
			} else {
				editorData.setMode("design");
			}
			editorData.setContent(props.content);
			options.editor = editorData;
		};
		if (options.editor !== null) {
			options.editor.setContent(props.content);
			if (options.editor.selection) {
				options.editor.selection.moveToBookmark(options.bookmark);
			}
		} else {
			redactor.init({
				selector: `#${props.id}`,
				height: "350",
				skin: false,
				language: "ru",
				readonly: false,
				value: "",
				plugins: "code",
				mode: "design",
				menubar: true,
				init_instance_callback: initEditor,
				toolbar:
					"formatselect  wordcount  advlist contextmenu  media | bold italic  strikethrough  forecolor backcolor | link | alignleft aligncenter alignright alignjustify  | numlist bullist outdent indent  | removeformat | code",
			});
		}
	}, [props]);

	useEffect(() => {
		return () => {
			redactor.destroy(props.id);
			options.editor = null;
			options.bookmark = null;
			options.checkerTimeout = null;
			options.isTyping = false;
		};
	}, [props.id]);

	return <textarea id={props.id} value={props.content} onChange={() => {}} />;
};

export default TextEditor;
