import React, {FC, Fragment} from "react";
import {
    createStyles,
    makeStyles,
    Theme,
} from "@material-ui/core";
import {Grid} from "@material-ui/core";
import {BenefitsIntegration} from "./BenefitsIntegration"
import {News} from "./News";
import {JustinRandom} from "./JustinRandom";

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        main: {
            width: "100%",
            display: "flex",
            background: "#104EFF",
            color: "white",
            borderRadius: "10px",
            padding: "20px 30px",

            "& h3": {
                fontSize: "36px",
                lineHeight: "41px",
                fontWeight: "700",
                fontFamily: "Helvetica",
            }
        },
        news: {
            paddingTop: "40px",
            display: "flex",
            ["@media (max-width:812px)"]: {
                flexDirection: "column",
            },
        }
    })
);


export const MainPage: FC = () => {
    const classname = useStyles();
    return (
        <Fragment>
            <Grid className={classname.main}>
                <BenefitsIntegration/>
            </Grid>
            <Grid className={classname.news}>
                <News/>
                <JustinRandom/>
            </Grid>
        </Fragment>
    );
};
