import React, {FC, Fragment} from "react";
import LOGO from '../../images/logo-light.svg';
import {makeStyles} from "@material-ui/core/styles";
import {Link} from "react-router-dom";

const useStyles = makeStyles(() => ({
    root: {},
    logoJustinStyle: {
        width: '120px',
        height: '77px',
        "&:hover": {
            width: '150px',
            height: '90px',
            border: "1px solid white",
            borderRadius: "30px",
            padding: "10px 20px"
        },
        ["@media (max-width:812px)"]: {
            width: '92px',
            height: '41px',
            paddingLeft: "15px",
        },
    }

}));

export const LogoJustin: FC = () => {
    const classes = useStyles();

    return (
        <Fragment>
            <Link to="/"> <img src={LOGO} className={classes.logoJustinStyle} alt="logo img"/></Link>
        </Fragment>

    )
};
