import * as actionTypes from "../actions/actionTypes";
import {IInitialStateStore, StoreAction} from "../types/type";

const initialState: IInitialStateStore = {
    isModalContacts: false,
    isModalIntegration: false,
    isModalSupport: false,
    isModalAuthorization: false,
    isModalRegistration: false,
    language: "ua",
    listMethodApi:[],
};

export default (state: IInitialStateStore = initialState, action: StoreAction) => {
    switch (action.type) {
        case actionTypes.HANDLE_MODAL_CONTACTS: {
            return {
                ...state,
                isModalContacts: !state.isModalContacts
            }
        }
        case actionTypes.HANDLE_MODAL_INTEGRATION: {
            return {
                ...state,
                isModalIntegration: !state.isModalIntegration
            }
        }
        case actionTypes.HANDLE_MODAL_SUPPORT: {
            return {
                ...state,
                isModalSupport: !state.isModalSupport
            }
        }
        case actionTypes.HANDLE_MODAL_AUTHORIZATION: {
            return {
                ...state,
                isModalAuthorization: !state.isModalAuthorization
            }
        }
        case actionTypes.HANDLE_MODAL_REGISTRATION: {
            return {
                ...state,
                isModalRegistration: !state.isModalRegistration
            }
        }
        case actionTypes.SET_LANGUAGE: {
            const language: any = action.payload;
            return {...state, language: language};
        }
        case actionTypes.SET_METHODS_API: {
            const listMethodApi: any = action.payload;
            return {...state, listMethodApi: listMethodApi};
        }
        default:
            return state
    }

}
