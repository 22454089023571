import React, { FC, useContext, Fragment } from "react";
import {
	createStyles,
	makeStyles,
	Theme,
	Grid,
	TextField,
	Button,
} from "@material-ui/core";
import { useFormik } from "formik";
import { DICTIONARY, TYPE_MESSAGE } from "../../../../enums/dictionary";
import { subscriptionValidationSchema as validationSchema } from "./subscription.validation";
import { ISnackBarData } from "../../../../interfaces/interfaces";
import { ACTION_TYPES } from "../../../../reduser";
import { ContextStore } from "../../../../store";
import { useTranslation } from "react-i18next";

export const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		conteiner: {
			marginTop: "15px",
			marginBottom: "15px",
			display: "flex",
			justifyContent: "center",
		},
		btnStyle: {
			marginLeft: theme.spacing(2),
			textTransform: "none",
			minWidth: "95px",
			[theme.breakpoints.up("xs")]: {
				maxHeight: "37px",
			},
		},
		formStyle: {
			width: "100%",
		},
		labelStyle: {
			alignItems: " center",
			paddingRight: theme.spacing(2),
			alignSelf: "center",
		},
		wrapper: {
			display: " flex",
			justifyContent: "center",
			[theme.breakpoints.up("sm")]: {
				height: "40px",
			},
		},
		inputStyle: {
			height: "0px",
			[theme.breakpoints.up("sm")]: {
				width: "250px",
			},
		},
		[theme.breakpoints.only("xs")]: {
			marginMin: {
				width: "90%",
				alignSelf: "center",
			},
		},
		labelContainer: {
			[theme.breakpoints.up("xs")]: {
				maxHeight: "37px",
				display: "flex",
			},
			[theme.breakpoints.down("xs")]: {
				justifyContent: "center",
			},
		},

		[theme.breakpoints.down("xs")]: {
			labelStyle: {
				paddingRight: theme.spacing(0),
			},
			marginMin: {
				padding: theme.spacing(1),
			},
			wrapper: {
				display: "flex",
				flexDirection: "column",
			},
			btnStyle: {
				width: "25%",
				alignSelf: "center",
			},
		},
	})
);

export const Subscription: FC = () => {
	const classes = useStyles();
	const { dispatch } = useContext(ContextStore);
	const { t } = useTranslation();

	const {
		handleSubmit,
		handleChange,
		resetForm,
		values,
		errors,
		touched,
		handleBlur,
	} = useFormik({
		initialValues: {
			email: "",
		},
		onSubmit: ({ email }) => {
			console.log("values", values);
			resetForm();

			const snackData: ISnackBarData = {
				code: "200",
				message: t(DICTIONARY.SUBSCRIPTION_REGISTERED),
				typeMessage: TYPE_MESSAGE.SUCCESS,
			};
			dispatch({
				type: ACTION_TYPES.SET_SNACK_BAR_DATA,
				payload: snackData,
			});
		},
		validationSchema,
	});

	return (
		<Fragment>
			<Grid container spacing={1} className={classes.conteiner}>
				<Grid item xs={12}>
					<form onSubmit={handleSubmit} className={classes.formStyle}>
						<Grid className={classes.wrapper}>
							<div className={classes.labelContainer}>
								<label className={classes.labelStyle}>
									{t(DICTIONARY.SUBSCRIPTION)}
								</label>
							</div>
							<TextField
								value={values.email}
								onChange={handleChange}
								id="email"
								name="email"
								error={!!(errors.email && touched.email)}
								helperText={errors.email}
								onBlur={handleBlur}
								className={classes.marginMin}
								variant="outlined"
								placeholder={t(DICTIONARY.ENTER_EMAIL)}
								inputProps={{
									className: classes.inputStyle,
								}}
							/>
							<Button
								type="submit"
								className={classes.btnStyle}
								variant="contained"
								color="primary"
								size="small"
							>
								{t(DICTIONARY.SEND)}
							</Button>
						</Grid>
					</form>
				</Grid>
			</Grid>
		</Fragment>
	);
};
