import React, {useEffect, useContext} from "react";
import "./styles/fonts.css";
import "./App.css";
import {
    createMuiTheme,
    createStyles,
    Grid,
    responsiveFontSizes,
    Theme,
    MuiThemeProvider,
    CssBaseline,
} from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Footer from "./new-components/footer/footer";
import NavTabsMenu from "./new-components/header/header";
import {Navigation} from "./new-components/navigation/Navigation";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Redirect,
} from "react-router-dom";
import {ProductDetail} from "./components/pages/product.detail.page/product.details";
import {MainPage} from "./new-components/pages/main/Main";
import StartIntegration from "./new-components/pages/integration/StartIntegration";
import {ProductsAPI} from "./components/pages/products.api.page/products.api";
import {ApiDocumentation} from "./components/pages/api.documentation.page/api.documentation.page";
import Support from "./new-components/pages/support/Support";
import {AllNews} from "./components/pages/all.news.page/all.news";
import {Area} from "./components/pages/area.page/area";
import {KredytniPoserednyky} from "./components/footer/kredytni.poserednyky/kredytni.poserednyky";
import {PoliticaKonfidentsialnosti} from "./components/footer/politica.konfidentsijnosti/politica.konfidentsijnosti";
import {Terms} from "./components/footer/terms/terms";
import {Registration} from "./components/forms/registration/registration";
import {ACTION_TYPES} from "./reduser";
import {ContextStore} from "./store";
import {NewsDetails} from "./components/pages/news.details.page/news.details";
import {CustomizedSnackbars} from "./components/snackbar/snackbar";
import {AdminListNews} from "./components/pages/admin.list.news/admin.list.news";
import {AdminUpdateSingleNews} from "./components/pages/admin.update.single.news/admin.update.single.news";
import {AdminAddingNews} from "./components/pages/admin.adding.news/admin.adding.news";
import {ModalContacts} from "./new-components/pages/main/ModalContacts";
import {ModalCustom} from "./new-components/customs/ModalCustom";
import {ModalSupport} from "./new-components/pages/support/ModalSupport";
import {AutorizationModal} from "./new-components/autorization/AutorizationModal";
import {NewsPage} from "./new-components/pages/newsPage/NewsPage";
import {PluginsResources} from "./new-components/pages/pluginsResources/PluginsResources";
import {InfoPlugins} from "./new-components/pages/pluginsResources/InfoPlugins";
import {SendMessage} from "./new-components/pages/support/MobileSendSupport";
import {RegistrationModal} from "./new-components/registration/registrationFunc";
import {ApiPage} from "./new-components/pages/apiPage/ApiPage";
import {ApplySupport} from "./new-components/pages/apply/ApllySupport";
import {GetKeyPage} from "./new-components/pages/integration/GetKeyPage";
import {useTranslation} from "react-i18next";

let theme = createMuiTheme({
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 960,
            lg: 1280,
            xl: 1920,
        },
    },
    palette: {
        primary: {
            main: "#104eff",
        },
        secondary: {
            main: "#2CD5C4",
            light: "#fcf2f3",
        },
        error: {
            main: "#F27B50",
        },
        warning: {
            main: "#104eff",
            light: "#f93e3e",
            dark: "#41444e",
        },
        info: {
            main: "#104eff",
            light: "#49cc90",
            dark: "#61affe",
        },
        success: {
            main: "#104eff",
        },
    },
    typography: {
        fontFamily: [`"Gilroy-Medium"`, `"Gilroy-Regular"`].join(","),
    },
});
theme = responsiveFontSizes(theme);

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
            width: "100%",
            height: "100%",
        },
        paper: {
            padding: theme.spacing(1),
            textAlign: "center",
            // color: theme.palette.text.secondary,
        },
        gridRootContainer: {
            width: "100%",
            height: "100%",
            flexDirection: "column",
            flexWrap: "nowrap",
        },
        headerWrap: {
            flexBasis: "0",
        },

        content: {
            boxSizing: "border-box",
            position: "relative",
            top: "0",
            width: "100%",
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            padding: "40px 60px",
            background: "#fcf2f3",
            ["@media (max-width:812px)"]: {
                padding: "15px",
            },
        },
        gridItem: {
            height: "100%",
        },
        footer: {
            boxSizing: "border-box",
            flex: "0 0 auto",
            width: "100%",
        },
    })
);

function App() {
    const classes = useStyles();
    const {i18n} = useTranslation();
    useEffect(() => {
        console.log("localStorage", localStorage.getItem("i18nextLng"));
        i18n.changeLanguage("ua");
    }, [])
    return (
        <Router>
            <MuiThemeProvider theme={theme}>
                <div className="App">
                    <CssBaseline/>
                    <div className={classes.root}>
                        <Grid
                            container
                            spacing={0}
                            className={classes.gridRootContainer}
                        >
                            <Grid item xs={12} className={classes.headerWrap}>
                                <NavTabsMenu/>
                            </Grid>
                            <Grid item xs={12} className={classes.headerWrap}>
                                {/*<Navigation/>*/}
                            </Grid>
                            <ModalContacts/>
                            <ModalCustom/>
                            <ModalSupport/>
                            <AutorizationModal/>
                            <RegistrationModal/>
                            <main className={classes.content}>
                                <Switch>
                                    <Route exact path="/">
                                        <Redirect
                                            exact
                                            from="/"
                                            to="/justin"
                                        />
                                    </Route>
                                    <Route
                                        exact
                                        path="/justin"
                                        component={MainPage}
                                    />
                                    <Route
                                        exact
                                        path="/plugins_resources"
                                        component={PluginsResources}
                                    />
                                    <Route
                                        exact
                                        path="/plugins_resources/:id"
                                        component={InfoPlugins}
                                    />
                                    <Route
                                        path="/integration"
                                        component={StartIntegration}
                                    />
                                    <Route
                                        path="/get_key"
                                        component={GetKeyPage}
                                    />
                                    <Route
                                        path="/products"
                                        component={ProductsAPI}
                                    />
                                    <Route
                                        path="/documentation"
                                        component={ApiDocumentation}
                                    />
                                    <Route
                                        path="/support"
                                        component={Support}
                                    />
                                    <Route
                                        path="/apply"
                                        component={ApplySupport}
                                    />
                                    <Route
                                        path="/send-message"
                                        component={SendMessage}
                                    />
                                    {/*<Route*/}
                                    {/*    path="/news"*/}
                                    {/*    component={NewsPage}*/}
                                    {/*/>*/}
                                    <Route
                                        path="/doc"
                                        component={ApiPage}
                                    />
                                    <Route path="/area" component={Area}/>
                                    <Route
                                        path="/productdetails"
                                        component={ProductDetail}
                                    />
                                    <Route
                                        path="/kredytniposerednyky"
                                        component={KredytniPoserednyky}
                                    />
                                    <Route
                                        path="/politicakonfidentsialnosti"
                                        component={
                                            PoliticaKonfidentsialnosti
                                        }
                                    />
                                    <Route
                                        path="/terms"
                                        component={Terms}
                                    />
                                    <Route
                                        path="/registration"
                                        component={Registration}
                                    />
                                    <Route
                                        path="/newsdetails"
                                        component={NewsDetails}
                                    />
                                    <Route
                                        path="api/auth/email/verify/:user/:hash"
                                        component={MainPage}
                                    />

                                    {/* Admin routing */}
                                    <Route
                                        path="/admin/listnews"
                                        component={AdminListNews}
                                    />
                                    <Route
                                        path="/admin/singlenews"
                                        component={AdminUpdateSingleNews}
                                    />
                                    <Route
                                        path="/admin/addingnews"
                                        component={AdminAddingNews}
                                    />
                                    <Redirect
                                        from="*"
                                        to="justin"
                                    />
                                </Switch>
                            </main>
                            {/*</Grid>*/}
                            <Grid item xs={12} className={classes.footer}>
                                <Footer/>
                            </Grid>
                        </Grid>
                    </div>
                </div>
                <CustomizedSnackbars/>
            </MuiThemeProvider>
        </Router>
    );
}

export default App;
