// Import TinyMCE

import tinymce from "tinymce/tinymce";

// A theme is also required
import "tinymce/themes/silver/theme.min";

// Any plugins you want to use has to be imported
import "tinymce/plugins/code";
import "tinymce/plugins/advlist";
import "tinymce/plugins/wordcount";
// import 'tinymce/plugins/autolink';
// import 'tinymce/plugins/autosave';
// import 'tinymce/plugins/charmap';
// import 'tinymce/plugins/codesample';
import "tinymce/plugins/contextmenu";
// import 'tinymce/plugins/emoticons';
// import 'tinymce/plugins/fullscreen';
// import 'tinymce/plugins/hr';
// import 'tinymce/plugins/imagetools';
// import 'tinymce/plugins/insertdatetime';
// import 'tinymce/plugins/link';
import "tinymce/plugins/media";
// import 'tinymce/plugins/noneditable';
// import 'tinymce/plugins/paste';
// import 'tinymce/plugins/print';
// import 'tinymce/plugins/searchreplace';
// import 'tinymce/plugins/tabfocus';
// import 'tinymce/plugins/template';
// import 'tinymce/plugins/textpattern';
// import 'tinymce/plugins/visualblocks';
// import 'tinymce/plugins/anchor';
// import 'tinymce/plugins/autoresize';
// import 'tinymce/plugins/bbcode';
// import 'tinymce/plugins/colorpicker';
// import 'tinymce/plugins/directionality';
// import 'tinymce/plugins/fullpage';
// import 'tinymce/plugins/help';
// import 'tinymce/plugins/image';
// import 'tinymce/plugins/importcss';
// import 'tinymce/plugins/legacyoutput';
// import 'tinymce/plugins/lists';
// import 'tinymce/plugins/nonbreaking';
// import 'tinymce/plugins/pagebreak';
// import 'tinymce/plugins/preview';
// import 'tinymce/plugins/save';
// import "tinymce/plugins/spellchecker";
// import "tinymce/plugins/table";
// import "tinymce/plugins/textcolor";
// import "tinymce/plugins/toc";
// import "tinymce/plugins/visualchars";

import "tinymce/skins/ui/oxide/skin.min.css";

import "../tinymce/langs/rus.js";

export default {
	init(options) {
		tinymce.init(options);
	},
	destroy(selector) {
		tinymce.remove(tinymce.editors[selector]);
	},
};
