import React from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { DICTIONARY } from "../../../../enums/dictionary";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		formControl: {
			margin: theme.spacing(1),
			// minWidth: 220,
			width: 500,
			[theme.breakpoints.down("sm")]: {
				width: "100%",
			},
		},
		selectEmpty: {
			marginTop: theme.spacing(2),
		},
		container: {
			display: "flex",
			justifyContent: "center",
			alignItems: "center",
			[theme.breakpoints.down("sm")]: {
				flexDirection: "column",
			},
		},
		castomLabel: {
			boxSizing: "border-box",
			paddingRight: "20px",
		},
	})
);

export default function ApiSelect() {
	const classes = useStyles();
	const [state, setState] = React.useState<{
		age: string | number;
		name: string;
	}>({
		age: "",
		name: "hai",
	});
	const { t } = useTranslation();

	const handleChange = (
		event: React.ChangeEvent<{ name?: string; value: unknown }>
	) => {
		const name = event.target.name as keyof typeof state;
		setState({
			...state,
			[name]: event.target.value,
		});
	};

	return (
		<div className={classes.container}>
			<div className={classes.castomLabel}>
				{t(DICTIONARY.SELECT_PLATFORM)}
			</div>
			<FormControl variant="outlined" className={classes.formControl}>
				<InputLabel htmlFor="outlined-age-native-simple">
					{t(DICTIONARY.INPUT_SELECT_PLATFORM)}
				</InputLabel>
				<Select
					native
					value={state.age}
					onChange={handleChange}
					label={t(DICTIONARY.INPUT_SELECT_PLATFORM)}
					inputProps={{
						name: "age",
						id: "outlined-age-native-simple",
					}}
				>
					<option aria-label="None" value="" />
					<option value={10}>Service API</option>
					<option value={20}>Operation API</option>
				</Select>
			</FormControl>
		</div>
	);
}
