import * as Yup from "yup";

export const subscriptionValidationSchema = Yup.object().shape({
	email: Yup.string()
		.required("Обов'язкове для заповнення")
		.typeError("Введіть коректний Email користувача")
		.email("Веддіть коректний Email")
		.min(5, "Закороткий Email"),
	success: Yup.boolean(),
});
