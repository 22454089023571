import React, { FC, Fragment, useState, useContext, useEffect } from "react";
import {
	createStyles,
	makeStyles,
	Theme,
	Grid,
	MenuItem,
} from "@material-ui/core";
import Banner from "../../../images/banner-1.png";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ContextStore } from "../../../store";
import axios from "axios";
import { ACTION_TYPES } from "../../../reduser";
import { ISnackBarData } from "../../../interfaces/interfaces";
import { DICTIONARY, TYPE_MESSAGE } from "../../../enums/dictionary";

export const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		description: {
			width: "100%",
			borderRadius: "15px",
			marginTop: "10px",
		},
		imgStyle: {
			height: "5vw",
			maxHeight: "52px",
			cursor: "pointer",

		},
		textStyle: {
			padding: theme.spacing(2),
		},
		logoPosition: {
			display: "flex",
			justifyContent: "center",
			padding: theme.spacing(2),
		},
		imgContent: {
			width: "100%",
			borderRadius: "15px",
		},

		[theme.breakpoints.down("sm")]: {
			imgStyle: {
				height: "8vw",
				minHeight: "30px",
				minWidth: "140px",
			},
		},
		[theme.breakpoints.down("xs")]: {
			gridContainer: {
				display: "flex",
				flexDirection: "column",
				justifyContent: "center",
				alignItems: "center",
			},
		},
	})
);

export type INewsParams = {
	lang: string | null;
	qty: number;
	page: number;
};

export const ProductsAPI: FC = () => {
	const classes = useStyles();
	const { t } = useTranslation();
	const [listModules, setListModules] = useState<Array<any>>([]);
	const { state, dispatch } = useContext(ContextStore);
	const [language, setLanguage] = useState<string | null>("");

	useEffect(() => {
		if (state.language) {
			setLanguage(state.language);
		} else {
			const lang = localStorage.getItem("i18nextLng");
			setLanguage(lang);
		}
	}, [state.language]);

	useEffect(() => {
		let getModules = async () => {
			const params: INewsParams = {
				lang: language,
				qty: 20,
				page: 1,
			};
			try {
				const response = await axios.get(
					`${process.env.REACT_APP_API_END_POINT}api/open/libs`,
					{
						params: params,
						headers: {
							"content-type": "application/json",
							Accept: "application/json",
						},
					}
				);

				if (Array.isArray(response.data.data)) {
					const module = response.data.data;
					setListModules(module);
				}
			} catch (error) {
				// console.log("error = ", error);
				// if (error.response.status === 500) {
				// 	const snackData: ISnackBarData = {
				// 		code: "500",
				// 		message: t(DICTIONARY.SERVER_ERROR),
				// 		typeMessage: t(TYPE_MESSAGE.ERROR),
				// 	};
				// 	dispatch({
				// 		type: ACTION_TYPES.SET_SNACK_BAR_DATA,
				// 		payload: snackData,
				// 	});
				// }
			}
		};

		getModules();
	}, [language]);

	return (
		<Fragment>
			<Grid container spacing={1} className={classes.gridContainer}>
				{listModules && listModules.length > 0 ? (
					listModules.map(({ image, id, title }) => {
						return (
							<Grid item xs={8} sm={4} key={id}>
								<Grid
									item
									xs={12}
									className={classes.logoPosition}
								>
									<MenuItem
										component={Link}
										to="/productdetails"
									>
										<img
											className={classes.imgStyle}
											alt="module-pictire"
											src={image}
											onClick={() => {
												const moduleId = id;
												dispatch({
													type:
														ACTION_TYPES.MODULE_ID,
													payload: moduleId,
												});
											}}
										></img>
									</MenuItem>
								</Grid>
								<Grid
									item
									xs={12}
									className={classes.textStyle}
								>
									{t(title)}
								</Grid>
							</Grid>
						);
					})
				) : (
					<div>Nothing</div>
				)}
			</Grid>
			<Grid
				item
				xs={12}
				style={{ display: "flex", justifyContent: "center" }}
			>
				<Grid item xs={12} className={classes.description}>
					<img
						alt="contentPicture"
						src={Banner}
						className={classes.imgContent}
					></img>
				</Grid>
			</Grid>
		</Fragment>
	);
};
