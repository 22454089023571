import React, {useEffect, useContext, useState} from "react";
import {makeStyles, Theme, createStyles, styled} from "@material-ui/core/styles";
import AuthorizationImg from "../../images/authorization.svg";
import {
    AppBar,
    CssBaseline,
    Toolbar,
    MenuList,
    MenuItem, Menu,
} from "@material-ui/core";
import {DICTIONARY, LANGUAGE} from "../../enums/dictionary";
import {LogoJustin} from "./header.logo";
import {useTranslation} from "react-i18next";
import {IInitialStateStore} from "../../redux/types/type";
import {Dispatch} from "redux";
import {connect, ConnectedProps} from "react-redux";
import {setLanguage} from "../../redux/actions/actionCreators";
import BurgerMenuImg from "../../images/burger-menu.svg";
import CloseImg from "../../images/close.svg";
import {Link} from "react-router-dom";
import {Navigation} from "../navigation/Navigation";
import globe from "../../images/icon_globe.svg";
import MobileGlobe from "../../images/mobile_globe.svg";
import flagUkraine from "../../images/flag_uk.svg";
import flagUk from "../../images/flag_eng.svg";
import flagRussia from "../../images/flag_russia.svg";

const drawerWidth = 240;

const useStyles = makeStyles((theme: Theme) => ({
        root: {
            position: "relative",
            backgroundColor: theme.palette.secondary.light,
            color: "#104eff",
            height: "125px",
            ["@media (max-width:812px)"]: {
                height: "80px",
            },
        },
        pd_l_r: {
            display: "none",
            padding: "20px 15px",
            ["@media (max-width:812px)"]: {
                display: "block",
            },
        },
        appBar: {
            height: "125px",
            padding: "20px 20px 20px 60px",
            ["@media (max-width:1100px)"]: {
                height: "max-content",
                padding: "20px",
            },
            ["@media (max-width:812px)"]: {
                height: "max-content",
                padding: "0px",
            },
            [theme.breakpoints.up("xs")]: {},
            [theme.breakpoints.up("md")]: {
                width: "100%",
                alignItems: "center",
            },
        },
        menuButton: {
            [theme.breakpoints.up("md")]: {
                display: "none",
            },
        },

        menuTabs: {
            display: "flex",
            [theme.breakpoints.down("sm")]: {
                display: "none",
            },
        },


        // necessary for content to be below app bar
        toolbar: theme.mixins.toolbar,

        toolbarmob: {
            display: "flex",
            width: "100%",
            justifyContent: "space-between",
            ["@media (max-width:812px)"]: {
                padding: "0px",
            },
        },
        drawerPaper: {
            width: drawerWidth,
            paddingLeft: "30px",
            paddingRight: "30px",
        },
        langContainer: {
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            position: "absolute",
            background: "white",
            color: "black",
            top: "60px",
            left: "-120px",
            justifyContent: "space-between",
            width: "max-content",
            borderRadius: "15px",
            border: ".5px solid grey",
            ["@media (max-width:812px)"]: {
                top: "0",
                left: "0",
                position: "relative",
            },
            "& p": {
                fontSize: "22px",
                padding: "10px",
            },
            '& li': {
                width: "100%",
                minHeight: "auto",
                fontSize: "18px",
                paddingTop: "0"
            },
            "&$selected": {
                color: "red",
            },
            "&$ .Mui-selected": {
                color: "blue",
            },

        },
        "landMenu": {
            display: "flex",
            flexDirection: "column",
            position: "relative",
            "& img": {
                width: "30px",
            },
            ["@media (max-width:812px)"]: {
                display: "none",
            },
        },
        "wrapperAuthorization": {
            display: "flex",
            alignItems: "center",
            "z-index": "1",
            "& label": {
                cursor: "pointer",
                fontSize: "18px",
                fontFamily: "Helvetica",
                fontWeight: "700",
                lineHeight: "21px",
            },
            "& img": {
                margiRight: "20px"
            }

        },
        wrapperBurgerMenu: {
            position: "absolute",
            display: "flex",
            "justify-content": "space-between",
            "align-items": "flex-start",
            width: "100%",
            background: "white",
            padding: "30px 25px 60px 15px",
            top: "0px",
            left: "0px",


            "& a": {
                color: "#104EFF",
                textDecoration: "none",
                fontSize: "24px",
                lineHeight: "28px",
                fontWeight: "700",
                fontFamily: "Helvetica",
                paddingBottom: "25px",
            },
            "& img": {
                width: "30px",
            }

        },
        wrapperLinks: {
            display: "flex",
            flexDirection: "column",
        },
        boxAuthorization: {
            width: "100%",
            display: "flex",
            justifyContent: "flex-end",
            ["@media (max-width:812px)"]: {
                display: "none",
            },
        },
        globe: {
            cursor: "pointer",
            marginBottom: "10px",
            ["@media (max-width:812px)"]: {
                marginBottom: "0",
                marginRight: "10px",
            },
        },
        boxLanguage: {
            display: "flex",
            justifyContent: "flex-start",
            margin: "5px 0",
            "& label": {
                marginLeft: "10px"
            }
        },
        pointer: {
            cursor: "pointer",
        },
        boxBurger: {
            display: "flex",
            flexDirection: "column",
            position: "absolute",
            "justify-content": "space-between",
            "align-items": "flex-start",
            width: "100%",
            background: "white",
            padding: "30px 40px 60px 15px",
            top: "0px",
        },

        landMenuMobil: {
            display: "flex",
            flexDirection:
                "column",
            position:
                "relative",
            "& img":
                {
                    width: "30px",
                }
            ,

        }
        ,
        boxLangMobile: {
            display: "flex",
            alignItems: "center",
        },

    }))
;

const StyledMenuItem = styled(MenuItem)({
    "&.Mui-selected": {
        // "&:before": {
        //     position: "absolute",
        //     content: '""',
        //     bottom: 0,
        //     left: "25%",
        //     right: 0,
        //     width: "50%",
        //     height: 2,
        //     background: "white"
        // }
    },
});

interface Props {
    /**
     * Injected by the documentation to work in an iframe.
     * You won't need it on your project.
     */
    window?: () => Window;
}


const mapStateToProps = (state: IInitialStateStore) => ({language: state.language});

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        setLanguage: (lang: string) => dispatch(setLanguage(lang))
    }
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>

interface IProps extends PropsFromRedux {
}

function getImg() {
    switch (localStorage.getItem("i18nextLng")) {
        case "ru" :
            return <img src={flagRussia} alt={flagRussia.toString()}/>;
        case "ua" :
            return <img src={flagUkraine} alt={flagUkraine.toString()}/>;
        case "en" :
            return <img src={flagUk} alt={flagUk.toString()}/>;
    }
}

function NavTabsMenu(props: IProps) {
    const {setLanguage} = props;
    const classes = useStyles();
    const [langState, setLangState] = useState(localStorage.getItem("i18nextLng") ? localStorage.getItem("i18nextLng") : "ua");
    const {t, i18n} = useTranslation();
    const [isModalOpen, setModalOpen] = useState<boolean>(false);
    const [isModalSetLanguage, setModalSetLanguage] = useState<boolean>(false);


    function handleLang(lang: string): void {
        i18n.changeLanguage(lang);
        setLangState(lang);
        setLanguage(lang)
    }

    const navItems = [
        {
            text: DICTIONARY.JUSTIN,
            link: "/justin",
        },
        {
            text: DICTIONARY.API,
            link: "/doc",
        },
        {
            text: DICTIONARY.STARTINTEGRATION,
            link: "/integration",
        },
        {
            text: DICTIONARY.PLUGINSANDRESOURCES,
            link: "/plugins_resources",
        },
        // {
        //     text: DICTIONARY.ALL_NEWS,
        //     link: "/news",
        // },
        {
            text: DICTIONARY.SERVICE_SUPPORT,
            link: "/support",
        },
    ];
    const navItemsMobile = [
        {
            text: DICTIONARY.MAIN,
            link: "/justin",
        },
        {
            text: DICTIONARY.API,
            link: "/doc",
        },
    ];

    function setLang() {
        return (
            <div className={classes.langContainer} onClick={() => setModalSetLanguage(!isModalSetLanguage)}>
                <StyledMenuItem
                    selected={langState === "ua"}
                    key={DICTIONARY.UKR}
                    onClick={() => handleLang(LANGUAGE.UA)}
                >
                    <div className={classes.boxLanguage}>
                        <img src={flagUkraine} alt={flagUkraine.toString()}/>
                        <label>Українська</label>
                    </div>
                </StyledMenuItem>
                <StyledMenuItem
                    selected={langState === "en"}
                    key={DICTIONARY.ENGl}
                    onClick={() => handleLang(LANGUAGE.EN)}
                >
                    <div className={classes.boxLanguage}>
                        <img src={flagUk} alt={flagUk.toString()}/>
                        <label>English</label>
                    </div>
                </StyledMenuItem>
                <StyledMenuItem
                    selected={langState === "ru"}
                    key={DICTIONARY.RUS}
                    onClick={() => handleLang(LANGUAGE.RU)}
                >
                    <div className={classes.boxLanguage}>
                        <img src={flagRussia} alt={flagRussia.toString()}/>
                        <label>Русский</label>
                    </div>
                </StyledMenuItem>
            </div>
        );
    }

    function BurgerMenu({setModalOpen}: { setModalOpen: any }) {
        console.log("window.innerWidth", window.innerWidth);
        return (
            <div className={classes.boxBurger}>
                <div className={classes.wrapperBurgerMenu}>
                    <div className={classes.wrapperLinks}>
                        {navItems.map((item, index) => {
                            return <Link to={item.link} key={item.text + index}
                            >{t(item.text)}</Link>
                        })}
                        <div className={classes.boxLangMobile}><img
                            onClick={() => setModalSetLanguage(!isModalSetLanguage)} className={classes.globe}
                            src={MobileGlobe} alt="globe"/>
                            {getImg()}
                        </div>
                        <div className={classes.landMenuMobil}>
                            {isModalSetLanguage && setLang()}
                        </div>
                    </div>
                    <img src={CloseImg} alt={CloseImg.toString()} onClick={() => setModalOpen(!isModalOpen)}/>
                </div>

            </div>

        )
    }

    function authorization() {
        return <div className={classes.wrapperAuthorization}>
            <img src={AuthorizationImg} alt="AuthorizationImg"/>
            <label onClick={() => alert("log")}>Log in/</label>
            <label onClick={() => alert("Create")}>Create account</label>
        </div>
    }

    return (
        <div className={classes.root}>
            <CssBaseline/>
            <AppBar position="fixed" className={classes.appBar}>
                <div className={classes.boxAuthorization}>
                    {/*{authorization()}*/}
                </div>
                <Toolbar className={classes.toolbarmob}>
                    <LogoJustin/>
                    <Navigation/>
                    <div className={classes.landMenu}>
                        <img onClick={() => setModalSetLanguage(!isModalSetLanguage)} className={classes.globe}
                             src={globe} alt="globe"/>
                        {getImg()}
                        {isModalSetLanguage && setLang()}
                    </div>
                    <img className={classes.pd_l_r} src={BurgerMenuImg} alt={BurgerMenuImg.toString()}
                         onClick={() => setModalOpen(!isModalOpen)}/>
                    {isModalOpen && <BurgerMenu setModalOpen={setModalOpen}/>}
                </Toolbar>
            </AppBar>
        </div>
    );
}

export default connector(NavTabsMenu);