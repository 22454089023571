import {useTranslation} from "react-i18next";
import React, {Fragment, useState} from "react";
import {arrayStartIntegration} from "../pages/integration/ArrayStartIntegration.text";
import {Accordion, AccordionDetails, AccordionSummary, Button, createStyles, Grid, TextField} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/core/SvgIcon/SvgIcon";
import {DICTIONARY, TYPE_MESSAGE} from "../../enums/dictionary";
import {makeStyles} from "@material-ui/core/styles";
import {useDispatch, useSelector} from "react-redux";
import {IInitialStateStore} from "../../redux/types/type";
import CloseImg from "../../images/close.svg";
import {HANDLE_MODAL_REGISTRATION} from "../../redux/actions/actionTypes";
import {useFormik} from "formik";
import {ISnackBarData} from "../../interfaces/interfaces";
import {ACTION_TYPES} from "../../reduser";
import {areaValidationSchema as validationSchema} from "../../components/pages/area.page/area.form.validation";
import ReCAPTCHA from "react-google-recaptcha";

export const useStyles = makeStyles(() =>
    createStyles({
        modal: {
            position: "fixed",
            left: 0,
            top: 0,
            right: 0,
            bottom: 0,
            width: "100%",
            backgroundColor: "rgba(0,0,0,0.5)",
            fontFamily: "Helvetica",
            "z-index": "9999",
            display: "flex",
            "justify-content": "center",
            "align-items": "center",
            "& h3":
                {
                    fontSize: "36px",
                    lineHeight: "41px",
                    fontWeight: "700",
                }
            ,
            "& p":
                {
                    marginTop: "25px",
                    lineHeight: "28px",
                }
        },
        container: {
            position: "relative",
            background: "white",
            width: "600px",
            overflow: "auto",
            marginTop: "10px",
            color: "#104EFF",
            borderRadius: "20px",
            ["@media (max-width:812px)"]: {
                width: "100%",
            },
        },
        header: {
            display: "flex",
            "align-items": "flex-end",
            "justify-content": "space-between",
            fontSize: "36px",
            lineHeight: "41px",
            "font-weight": "700",
            padding: "20px 20px 0 50px",
            "& img": {
                cursor: "pointer",
            }
        },
        headerBox: {
            display: "flex",
            "align-items": "flex-end",
            "& label": {
                fontSize: "24px",
                lineHeight: "28px",
                "font-weight": "300",
                marginRight: "10px",
            }

        },
        boxForm: {
            display: "flex",
            flexDirection: "column",
            padding: "30px 50px 50px 50px",
            "& input": {
                border: "1px solid grey",
                borderRadius: "7px",
                marginBottom: "30px",
                paddingLeft: "10px",
                "&:before": {
                    display: "none !importznt",
                }
            },
            "& label": {
                color: "black",
                marginBottom: "10px",
                fontSize: "24px",
                lineHeight: "30px",
                fontWeight: "400",
            },
            "& .MuiInputBase-root:before, .MuiInputBase-root:after": {
                display: "none",
            },
        },

        colorBlue: {
            color: "#104EFF !important"
        },
        wrapperCaptcha: {
            marginBottom: "20px",
        },
    }));


export const RegistrationModal = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const isRegistration: boolean = useSelector(((state: IInitialStateStore) => state.isModalRegistration));
    const {t} = useTranslation();

    const [isValidStep, handleValidStep] = useState(false);
    const [isAllRight, handleAllRight] = useState(false);
    const [passwordRef, setPasswordRef] = useState("");
    const [passwordReRef, setPasswordReRef] = useState("");

    const {
        handleSubmit,
        handleChange,
        resetForm,
        values,
        errors,
        touched,
        handleBlur,
    } = useFormik({
        initialValues: {
            userName: "",
            email: "",
            phone: "",
            companyName: "",
            password: "",
            passwordConfirmation: "",
        },
        onSubmit: ({userName, email, phone, companyName, password, passwordConfirmation}) => {
            if (!isValidStep) {
                resetForm();
                return handleValidStep(!isValidStep);
            } else {

            }
            const snackData: ISnackBarData = {
                code: "200",
                message: "Дані успішно змінено",
                typeMessage: TYPE_MESSAGE.SUCCESS,
            };
            dispatch({
                type: ACTION_TYPES.SET_SNACK_BAR_DATA,
                payload: snackData,
            });
        },
    });

    function handleChangeState(data: any) {
        const target = data.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

    };

    function getInput(title: string, name: any, value: string, type?: string) {
        return <> <label>{title}</label>
            <TextField
                type={type ? type : "text"}
                name={name}
                onBlur={handleBlur}
                value={value}
                onChange={handleChange}
            />
        </>
    }

    function onChange(value: any) {
        handleAllRight(!!value);
    }

    return (<Fragment>
            {isValidStep && <div className={classes.modal}>
                <div className={classes.container}>
                    <div className={classes.header}>
                        {t(DICTIONARY.REGISTRATION_TITLE)}
                        <div className={classes.headerBox}>
                            {isValidStep && <label>{t(DICTIONARY.REQUIRED_FIELDS)}</label>}
                            <img onClick={() => dispatch({type: HANDLE_MODAL_REGISTRATION})} src={CloseImg}
                                 alt={CloseImg.toString()}/>
                        </div>
                    </div>
                    <div>
                        <form onSubmit={handleSubmit} className={classes.boxForm}>
                            {isValidStep ?
                                <>
                                    <label className={classes.colorBlue}>
                                        {t(DICTIONARY.EMAIL_ADDRESSES)}
                                    </label>
                                    <TextField
                                        type="text"
                                        name="email"
                                        onBlur={handleBlur}
                                        value={values.email}
                                        onChange={handleChange}
                                    />
                                </> :
                                <>
                                    {getInput(t(DICTIONARY.NAME_COMPANY), "companyName", values.companyName)}
                                    {getInput(t(DICTIONARY.EMAIL_ADDRESSES), "email", values.email)}
                                    {getInput(t(DICTIONARY.USER_NAME), "userName", values.userName)}
                                    {getInput(t(DICTIONARY.PASSWORD), "password", values.password, "password")}
                                    {getInput(t(DICTIONARY.RE_PASSWORD), "passwordConfirmation", values.passwordConfirmation, "password")}
                                </>
                            }
                            <div className={classes.wrapperCaptcha}>
                                <ReCAPTCHA
                                    sitekey="6LccqrsZAAAAAAUOuIOSM-aoAJK73RmVAHzQSiO-"
                                    onChange={onChange}
                                />
                            </div>
                            < Button
                                variant="contained"
                                color="primary"
                                size="large"
                                type="submit"
                                disabled={!isAllRight}
                            >
                                {t(DICTIONARY.NEXT)}
                            </Button>
                        </form>
                    </div>
                </div>
            </div>
            }
        </Fragment>
    );
};