import React, {Fragment} from "react";
import {
    createStyles,
    makeStyles,
    Theme,
    Button,
} from "@material-ui/core";
import {Grid} from "@material-ui/core";
import {DICTIONARY} from "../../../enums/dictionary";
import IntegrationImgUA from "../../../images/integrationStepUA.svg";
import IntegrationImgRU from "../../../images/integrationStepRU.svg";
import IntegrationImgEN from "../../../images/integrationStepEN.svg";
import {useTranslation} from "react-i18next";
import {IInitialStateStore} from "../../../redux/types/type";
import {Dispatch} from "redux";
import {HANDLE_MODAL_INTEGRATION} from "../../../redux/actions/actionTypes";
import {connect, ConnectedProps} from "react-redux";
import {getListIn, getListInJustin, getListManageYourShipments} from "./ListIntegration";
import {Link} from "react-router-dom";

export const useStyles = makeStyles((theme: Theme) =>
        createStyles({
            root: {
                width: "100%",
                display: "flex",
            },
            wrapperInfo: {
                width: "100%",
                marginTop: "50px",
                background: "white",
                fontSize: "24px",
                lineHeight: "28px",
                "font-weight": "400",
                fontFamily: "Helvetica",
                borderRadius: "10px",
                padding: "65px 75px 70px 30px",
                "& img": {
                    width: "80%",
                },
                ["@media (max-width:812px)"]: {
                    marginTop: "25px",
                    padding: "50px 30px",
                    "& img": {
                        display: "none",
                    },
                },
                "& h3": {
                    width: "100%",
                    fontSize: "24px",
                    lineHeight: "28px",
                    fontWeight: "700",
                    fontFamily: "Helvetica",
                    marginBottom: "50px",
                    paddingLeft: "50px",
                    textAlign: "center",
                    ["@media (max-width:812px)"]: {
                        width: "100%",
                        marginBottom: "30px",
                        paddingLeft: "0",
                    },
                },
                "& p": {
                    marginTop: "20px",
                    lineHeight: "23px",
                    "font-weight": "400",
                    fontFamily: "Helvetica",
                },
                "& .MuiPaper-elevation1": {
                    boxShadow: "none",
                    // paddingBottom: "70px",
                    ["@media (max-width:812px)"]: {
                        paddingBottom: "30px",
                    }
                },
                "& .MuiAccordion-root:before": {
                    display: "none",
                },
                "& .Mui-expanded ": {
                    margin: "0",
                },
            },

            news: {
                display: "flex",
                width: "100%"
            },
            wrapperIntegration: {
                display: "flex",
                width: "100%",
                flexDirection: "column",
                "justify-content": "space-between",
                background: "white",
                lineHeight: "28px",
                borderRadius: "10px",
                padding: "65px 15% 50px 15%",
                ["@media (max-width:812px)"]: {
                    flexDirection: "column",
                    padding: "30px 12px 50px 12px",
                    marginTop: 0,
                    "& img": {
                        display: "none",
                    },
                    "& h3": {
                        marginBottom: "30px",
                    },
                },

            },
            integrationWrapper: {
                "& h3": {
                    marginLeft: "10%",
                    fontSize: "24px",
                    fontWeight: "700",
                    fontFamily: "Helvetica",
                },
                "& p": {
                    marginLeft: "10%",
                    fontSize: "20px",
                    marginTop: "24px",
                    lineHeight: "23px",
                    "font-weight": "400",
                    fontFamily: "Helvetica",
                    ["@media (max-width:812px)"]: {
                        marginTop: "0",
                    },
                },
                "& .MuiPaper-elevation1": {
                    boxShadow: "none",
                    padding: "0 !important",
                    ["@media (max-width:812px)"]: {
                        paddingBottom: "0 !important",
                    },
                }, "& .MuiAccordion-root:before": {
                    display: "none",
                },
                "& .MuiAccordionDetails-root": {
                    padding: "0 !important",
                    "& p": {
                        padding: "0",
                    }
                },
                "& .Mui-expanded ": {
                    padding: "0",
                },
            },
            bt: {
                marginTop: "60px",
                fontSize: "20px",
                lineHeight: "25px",
                "font-weight": "400",
                fontFamily: "Helvetica",
                padding: "15px 105px",
                textTransform: "capitalize",
                ["@media (max-width:1100px)"]:
                    {
                        padding: "15px",
                    },
            },
            boxInfo: {
                width: "100%",
                "padding": "0 15% 70px",
                "& p": {
                    fontSize: "20px !important",
                },
                "& .Mui-expanded":
                    {
                        "padding": "0 10% !important",
                    },
                ["@media (max-width:812px)"]:
                    {
                        "padding": "0 0 50px",
                        "& p": {
                            fontSize: "20px",
                            lineHeight: "23px",
                        }
                    }
            },
            steps: {
                fontSize: "24px",
                lineHeight: "28px !important",
                "font-weight": "400",
                marginTop: "0px !important",
            }
            ,
            box: {
                display: "flex",
                flexDirection: "column",
                "& .MuiPaper-elevation1":
                    {
                        boxShadow: "none",
                        "padding": "0 10%",
                        ["@media (max-width:812px)"]:
                            {
                                padding: "0 12px !important",
                            }
                        ,
                    }
                ,
                "& .MuiPaper-rounded":
                    {
                        "padding": "0 15% !important",
                    }
                ,
                "& .MuiAccordion-root:before":
                    {
                        display: "none",
                    }
                ,
                "& .Mui-expanded ": {
                    margin: "0",
                    // padding:
                    //     "0",
                },
                "& .MuiAccordionDetails-root": {
                    padding: "0",
                    "& p": {
                        padding: "0",
                    }
                },
                "& .MuiAccordionSummary-root": {
                    padding: "0",
                },
            }
            ,
            wrapperBtnModal: {
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                margin: "0 15%",
                "& a": {
                    textDecoration: "none",
                },
                ["@media (max-width:812px)"]: {
                    width: "100%",
                }
            }
            ,
            wrapperImg: {
                display: "flex",
                "justify-content": "center",
                padding: "0 10%",

            },
            boxTitle: {
                background: "white",
                padding: "0 15%",
                fontSize: "24px",
                lineHeight: "28px",
                "font-weight": "700",
                fontFamily: "Helvetica",
                // "& p": {marginLeft: "16px",},
                ["@media (max-width:812px)"]: {
                    padding: "0 12px",
                }
            },
            title: {
                marginLeft: "10% !important",
            },
        })
    )
;
const mapStateToProps = (state: IInitialStateStore) => ({isModalIntegration: state.isModalIntegration});

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        handleModal: () => dispatch({type: HANDLE_MODAL_INTEGRATION})
    }
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>

interface IProps extends PropsFromRedux {
}

function getImg() {
    switch (localStorage.getItem("i18nextLng")) {
        case "ru" :
            return <img src={IntegrationImgRU} alt="IntegrationImgRU"/>
        case "ua" :
            return <img src={IntegrationImgUA} alt="IntegrationImgUA"/>
        case "en" :
            return <img src={IntegrationImgEN} alt="IntegrationImgEN"/>
    }
}

// export const getListIn =()=> {
//     /* eslint-disable */
//     const classname = useStyles();
//     const {t} = useTranslation();
//     console.log("arrayStartIntegration", arrayStartIntegration);
//     let test="";
//     return (
//         arrayStartIntegration.map((item: any, index: number) => {
//                 return (
//                     <Accordion key={index}>
//                         <AccordionSummary
//                             expandIcon={<ExpandMoreIcon/>}
//                             aria-controls="panel1a-content"
//                             id="panel1a-header"
//                         >
//                             <Typography>
//                                 {
//                                     // @ts-ignore
//                                     t(DICTIONARY.item.header)
//                                 }
//                             </Typography>
//                         </AccordionSummary>
//                         {item.sub.map((subItem: any, i: number) => {
//                             test =subItem.title
//                             return (
//                                 <AccordionDetails key={i}>
//                                     <Typography
//                                     >
//                                         <label>{test}</label>
//                                         <label>{
//                                             // @ts-ignore
//                                             t(DICTIONARY.test)}</label><label>{subItem.body}</label>
//                                     </Typography>
//                                 </AccordionDetails>
//                             );
//                         })}
//                         {/*{item.sub.map(subItem: any, i) => {*/}
//                         {/*    <AccordionDetails>*/}
//                         {/*    <Typography*/}
//                         {/*    className={classes.messageStyle}*/}
//                         {/*    >*/}
//                         {/*    {subItem.title}*/}
//                         {/*    </Typography>*/}
//                         {/*    </AccordionDetails>*/}
//                         {/*}}*/}
//                     </Accordion>
//                 );
//             }
//         ))
// }

const StartIntegration = (props: IProps) => {
    const {t} = useTranslation();
    const classname = useStyles();
    const {handleModal} = props;
    localStorage.getItem("i18nextLng");

    return (
        <Fragment>
            <Grid className={classname.root} container spacing={1}>
                <Grid className={classname.box}>
                    <div className={classname.wrapperIntegration}>
                        <div className={classname.integrationWrapper}>
                            <h3>{t(DICTIONARY.TYPES_OF_SHIPMENTS)}</h3>
                            {getListIn()}
                        </div>
                        <div className={classname.wrapperBtnModal}>
                            <Link to="/get_key">
                                <Button className={classname.bt}
                                        variant="contained"
                                        color="primary">
                                    {t(DICTIONARY.APPLY_FOR_API)}
                                </Button>
                            </Link>
                        </div>
                    </div>
                    <div className={classname.boxTitle}>
                        <p className={classname.title}> {t(DICTIONARY.FOR_INTEGRATION)}</p>
                        {getListInJustin()}
                    </div>

                </Grid>
                <Grid className={classname.wrapperInfo}>
                    <h3>{t(DICTIONARY.MANAGE_YOUR_SHIPMENTS)}</h3>
                    <div className={classname.wrapperImg}>{getImg()}</div>
                    <div className={classname.boxInfo}>
                        <p className={classname.steps}>{t(DICTIONARY.STATUS_MEAN_STEP_1)}</p>
                        <p className={classname.steps}>{t(DICTIONARY.STATUS_MEAN_STEP_2)}</p>
                        <p className={classname.steps}>{t(DICTIONARY.STATUS_MEAN_STEP_3)}</p>
                    </div>
                    <div>
                        {getListManageYourShipments()}
                    </div>
                </Grid>
            </Grid>
        </Fragment>
    );
};

export default connector(StartIntegration);