import React, {useState, Fragment} from 'react';
import {Theme, createStyles, makeStyles} from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import classNames from 'classnames';
import {DICTIONARY, TYPES_REQUESTS} from "../../../../../enums/dictionary";
import {Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@material-ui/core";
import {useTranslation} from "react-i18next";


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
        },
        heading: {
            fontWeight: 600,
            padding: '6px 15px 6px 0px',
            wordBreak: "break-word",

        },

        queryButtonGet: {
            minWidth: 80,
            padding: '6px 15px',
            textAlign: 'center',
            borderRadius: '3px',
            background: theme.palette.info.dark,
            fontSize: 14,
            color: '#fff',
            textTransform: 'uppercase',
            boxSizing: 'border-box',
            marginRight: '10px',
        },
        queryButtonPost: {
            minWidth: 80,
            padding: '6px 15px',
            textAlign: 'center',
            borderRadius: '3px',
            background: theme.palette.info.light,
            fontSize: 14,
            color: '#fff',
            textTransform: 'uppercase',
            boxSizing: 'border-box',
            marginRight: '10px',
        },
        queryButtonDelete: {
            minWidth: 80,
            padding: '6px 15px',
            textAlign: 'center',
            borderRadius: '3px',
            background: theme.palette.warning.light,
            fontSize: 14,
            color: '#fff',
            textTransform: 'uppercase',
            boxSizing: 'border-box',
            marginRight: '10px',
        },
        queryButtonPut: {
            minWidth: 80,
            padding: '6px 15px',
            textAlign: 'center',
            borderRadius: '3px',
            background: '#fca130',
            fontSize: 14,
            color: '#fff',
            textTransform: 'uppercase',
            boxSizing: 'border-box',
            marginRight: '10px',
        },

        requestBody: {
            width: '100%',
            overflowX: 'scroll',
            padding: '6px 15px',
            borderRadius: '3px',
            background: '#000000',
            fontSize: 14,
            color: '#fff',
            boxSizing: 'border-box',
            marginRight: '10px',
        },
        castomSummary: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        },
        detailsContainer: {
            width: '100%'
        },

        shortSummary: {
            // borderTop:  '1px solid #41444e',
            width: '100%',
            boxSizing: 'border-box',
            paddingTop: '10px',
            color: theme.palette.primary.main,

        },
        params: {
            padding: '8px 16px',
            minHeight: '50px',
            boxShadow: `0 1px 2px rgba(0,0,0,.1)`,
            display: 'flex',
            alignItems: 'center',
            fontWeight: 600,
        },
        noParams: {
            display: 'flex',
            paddingLeft: '32px',

        },
        listParams: {
            display: 'flex',
            boxSizing: 'border-box',
            padding: '10px',
        },
        paramName: {
            fontSize: 22,
        },
        table: {
            width: '100%',
        },
        required: {
            color: theme.palette.warning.light,
            fontWeight: 600,
            fontSize: 12,

        }

    }),
);

export default function AccordionApiDocumentation() {
    const classes = useStyles();
    const { t } = useTranslation();
    const [requestBody] = useState([
        {
            "idRequest": '1',
            "type": "post",
            "parameters": [],
            "route": `/login`,
            "shortTitle": 'Метод реєструє користувача в системі',
            "requestBodyExampleValues": {
                "username": "string",
                "password": "string"
            },
            "requestBodySchema": {
                "username": "string",
                "password": "string"
            },
            "responses": [{
                "code": "200",
                "description": "Вдала авторизация",
                "mediaType": "application/json",
                "responseBodyExampleValues": {
                    "user": {
                        "fio": "string",
                        "globalUserId": 0
                    },
                    "access_token": "string"
                },
            },
                {
                    "code": "500",
                    "description": "Помилка сервера",
                    "mediaType": "application/json",
                    "responseBodyExampleValues": {
                        "error": {
                            "descripton": "server error"
                        },
                    },
                }
            ]
        },
        {
            "idRequest": '2',
            "type": "get",
            "parameters": [{
                "Name": "limit",
                "typeName": "number",
                "isRequired": "true",
            }],
            "route": `/users/{limit}`,
            "shortTitle": 'Метод вибирає заданну кількість користувачів',
            "requestBodyExampleValues": null,
            "requestBodySchema": null,
            "responses": [{
                "code": "200",
                "description": "Вибрати користувачів",
                "mediaType": "application/json",
                "responseBodyExampleValues": {
                        "staffId": 0,
                        "fullName": "string",
                        "globalUserId": 0,
                        "filialId": 0,
                        "filialName": "string",
                        "departmentName": "string",
                        "positionName": "string",
                        "isActive": true
                    }
                ,
            }]
        },
        {
            "idRequest": '3',
            "type": "delete",
            "parameters": [
                {
                    "Name": "pollId",
                    "typeName": "number",
                    "isRequired": "true",
                },
                {
                    "Name": "userId",
                    "typeName": "number",
                    "isRequired": "true",
                    "Description": ""
                }
            ],
            "route": `/participaants/delete/poll-id/{pollId}/user-id/{userId}`,
            "shortTitle": 'Метод видаляе користувача з голосування',
            "requestBodyExampleValues": null,
            "requestBodySchema": null,
            "responses": [{
                "code": "200",
                "description": "Вдале видалення користувача",
                "mediaType": "application/json",
                "responseBodyExampleValues": null,
            }]
        },
        {
            "idRequest": '4',
            "type": "put",
            "parameters": [],
            "route": `/polls/update/{pollId}`,
            "shortTitle": 'Метод оновлює ідентифікатор голосування у системі',
            "requestBodyExampleValues": {
                "objectName": "string",
                "filialId": 0,
                "zoneId": 0
            },
            "requestBodySchema": {
                "objectName": "string",
                "filialId": "number",
                "zoneId": "number"
            },
            "responses": [{
                "code": "200",
                "description": "Вдале оновлення ідентифікатора",
                "mediaType": "application/json",
                "responseBodyExampleValues": null,
            }]
        }
    ]);


    // function setJson() {
    //     let json = document.getElementById("json");
    //     if (json !== null) {
    //         json.innerHTML = JSON.stringify(requestBody[0], undefined, 2);
    //     }
    // }


    function renderParams(params: Array<{ "Name": string,
                                            "typeName": string,
                                            "isRequired": string}>) {

        return (
            <Fragment>
                <Typography className={classes.params}>
                    Parameters
                </Typography>
                { params.length === 0 ? (
                        <Typography className={classes.noParams}>
                            No parameters
                        </Typography>
                ) : (
                        <TableContainer component={Paper} className={classes.table}>
                            <Table  aria-label="simple table">
                                <TableBody>
                                    {params.map((val) => {
                                        return(
                                            <TableRow key={val.Name}>
                                                <TableCell component="th" scope="row" className={classes.paramName}>
                                                    {val.Name}
                                                </TableCell>
                                                <TableCell align="left" className={classes.required}>{val.isRequired === 'true'? `isRequired*` : ''}</TableCell>
                                                <TableCell align="left">{val.typeName}</TableCell>
                                            </TableRow>
                                        )
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    )}

            </Fragment>
        )
    }

     function renderJsonBody(requestBodySchema?: any, name?: string) {
        let json = JSON.stringify(requestBodySchema, undefined, 2);

        return (
            <Fragment>
                <Typography className={classes.params}>
                    {name ? name : 'Request Body'}
                </Typography>
                {requestBodySchema === null ? (
                    <Typography className={classes.noParams}>
                        {name ? `No ${name}` : 'No request body'}
                    </Typography>
                ) : (
                    <pre id="json" className={classes.requestBody}>{json}</pre>
                )}
            </Fragment>
        )
     }

     function renderResponse(responses: Array<{
         "code": string,
         "description": string,
         "mediaType": string,
         "responseBodyExampleValues": object | null,
     }>) {
        return (
            <Fragment>
                <Typography className={classes.params}>
                    Responses
                </Typography>
                { responses.length === 0 ? (
                    <Typography className={classes.noParams}>
                        No response
                    </Typography>
                ) : (<Fragment>
                        {
                            responses.map((respons, index) => {
                                return(
                                    <Fragment>
                                        <TableContainer component={Paper} className={classes.table}>
                                            <Table  aria-label="simple table">
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell>Code</TableCell>
                                                        <TableCell align="left">Description</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    <TableRow key={index}>
                                                        <TableCell component="th" scope="row" className={classes.paramName}>
                                                            {respons.code}
                                                        </TableCell>
                                                        <TableCell align="left">{respons.description}</TableCell>
                                                    </TableRow>
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                        <Typography className={classes.params}>
                                            {t(DICTIONARY.MEDIA_TYPE)}
                                        </Typography>
                                        <Typography className={classes.noParams}>
                                            {respons.mediaType}
                                        </Typography>
                                        {renderJsonBody(respons.responseBodyExampleValues ,t(DICTIONARY.EXAMPLE_VALUE))}

                                    </Fragment>

                                )
                            })
                        }

                    </Fragment>
                )}

            </Fragment>
        )
     }

    return (
        <div className={classes.root}>
            {requestBody.map((item, index) => {

                return (
                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon/>}
                            // aria-controls="panel1a-content"
                            id="panel1a-header"
                            className={classes.castomSummary}
                        >
                            <span className={classNames(
                                item.type.toUpperCase() === TYPES_REQUESTS.GET ? classes.queryButtonGet : '',
                                        item.type.toUpperCase() === TYPES_REQUESTS.POST ? classes.queryButtonPost : '',
                                        item.type.toUpperCase() === TYPES_REQUESTS.PUT ? classes.queryButtonPut : '',
                                        item.type.toUpperCase() === TYPES_REQUESTS.DELETE ? classes.queryButtonDelete : '',
                            )}>{item.type}</span>
                            <Typography className={classes.heading}>{item.route}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <div className={classes.detailsContainer}>
                                <Typography className={classes.shortSummary}>
                                    {item.shortTitle}
                                </Typography>
                                {renderParams(item.parameters)}
                                {renderJsonBody(item.requestBodySchema)}
                                {renderResponse(item.responses)}
                            </div>

                        </AccordionDetails>
                    </Accordion>

                )
            })}
        </div>
    );
}
